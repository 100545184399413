import { Button, Grid, MenuItem, TextField, Typography } from "@material-ui/core"
import { Modal } from "components/modal/modal"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd"
import "./search.scss"
import { nanoid } from "nanoid"
import { useQueryTags } from "api/hooks"
import { ITagDto, ContactSearchFields, JobSearchFields } from "api/dto"
import { Autocomplete } from "@material-ui/lab"
import EmojiObjectsIcon from "@material-ui/icons/EmojiObjectsOutlined"
import { useTranslation } from "react-i18next"
import useDebounce from "hooks/useDebounce"
import CloseIcon from "@material-ui/icons/Close"
import { IGroup, ISearch, QueryType } from "./ISearch"
import { SearchPreview } from "./searchPreview"

interface ISearchModalProps {
  queryType: QueryType
  open: boolean
  toggle: (search?: any) => void
  setSearchString: (search: string) => void
  setSearch: (search: any) => void
  search: ISearch
}

export const SearchModal: React.FunctionComponent<ISearchModalProps> = (props) => {
  const { open, toggle, queryType, setSearchString, search, setSearch } = props
  const { data: tags } = useQueryTags()
  const { t: translate } = useTranslation()
  const tempRef = useRef<HTMLDivElement | undefined>()

  const removeGroup = useCallback(
    (id: string) => {
      const newSearch = [...search]
      const groupIndex = newSearch.findIndex((t) => t.id === id)
      if (groupIndex === -1) {
        return
      }

      if (groupIndex === newSearch.length - 1 && newSearch.length > 1) {
        delete newSearch[groupIndex - 1].operator
      }

      newSearch.splice(groupIndex, 1)

      setSearch(newSearch)
    },
    [search]
  )

  return (
    <Modal
      fullWidth
      maxWidth="md"
      saveText={translate("Search.StartSearch")}
      action={() => {
        setSearchString(tempRef.current?.innerText || "")
        toggle(search)
      }}
      open={open}
      title={translate("Common.AdvancedSearch")}
      dialogContentClass="search-modal"
      close={() => toggle(search)}
    >
      <Typography className="search-hint" variant="body1" style={{ display: "flex" }}>
        <div style={{ display: "flex", alignItems: "center", marginRight: "15px" }}>
          <EmojiObjectsIcon fontSize="large" />
        </div>
        <div>
          {translate("Search.AdvancedSearchExplanation")
            .split("\n")
            .map((i) => (
              <div key={nanoid()}>{i}</div>
            ))}
        </div>
      </Typography>
      <div className="preview">
        <SearchPreview ref={tempRef} search={search} />
      </div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          style={{ alignSelf: "flex-end" }}
          variant="outlined"
          onClick={() =>
            setSearch((currentSearch: ISearch) => {
              const newSearch = [...currentSearch]

              if (newSearch.length === 0) {
                newSearch.push({ id: nanoid(), parameters: [] })
              } else {
                newSearch[newSearch.length - 1].operator = "AND"
                newSearch.push({ id: nanoid(), parameters: [] })
              }

              return newSearch
            })
          }
        >
          {translate("Search.AddGroup")}
        </Button>
      </div>

      {search.map((g) => (
        <>
          <GroupParameter
            queryType={queryType}
            key={g.id}
            group={g}
            deleteGroup={search.length > 1 ? () => removeGroup(g.id) : undefined}
            setGroupParams={setSearch}
            tags={tags || []}
          />
          {g.operator && (
            <Grid container style={{ marginTop: "20px" }}>
              <Grid item xs={4}>
                <TextField
                  size="small"
                  title={translate("Search.Operator")}
                  variant="outlined"
                  style={{ width: "100%" }}
                  select
                  SelectProps={{
                    value: g.operator,
                    onChange: (e) =>
                      setSearch((currentSearch: any) => {
                        const newSearch = [...currentSearch]
                        const operatorIndex = newSearch.findIndex((t) => t.id === g.id)
                        if (operatorIndex === -1) {
                          return newSearch
                        }

                        newSearch[operatorIndex].operator = e.target.value
                        return newSearch
                      })
                  }}
                >
                  <MenuItem value="AND">{translate("Search.Operator.AND")}</MenuItem>
                  <MenuItem value="OR">{translate("Search.Operator.OR")}</MenuItem>
                </TextField>
              </Grid>
            </Grid>
          )}
        </>
      ))}
    </Modal>
  )
}

interface IGroupParameterProps {
  queryType: QueryType
  group: IGroup
  setGroupParams: React.Dispatch<React.SetStateAction<ISearch>>
  deleteGroup?: () => void
  tags: ITagDto[]
}

const GroupParameter: React.FunctionComponent<IGroupParameterProps> = (props) => {
  const { queryType, group, setGroupParams, deleteGroup, tags } = props

  const handleParamChange = useCallback(
    (id: string, name: string, value: any, operator?: "AND" | "OR") => {
      setGroupParams((old) => {
        const newGroups = [...old]
        const groupIndex = newGroups.findIndex((t) => t.id === group.id)
        const currentGroup = newGroups[groupIndex]

        if (groupIndex !== -1) {
          const paramIndex = currentGroup.parameters.findIndex((p) => p.id === id)

          if (paramIndex === -1) {
            if (currentGroup.parameters.length === 0) {
              currentGroup.parameters.push({ id, fieldName: name, field: value })
            } else {
              const lastParam = currentGroup.parameters[currentGroup.parameters.length - 1]
              lastParam.operator = "AND"
              currentGroup.parameters.push({ id, fieldName: name, field: value })
            }
          } else {
            const parameter = operator
              ? { ...currentGroup.parameters[paramIndex], value, operator }
              : { ...currentGroup.parameters[paramIndex], value }
            currentGroup.parameters[paramIndex] = parameter
          }

          newGroups[groupIndex] = currentGroup
        }

        return newGroups
      })
    },
    [group, setGroupParams]
  )

  const onDragEnd = useCallback(
    (result: DropResult) => {
      const { source, destination, draggableId } = result

      if (destination == null || group.parameters == null) {
        return
      }

      if (source.droppableId === destination.droppableId && source.index === destination.index) {
        return
      }

      const paramToMove = group.parameters.find((param) => param.id === draggableId)

      if (paramToMove == null) {
        return
      }

      const newParams = [...group.parameters]
      newParams.splice(source.index, 1)
      newParams.splice(destination.index, 0, paramToMove)

      if ((source.index === newParams.length - 1 && destination.index === newParams.length - 2) || destination.index === newParams.length - 1) {
        const { operator } = newParams[newParams.length - 1]
        const beforeLast = newParams[newParams.length - 2]
        beforeLast.operator = operator
        // Object.assign(beforeLast.operator, operator)
        delete newParams[newParams.length - 1].operator
      }

      setGroupParams((groups) => {
        const currentGroups = [...groups]
        const groupIndex = groups.findIndex((g) => g.id === group.id)
        if (groupIndex !== -1) {
          currentGroups[groupIndex] = { ...currentGroups[groupIndex], parameters: newParams }
        }
        return currentGroups
      })
    },
    [group, setGroupParams]
  )

  const removeLine = useCallback(
    (id: string) => {
      const newParams = [...group.parameters]
      const paramIndex = newParams.findIndex((t) => t.id === id)
      if (paramIndex === -1) {
        return
      }

      if (paramIndex === newParams.length - 1 && newParams.length > 1) {
        delete newParams[paramIndex - 1].operator
      }

      newParams.splice(paramIndex, 1)

      setGroupParams((groups) => {
        const currentGroups = [...groups]
        const groupIndex = groups.findIndex((g) => g.id === group.id)
        if (groupIndex !== -1) {
          currentGroups[groupIndex] = { ...currentGroups[groupIndex], parameters: newParams }
        }
        return currentGroups
      })
    },
    [group.parameters]
  )

  return (
    <Grid container className="group" direction="column">
      <div style={{ marginBottom: group.parameters.length > 0 ? "20px" : 0 }}>
        {deleteGroup && (
          <div className="delete-group">
            <Button size="small" variant="outlined" style={{ marginLeft: "20px", minWidth: "20px", maxWidth: "20px" }} onClick={() => deleteGroup()}>
              <CloseIcon fontSize="small" />
            </Button>
          </div>
        )}

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="parameters-droppable">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {group.parameters.map((param, index) => (
                  <SearchParameter
                    key={param.id}
                    index={index}
                    id={param.id}
                    field={param.field}
                    fieldName={param.fieldName}
                    value={param.value}
                    operator={param.operator}
                    setValue={handleParamChange}
                    removeLine={group.parameters.length > 1 ? removeLine : undefined}
                    tags={tags}
                  />
                ))}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>

      <AddParameter queryType={queryType} setValue={handleParamChange} />
    </Grid>
  )
}

interface IAddParameterProps {
  queryType: QueryType
  setValue: (id: string, name: string, value: string) => void
}

const AddParameter: React.FunctionComponent<IAddParameterProps> = (props) => {
  const { queryType, setValue } = props
  const fields = useMemo(() => (queryType === QueryType.contact ? Object.entries(ContactSearchFields) : Object.entries(JobSearchFields)), [queryType])
  const { t: translate } = useTranslation()
  return (
    <Grid item xs={5}>
      <Autocomplete
        options={fields.map(([k, v]) => ({ id: v, name: translate(`Search.Fields.${k}`) }))}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => <TextField {...params} className="searchByField" label={translate("Search.AddField")} variant="outlined" />}
        style={{ minWidth: "300px", marginRight: "20px" }}
        onChange={(e, value) => {
          if (value) {
            setValue(nanoid(), value.name, value?.id)
          }
        }}
      />
    </Grid>
  )
}

interface ISearchParameterProps {
  id: string
  index: number
  field: string
  fieldName: string
  value: any
  operator?: "AND" | "OR"
  tags: ITagDto[]
  setValue: (id: string, name: string, value: any, operator?: "AND" | "OR") => void
  removeLine?: (id: string) => void
}

const SearchParameter: React.FunctionComponent<ISearchParameterProps> = (props) => {
  const { id, value, field, fieldName, tags, operator, setValue, index, removeLine } = props
  const { t: translate } = useTranslation()
  const [currentValue, setCurrentValue] = useState<string>(value ?? "")
  const debouncedValue = useDebounce(currentValue, 400)

  useEffect(() => {
    setValue(id, fieldName, debouncedValue)
  }, [id, debouncedValue])

  return (
    <Draggable draggableId={id} index={index}>
      {(provided) => (
        <div ref={provided.innerRef} {...provided.draggableProps}>
          <Grid {...provided.dragHandleProps} key={id} item style={{ display: "flex", alignItems: "center", marginTop: "20px" }}>
            <Grid item xs={4} style={{ paddingLeft: 15 }}>
              <Typography variant="body1">{fieldName}</Typography>
            </Grid>
            <Grid item style={{ flexGrow: 1 }}>
              {field === ContactSearchFields.tags ? (
                <Autocomplete
                  size="small"
                  className="ByTagsInModal"
                  multiple
                  options={tags}
                  value={value || []}
                  getOptionLabel={(option) => option.name}
                  getOptionSelected={(option, v) => option.name === v.name}
                  renderInput={(params) => <TextField {...params} label={translate("Search.ByTags")} variant="outlined" />}
                  onChange={(e, newValues) => setValue(id, fieldName, newValues)}
                  limitTags={3}
                />
              ) : (
                <TextField
                  size="small"
                  variant="outlined"
                  className="tagInput"
                  placeholder={translate("Search.EnterValue")}
                  style={{ width: removeLine ? "100%" : "calc(100% - 40px)" }}
                  value={currentValue}
                  onChange={(e) => setCurrentValue(e.target.value)}
                />
              )}
            </Grid>
            {operator && (
              <Grid item xs={3} style={{ marginLeft: "10px" }}>
                <TextField
                  size="small"
                  title={translate("Search.Operator")}
                  variant="outlined"
                  style={{ width: "100%" }}
                  select
                  SelectProps={{
                    value: operator,
                    onChange: (e: any) => setValue(id, fieldName, value, e.target.value)
                  }}
                >
                  <MenuItem value="AND">{translate("Search.Operator.AND")}</MenuItem>
                  <MenuItem value="OR">{translate("Search.Operator.OR")}</MenuItem>
                </TextField>
              </Grid>
            )}
            {removeLine && (
              <Grid item xs={1}>
                <Button
                  size="small"
                  variant="outlined"
                  color="secondary"
                  style={{ marginLeft: "20px", minWidth: "20px", maxWidth: "20px" }}
                  onClick={() => removeLine(id)}
                >
                  <CloseIcon fontSize="small" />
                </Button>
              </Grid>
            )}
          </Grid>
        </div>
      )}
    </Draggable>
  )
}
