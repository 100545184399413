import i18next from "i18next"
import { initReactI18next } from "react-i18next"
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import detector from "i18next-browser-languagedetector"
import commonEn from "./translations/en/common.json"
import commonFr from "./translations/fr/common.json"
import errorEn from "./translations/en/error.json"
import errorFr from "./translations/fr/error.json"

// eslint-disable-next-line no-shadow
export enum TranslationNamespace {
  translation = "translation",
  core = "core"
}

i18next.use(initReactI18next).init({
  fallbackLng: "fr",
  resources: {
    en: {
      translation: commonEn,
      error: errorEn
    },
    fr: {
      translation: commonFr,
      error: errorFr
    }
  }
})

export default i18next
