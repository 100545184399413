import { makeStyles } from "@material-ui/styles"

export const useStyles = makeStyles(() => ({
  root: {
    width: "160px"
  },
  st0: {
    fill: "url(#SVGID_1_)"
  },
  st1: {
    fill: "url(#SVGID_2_)"
  },
  st2: {
    fill: "url(#SVGID_3_)"
  },
  st3: {
    fill: "url(#SVGID_4_)"
  },
  st4: {
    fill: "url(#SVGID_5_)"
  },
  st5: {
    fill: "url(#SVGID_6_)"
  },
  st6: {
    fill: "url(#SVGID_7_)"
  },
  st7: {
    enableBackground: "new"
  },
  st8: {
    fill: "#f6f6f6"
  },
  st9: {
    fill: "url(#SVGID_8_)"
  },
  st10: {
    fill: "url(#SVGID_9_)"
  },
  st11: {
    fill: "url(#SVGID_10_)"
  },
  st12: {
    fill: "url(#SVGID_11_)"
  },
  st13: {
    fill: "#333"
  }
}))

export default { useStyles }
