import { makeStyles } from "@material-ui/styles"

export const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    height: "calc(100% - 64px)"
  },
  iframe: {
    width: "100%",
    height: "100%"
  }
}))

export default { useStyles }
