import { IContactDto, IContactJobDto } from "api/dto"
import { createContext, useCallback, useContext, useState } from "react"

const nodeContext = createContext<ReturnType<typeof useProvideModal>>({} as any)

export function ProvideModal({ children }: { children: any }) {
  const currentNode = useProvideModal()
  return <nodeContext.Provider value={currentNode}>{children}</nodeContext.Provider>
}

export const useMultiStepsModal = () => useContext(nodeContext)

const useProvideModal = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [step, setStep] = useState(1)
  const [contactName, setContactName] = useState<string | undefined>()
  const [job, setJob] = useState<IContactJobDto | undefined>()
  const [rowData, setRowData] = useState<IContactDto | undefined>()
  const [tempStatusFromNotes, setTempStatusForStep] = useState<string | undefined>()
  const close = useCallback(() => {
    setIsOpen(false)
    setContactName(undefined)
    setJob(undefined)
    setStep(1)
  }, [])

  const open = useCallback(
    ({
      contactName: currentContactName,
      job: currentJob,
      rowData: rowDatas,
      tempStatus
    }: {
      contactName: string
      job: IContactJobDto
      rowData: IContactDto
      tempStatus: string
    }) => {
      setContactName(currentContactName)
      setJob(currentJob)
      setRowData(rowDatas)
      setIsOpen(true)
      setTempStatusForStep(tempStatus)
    },
    []
  )

  return {
    isOpen,
    contactName,
    job,
    step,
    rowData,
    setStep,
    open,
    close,
    tempStatusFromNotes
  }
}
