import React from "react"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"
import { Grid, TextField } from "@material-ui/core"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { useTranslation } from "react-i18next"
import { StatsType } from "api/dto"
import camelCase from "lodash/camelCase"

export interface IStatTypePickerProps {
  setType: (type?: string) => void
}

export const StatTypePicker: React.FC<IStatTypePickerProps> = (props) => {
  const { setType } = props
  const { t: translate } = useTranslation()

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid item xs={12}>
        <Autocomplete
          id="combo-stat-types-list"
          options={Object.keys(StatsType).map((t) => ({ label: translate(`Statistics.${t}.Label`), value: t }))}
          onChange={(e, value) => setType(camelCase(value?.value) ?? undefined)}
          getOptionLabel={(option: any) => option.label}
          renderInput={(params) => <TextField {...params} label={translate("Statistics.StatsType")} variant="outlined" />}
        />
      </Grid>
    </MuiPickersUtilsProvider>
  )
}
