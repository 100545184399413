import * as AWSCognito from "amazon-cognito-identity-js"
import axios from "axios"
import environment from "../../config/env"

const poolData = {
  UserPoolId: environment.cognito.userPoolId,
  ClientId: environment.cognito.clientId
}

export const userPool = new AWSCognito.CognitoUserPool(poolData)

export const setDefaultUrl = (url) => {
  axios.defaults.baseURL = url
}

export async function setRequestInterceptor() {
  axios.interceptors.request.use(async (config) => {
    const newConfig = { ...config }
    try {
      let cognitoUser
      await new Promise((resolve) => {
        cognitoUser = userPool.getCurrentUser()
        if (cognitoUser == null) {
          resolve({ payload: null })
        }
        cognitoUser.getSession((err, result) => {
          if (result) {
            cognitoUser.getUserAttributes((error, attrs) => {
              if (error) {
                resolve({ payload: null, err: error })
              } else {
                const payload = {}
                payload.user = {}
                attrs.forEach((attr) => {
                  payload.user[attr.Name] = attr.Value
                })
                payload.jwt = result.getIdToken().getJwtToken()
                resolve({ cognitoUser })
              }
            })
          } else {
            resolve({ payload: null, err })
          }
        })
      })

      const session = cognitoUser.signInUserSession
      if (!session) {
        return Promise.reject(new Error("No session found"))
      }

      newConfig.headers.common = {
        ...newConfig.headers.common,
        v2: true,
        Authorization: `Bearer ${session.getIdToken().getJwtToken()}`
      }
    } catch (error) {
      return Promise.reject(error)
    }

    return newConfig
  })
}
