import React, { useState, useMemo, useCallback, useRef, useEffect } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Form, Field } from "react-final-form"
import { Typography, Paper, Grid, Button, MenuItem } from "@material-ui/core"
import PropTypes from "prop-types"
import { Select } from "final-form-material-ui"
import Box from "@material-ui/core/Box"
import TextField from "@material-ui/core/TextField"
import { monthList } from "utils/date"
import { useMutateUpdateJob } from "api/hooks/update"
import { postActionInfusion } from "redux/action/superFetch"
import { useTranslation } from "react-i18next"
import { JobStatus } from "api/dto/IContactJobDto"
import { useMultiStepsModal } from "hooks/useMultiStepsModal"
import { postJobsDatas } from "../../../redux/contact/superFetch"
import { useStyles } from "../styles"

const validate = () => {
  const errors = {}

  return errors
}

const categoryList = [
  "Formation",
  "AptitudesLinguistiques",
  "AptitudesTechniques",
  "AttitudeProfilFit",
  "PasDinteretNonDisponible",
  "LocalisationMobilite",
  "SansNouvelles",
  "AttentesSalariales",
  "HoraireDeTravail",
  "PosteComble"
]

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component="span">{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

const RejectedDisclaimerNotes = ({ rowData, auth, status, tempStatus, setShowModalRejectedDisclaimerNotes }) => {
  const { Id, currentJob } = rowData
  const { t: translate } = useTranslation()
  const { open } = useMultiStepsModal()
  const inputRef = useRef(null)
  const classes = useStyles()
  const [textDisabled, setTextDisabled] = useState(true)
  const [notes, setNotes] = useState(currentJob.rejectedDisclaimerNotes)
  const months = useMemo(() => monthList, [])
  const { mutateAsync } = useMutateUpdateJob(Id, currentJob.jobTagId)

  const onSubmit = async (contact) => {
    const currentStatus = status !== "" ? status : contact.currentJob.status
    open({ contactName: contact.ContactName, job: currentJob, rowData, tempStatus: status })
    const job = { ...contact.currentJob, status: currentStatus, rejectedDisclaimerNotes: notes }
    await mutateAsync({ ...contact, currentJob: job })
    if (status === JobStatus.Hiring) {
      postActionInfuDatas(Id, "03903d06-6942-4394-8501-db489898b7aa")
    }

    setTextDisabled(true)
    setShowModalRejectedDisclaimerNotes(false)
  }

  const appendNewNote = useCallback(() => {
    setTextDisabled(!textDisabled)
    const actualtext = notes || ""
    const processedtext = `${generateLine()}\n\n${actualtext}`
    setNotes(processedtext)
  }, [months, setTextDisabled, setNotes])

  const generateLine = useCallback(() => {
    const d = new Date()
    const dates = d.getDate()
    const month = d.getMonth()
    const hours = d.getHours()
    const minutes0 = d.getMinutes() < 10 ? `0${d.getMinutes()}` : d.getMinutes()
    const totalDate = `${dates} ${months[month]} ${hours}:${minutes0}`

    return `${totalDate} [${auth.user.givenName}] : `
  }, [auth])

  useEffect(() => {
    if (!textDisabled) {
      const generated = generateLine()
      inputRef.current?.setSelectionRange(generated.length, generated.length)
      inputRef.current?.focus()
    }
  }, [inputRef, textDisabled])

  const updateText = (event) => {
    setNotes(event.target.value)
  }

  return (
    <Form onSubmit={onSubmit} initialValues={{ ...rowData }} validate={validate}>
      {({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit} noValidate>
          <h3 className={classes.h3}>Notes Desistement ou Rejeté</h3>
          <Paper className={classes.wrapper}>
            <Grid container alignItems="flex-start" className={classes.center} spacing={2}>
              <Grid container item direction="column" justifyContent="center" alignItems="center" spacing={2} xs={12} sm={3}>
                <Grid className={classes.raison} item xs={12} sm={12}>
                  <h4>Raison</h4>
                  <Field
                    name="currentJob.rejectedCategory"
                    component={Select}
                    className={classes.raisonField}
                    value={currentJob.rejectedCategory}
                    formControlProps={{ className: "fullwidth" }}
                  >
                    {categoryList.map((category) => (
                      <MenuItem key={translate(`Job.categoryList.${category}`)} value={translate(`Job.categoryList.${category}`)}>
                        {translate(`Job.categoryList.${category}`)}
                      </MenuItem>
                    ))}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Button onClick={appendNewNote} variant="contained" color="primary" disabled={!textDisabled}>
                    {translate("Job.AddNote")}
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={9}>
                <Grid item xs={12} sm={12}>
                  <Field name="currentJob.rejectedDisclaimerNotes">
                    {(props) => (
                      <div>
                        <TextField
                          fullWidth
                          rows="3"
                          inputRef={inputRef}
                          multiline
                          name={props.input.name}
                          value={notes}
                          onChange={updateText}
                          disabled={textDisabled}
                          variant="outlined"
                        />
                      </div>
                    )}
                  </Field>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <div className={classes.footer}>
                  <Button variant="contained" color="secondary" type="submit" disabled={submitting} style={{ float: "right" }}>
                    {translate("Common.Save")}
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Paper>
        </form>
      )}
    </Form>
  )
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      postContactsJobsDatas: postJobsDatas,
      postActionInfuDatas: postActionInfusion
    },
    dispatch
  )

const mapStateToProps = ({ auth }) => {
  return { auth }
}

export default connect(mapStateToProps, mapDispatchToProps)(RejectedDisclaimerNotes)
