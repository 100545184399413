import { REQUEST_CONCTACT_INFU_DATAS, RECEIVE_CONTACTS_INFU, RECEIVE_ERROR } from "./actions"

const initialState = {
  ContactInfuFetching: false,
  contactInfu: {},
  hasErrorInfu: false
}

function infuContactReducer(state = initialState, infu) {
  switch (infu.type) {
    case REQUEST_CONCTACT_INFU_DATAS:
      return {
        ...state,
        contactInfu: {},
        ContactInfuFetching: true
      }

    case RECEIVE_CONTACTS_INFU:
      return {
        ...state,
        contactInfu: infu.json,
        ContactInfuFetching: false,
        hasErrorInfu: false
      }

    case RECEIVE_ERROR:
      return {
        ...state,
        hasErrorInfu: true,
        isFetchingTag: false
      }
    default:
      return state
  }
}

export default infuContactReducer
