import { REQUEST_RESUME_ID_JOB_ID, RECEIVE_ERROR, RECEIVE_RESUME_DATAS, RECEIVE_RESUME_ID_JOB_ID, REQUEST_RESUME_DATAS } from "./actions"

const initialState = {
  isFetchingResumeId: false,
  isFetchingResumeDatas: false,
  resumeid: {},
  resumeDatas: {},
  hasErrorCV: false
}

function cvReducer(state = initialState, cv) {
  switch (cv.type) {
    case REQUEST_RESUME_ID_JOB_ID:
      return {
        ...state,
        resumeid: {},
        isFetchingResumeId: true
      }
    case REQUEST_RESUME_DATAS:
      return {
        ...state,
        resumeDatas: {},
        isFetchingResumeDatas: true
      }

    case RECEIVE_RESUME_ID_JOB_ID:
      return {
        ...state,
        resumeid: cv.json,
        isFetchingResumeId: false,
        hasErrorCV: false
      }
    case RECEIVE_RESUME_DATAS:
      return {
        ...state,
        resumeDatas: cv.json,
        isFetchingResumeDatas: false,
        hasErrorCV: false
      }

    case RECEIVE_ERROR:
      return {
        ...state,
        hasErrorCV: true,
        isFetching: false,
        isJobFetching: false
      }
    default:
      return state
  }
}

export default cvReducer
