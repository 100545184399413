import axios from "axios"
import { requestSovrenDatas, receiveSovren, receiveError, requestSovrenDatasMatch, receiveSovrenMatch } from "./actions"

export function fetchSovren() {
  return (dispatch) => {
    dispatch(requestSovrenDatas())
    axios({
      url: `/contacts/ui/searcher`,
      method: "post"
    })
      .then((res) => {
        return dispatch(receiveSovren(res.data))
      })
      .catch((err) => dispatch(receiveError(err)))
  }
}

export function fetchSovrenMatch(contactId) {
  return (dispatch) => {
    dispatch(requestSovrenDatasMatch())
    axios({
      url: `/contacts/ui/matcher/${contactId}`,
      method: "post"
    })
      .then((res) => {
        return dispatch(receiveSovrenMatch(res.data))
      })
      .catch((err) => dispatch(receiveError(err)))
  }
}
