import { Tooltip } from "@material-ui/core"
import { StatsType, StatsCategory } from "api/dto/IStatsDto"
import React from "react"
import { useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { capitalize } from "utils/string"
// import { Checkbox, FormControlLabel } from "@material-ui/core"
import InfoIcon from "@material-ui/icons/Info"
import { useStyleStats } from "./styles"

export const defaultTemplates = [
  StatsType.ToSort,
  StatsType.Sourcing,
  StatsType.GoogleVerification,
  StatsType.Email,
  StatsType.Prequalification,
  StatsType.TelephoneInterview,
  StatsType.PartnerInterview,
  // StatsType.ClientInterview,
  StatsType.Hiring,
  StatsType.Documents,
  StatsType.ApplicationCompliance,
  StatsType.Tests,
  StatsType.Reference,
  StatsType.CandidateFollowUp,
  StatsType.SelectionCommittee,
  StatsType.ClientFollowUp,
  StatsType.BillableMPO,
  StatsType.BillableEnglishOral,
  StatsType.BillableEnglishWritten,
  StatsType.BillableFrench,
  StatsType.BillableWord,
  StatsType.BillableExcel,
  StatsType.BillablePowerPoint,
  StatsType.BillableDigComp
]

interface IStatCheckRowProps {
  type: string
}

const Stats: React.FC<IStatCheckRowProps> = (props) => {
  const { type } = props
  const classes = useStyleStats()
  const { t: translate } = useTranslation()

  const { register } = useFormContext()

  return (
    <div className={classes.stat}>
      <input type="checkbox" id={`${type}`} placeholder={`${type}`} {...register(`${type}`, {})} />
      <label className={classes.label} htmlFor={`${type}`}>
        {translate(`Statistics.${capitalize(type)}.Label`)}
      </label>
      <Tooltip title={translate(`Statistics.${capitalize(type)}.Reason`)}>
        <InfoIcon fontSize="small" />
      </Tooltip>
    </div>
  )
}

export default Stats
