import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import FormLabel from "@material-ui/core/FormLabel"
import FormControl from "@material-ui/core/FormControl"
import FormGroup from "@material-ui/core/FormGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Switch from "@material-ui/core/Switch"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"

import {
  setMuiGroupingStatus,
  setMuiOptionsPageSize,
  setMuiOptionsSorting,
  setMuiOptionsGrouping,
  setMuiOptionsFiltering,
  setMuiOptionsHidden,
  setMuiOptionsDraggable,
  setMuiOptionsPadding
} from "../../redux/mui/actions"

const Dispatcher = ({
  muiGroupingStatus,
  muiOptionsPageSize,
  muiOptionsSorting,
  muiOptionsGrouping,
  muiOptionsFiltering,
  muiOptionsHidden,
  muiOptionsDraggable,
  muiOptionsPadding,
  mui
}) => {
  let { status } = mui.grouping
  const { pageSize, padding } = mui.options
  let { filtering, grouping, sorting, hidden, draggable } = mui.options
  return (
    <div>
      <h1>MUI</h1>
      <FormControl component="fieldset">
        <FormLabel component="legend">{"state > mui > grouping"}</FormLabel>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={status}
                onChange={() => {
                  muiGroupingStatus((status = !status))
                }}
                value="status"
              />
            }
            label="statuts"
          />
        </FormGroup>
      </FormControl>
      <hr />
      <br />
      <br />
      <FormControl component="fieldset">
        <FormLabel component="legend">state &gt; mui &gt; options</FormLabel>
        <FormGroup>
          <FormControlLabel
            control={
              <Select
                labelId="demo-simple-select-filled-label"
                id="demo-simple-select-filled"
                value={pageSize}
                onChange={(e) => muiOptionsPageSize(e.target.value)}
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
              </Select>
            }
            label="pageSize"
          />
          <FormControlLabel
            control={
              <Select
                labelId="demo-simple-select-filled-label"
                id="demo-simple-select-filled"
                value={padding}
                onChange={(e) => muiOptionsPadding(e.target.value)}
              >
                <MenuItem value="default">Default</MenuItem>
                <MenuItem value="dense">Dense</MenuItem>
              </Select>
            }
            label="padding"
          />
          <FormControlLabel
            control={
              <Switch
                checked={sorting}
                onChange={() => {
                  muiOptionsSorting((sorting = !sorting))
                }}
                value="sorting"
              />
            }
            label="sorting"
          />
          <FormControlLabel
            control={
              <Switch
                checked={grouping}
                onChange={() => {
                  muiOptionsGrouping((grouping = !grouping))
                }}
                value="grouping"
              />
            }
            label="grouping"
          />
          <FormControlLabel
            control={
              <Switch
                checked={filtering}
                onChange={() => {
                  muiOptionsFiltering((filtering = !filtering))
                }}
                value="filtering"
              />
            }
            label="filtering"
          />
          <FormControlLabel
            control={
              <Switch
                checked={hidden}
                onChange={() => {
                  muiOptionsHidden((hidden = !hidden))
                }}
                value="hidden"
              />
            }
            label="hidden"
          />
          <FormControlLabel
            control={
              <Switch
                checked={draggable}
                onChange={() => {
                  muiOptionsDraggable((draggable = !draggable))
                }}
                value="draggable"
              />
            }
            label="draggable"
          />
        </FormGroup>
      </FormControl>
    </div>
  )
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      muiGroupingStatus: setMuiGroupingStatus,
      muiOptionsPageSize: setMuiOptionsPageSize,
      muiOptionsSorting: setMuiOptionsSorting,
      muiOptionsGrouping: setMuiOptionsGrouping,
      muiOptionsFiltering: setMuiOptionsFiltering,
      muiOptionsHidden: setMuiOptionsHidden,
      muiOptionsDraggable: setMuiOptionsDraggable,
      muiOptionsPadding: setMuiOptionsPadding
    },
    dispatch
  )

export const mapStateToProps = ({ mui }) => {
  return { mui }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dispatcher)
