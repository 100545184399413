import React from "react"
import ReactDOM from "react-dom"
import { ConnectedRouter } from "connected-react-router"
import { HashRouter } from "react-router-dom"
import { Provider } from "react-redux"
import { ReactQueryDevtools } from "react-query/devtools"
import { QueryClientProvider, QueryClient } from "react-query"
import { ToastContainer } from "react-toastify"
import { Integrations } from "@sentry/tracing"
import * as Sentry from "@sentry/react"
import configureStore, { history } from "./redux/configureStore"
import { App } from "./App"
import "react-toastify/dist/ReactToastify.css"
import environment, { LOCAL } from "./config/env"

if (environment.defaultEnvPrefix !== LOCAL && process.env.REACT_APP_SENTRY_RELEASE) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    release: process.env.REACT_APP_SENTRY_RELEASE
  })
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false
    }
  }
})

const store = configureStore()

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <HashRouter>
          <App />
          <ReactQueryDevtools />
          <ToastContainer pauseOnHover autoClose={5000} position="bottom-right" closeOnClick />
        </HashRouter>
      </ConnectedRouter>
    </Provider>
  </QueryClientProvider>,
  document.getElementById("root")
)
