export interface IContactJobDto {
  clientTagId?: string
  contactId: string // Infu Id
  jobTagId: string
  jobTitle?: string
  lastUpdated?: string
  status: JobStatus
  standby: boolean
  notes?: string
  rejectedDisclaimerNotes?: string
  appreciationPartenaireDaffaire?: string
  appreciationClient?: string
  prequalification?: IPrequalificationDto
  createdAt?: string
  modifiedAt?: string
  cvOrigin?: string
}

export interface IPrequalificationDto {
  motivations?: string
  formations?: string
  experiencesProfessionnelles?: string
  aptitudesTechniques?: string
  aptitudesLinguistiques?: string
  disponibiliteMobilibite?: string
  attentesSalariales?: string
  dateEntrevuePersonne?: string
  dateEntrevueClient?: string
  dateEntrevueRecruto?: string
  appreciationRecruteur?: string
}

// eslint-disable-next-line no-shadow
export enum JobStatus {
  Sourcing = "SOURCING",
  ToSort = "B_TO_SORT",
  TelephoneInterview = "C_ENTREVUE_TELEPHONIQUE",
  Documents = "D_DOCUMENTS",
  PartnerInterview = "E_ENTREVUE_PARTENAIRE",
  ClientInterview = "F_ENTREVUE_CLIENT",
  Reference = "G_REFERENCES",
  Tests = "H_TESTS",
  StandBy = "I_STAND_BY",
  ReadyToHire = "J_PRET_A_LEMBAUCHE",
  Hiring = "K_EMBAUCHE",
  Desistement = "L_DESISTEMENT",
  Rejected = "M_REJETE",
  Transfered = "N_Transfert_autre_affichage",
  JobFilled = "O_JOB_FILLED"
}

// eslint-disable-next-line no-shadow
export enum JobSearchFields {
  id = "id",
  notes = "notes",

  motivations = "prequal_motivations",
  formations = "prequal_formations",
  professionalExperiences = "prequal_experiencesProfessionnelles",
  technicalAptitude = "prequal_aptitudesTechniques",
  languages = "prequal_aptitudesLinguistiques",
  mobility = "prequal_disponibiliteMobilibite",
  salaryExpectations = "prequal_attentesSalariales",
  recruterAppreciation = "prequal_appreciationRecruteur",

  clientAppreciation = "appreciationClient",
  businessPartnerAppreciation = "appreciationPartenaireDaffaire",

  rejectedDisclaimerNotes = "rejectedDisclaimerNotes",
  rejectedCategory = "rejectedCategory"
}

export const DefaultJobStatus: JobStatus = JobStatus.ToSort
