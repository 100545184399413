import React from "react"
import { useMutationUpdateContact } from "api/hooks/update"
import { Modal } from "components/modal/modal"
import { useTranslation } from "react-i18next"

interface IDeleteCandidacyProps {
  contactId: string
  tagsToRemove: string[]
  jobId?: string
  contactName: string
  open: boolean
  onClose: () => void
}

const DeleteCandidacy: React.FC<IDeleteCandidacyProps> = (props) => {
  const { contactId, tagsToRemove, jobId, contactName, open, onClose } = props
  const { t: translate } = useTranslation()

  const { mutateAsync: editContact } = useMutationUpdateContact(jobId)

  const submit = async () => {
    onClose()
    await editContact({ contactId, tagsToRemove })
  }

  return (
    <Modal
      title={translate(`Contact.DeleteCandidacyTitle`)}
      open={open}
      fullWidth
      maxWidth="md"
      close={onClose}
      action={submit}
      saveText={translate("Common.Delete")}
    >
      <p>{translate(`Contact.DeleteCandidacyConfirmation`, { contactName })}</p>
    </Modal>
  )
}

export default DeleteCandidacy
