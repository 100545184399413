import { ANCIA_CLIENT_ID } from "api/constants"

export const ClientIdCompany = [
  {
    name: "Duvaltex Candidat",
    id: 9178
  },

  {
    name: "UnicornPowered Candidat",
    id: 8808
  },
  {
    name: "Garant Candidat",
    id: 8250
  },

  {
    name: "ANCIA Candidat",
    id: ANCIA_CLIENT_ID
  }
]

export const CvOriginList = [
  { name: "CarrierPage" },
  { name: "LinkedIn" },
  { name: "Indeed" },
  { name: "Jobillico" },
  { name: "Jobboom" },
  { name: "ProvidedByClient" },
  { name: "Isarta" },
  { name: "EmployeeReference" },
  { name: "Facebook" },
  { name: "QuebecEmployment" },
  { name: "Other" }
]
