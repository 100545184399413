import { createStore, applyMiddleware, compose } from "redux"
import thunk from "redux-thunk"
import { createHashHistory } from "history"
import { routerMiddleware } from "connected-react-router"
import createSagaMiddleware from "redux-saga"
import rootReducer from "./rootReducer"
import rootSaga from "./auth/sagas"

export const history = createHashHistory()
const isProd = process.env.REACT_APP_ENV === "production"

export default function configureStore(preloadedState) {
  const sagaMiddleware = createSagaMiddleware()
  const routeMiddleware = routerMiddleware(history)

  const middleWareDevelopment = [thunk, sagaMiddleware, routeMiddleware]

  // Only includes in DEV mode
  // if (!isProd) {
  //   // eslint-disable-next-line @typescript-eslint/no-var-requires, global-require
  //   const freeze = require("redux-immutable-state-invariant").default()
  //   middleWareDevelopment.push(freeze)
  // }

  const middleWareProduction = [...middleWareDevelopment]

  let customMiddleware = middleWareDevelopment
  if (!isProd) {
    customMiddleware = middleWareProduction
  }
  const store = createStore(
    rootReducer(history),
    compose(applyMiddleware(...customMiddleware), window.devToolsExtension ? window.devToolsExtension() : (f) => f),
    preloadedState
  )
  sagaMiddleware.run(rootSaga)

  return store
}
