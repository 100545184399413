import { createStyles, InputLabel, makeStyles, Popover, Theme, Typography } from "@material-ui/core"
import React, { useMemo, useState } from "react"
import HelpIcon from "@material-ui/icons/Help"

interface ILabelWithPopoverProps {
  id: string
  labelText: string
  popoverText: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popover: {
      pointerEvents: "none"
    },
    paper: {
      padding: theme.spacing(1)
    }
  })
)

export const LabelWithPopover: React.FunctionComponent<ILabelWithPopoverProps> = (props) => {
  const { id, labelText, popoverText } = props
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const open = useMemo(() => Boolean(anchorEl), [anchorEl])
  const classes = useStyles()

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  return (
    <div className="input-label">
      <InputLabel htmlFor="required">{labelText}</InputLabel>
      <div
        aria-owns={id}
        aria-haspopup="true"
        style={{ height: "25px" }}
        onMouseEnter={(e) => handlePopoverOpen(e)}
        onMouseLeave={handlePopoverClose}
      >
        <HelpIcon style={{ width: "20px" }} />
      </div>
      <Popover
        id={id}
        open={open}
        className={classes.popover}
        classes={{
          paper: classes.paper
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left"
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography>{popoverText}</Typography>
      </Popover>
    </div>
  )
}
