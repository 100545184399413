import React from "react"
import { ITagDto } from "api/dto/ITagDto"
import { useQueryContacts } from "api/hooks"
import { useContactJobs } from "utils/jobs"
import { ProvideModal } from "hooks/useMultiStepsModal"
import { IJobDto } from "api/dto"
import { ContactTableJob } from "../ContactsList/contactTableJob"

interface IJobContactsProps {
  tags: ITagDto[]
  isFetchingTags: boolean
  jobsList: IJobDto[]
}

export const JobContacts: React.FC<IJobContactsProps> = (props) => {
  const { tags, isFetchingTags, jobsList } = props
  const { currentJobId } = useContactJobs()

  useQueryContacts({ jobId: currentJobId, withDispatch: true })

  return (
    <ProvideModal>
      <ContactTableJob tags={tags} isLoadingTags={isFetchingTags} isJob jobsList={jobsList} />
    </ProvideModal>
  )
}
