export const REQUEST_CONTACTS = "REQUEST_CONTACTS"
export const RECEIVE_CONTACTS = "RECEIVE_CONTACTS"
export const UPDATE_CONTACT_JOB = "UPDATE_CONTACT_JOB"
export const SEND_POST_JOBS_DATAS = "SEND_POST_JOBS_DATAS"
export const RECEIVE_ERROR = "RECEIVE_ERROR"

export const sendPostJobsDatas = () => ({
  type: SEND_POST_JOBS_DATAS
})

export const requestContactsDatas = (contacts) => ({
  type: REQUEST_CONTACTS,
  json: contacts
})

export const receiveContactsDatas = (contacts) => ({
  type: RECEIVE_CONTACTS,
  json: contacts
})

export const updateContact = (contact) => ({
  type: UPDATE_CONTACT_JOB,
  json: contact
})

export const receiveError = (err) => ({
  type: RECEIVE_ERROR,
  error: err.data
})
