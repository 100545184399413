const initialState = {
  grouping: {
    status: true
  },
  localization: {
    pagination: {
      labelDisplayedRows: "{from}-{to} de {count}",
      labelRowsSelect: "lignes",
      firstTooltip: "Premier",
      previousTooltip: "Précédent",
      nextTooltip: "Suivant",
      lastTooltip: "Dernier"
    },
    grouping: {
      groupedBy: "Grouper par: ",
      placeholder: "Faites glisser pour regrouper"
    },
    toolbar: {
      nRowsSelected: "{0} lignes(s) sélectionner",
      searchPlaceholder: "Rechercher..."
    },
    header: {
      actions: "Actions"
    },
    body: {
      emptyDataSourceMessage: "Aucun enregistrement à afficher",
      filterRow: {
        filterTooltip: "Filtres"
      },
      addTooltip: "Ajouter"
    }
  },
  options: {
    draggable: false,
    filtering: false,
    grouping: false,
    hidden: false,
    padding: "dense",
    pageSize: 50,
    pageSizeOptions: [5, 10, 25, 50, 100],
    selection: false,
    sorting: true
  }
}

export default initialState
