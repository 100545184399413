import React from "react"
import "./animation.scss"

export const Bubble = () => (
  <ul className="bg-bubbles">
    <li />
    <li />
    <li />
    <li />
    <li />
    <li />
    <li />
    <li />
    <li />
    <li />
    <li />
  </ul>
)
