import { JobStatus } from "aws-sdk/clients/batch"

/* eslint-disable no-shadow */
export interface IStatsDto {
  id?: string
  clientId?: string
  createdAt?: string
  category?: string
  type: StatsType
  status: JobStatus
  createdBy?: string
  createdById?: string
  contactId: string
  jobId: string
  jobName: string
  contactName: string
}

export interface IStatTemplate {
  type: string
  category: string
}

export interface IStatParams {
  contactId?: string
  jobId?: string
  start: string
  end?: string
  type?: string
}

export enum StatsCategory {
  advancement = "advancement",
  process = "process"
}

export enum StatsType {
  Sourcing = "sourcing",
  ToSort = "toSort",
  GoogleVerification = "googleVerification",
  Prequalification = "prequalification",
  TelephoneInterview = "telephoneInterview",
  Email = "email",
  PartnerInterview = "partnerInterview",
  Documents = "documents",
  Tests = "tests",
  Reference = "reference",
  ApplicationCompliance = "applicationCompliance",
  SelectionCommittee = "SelectionCommittee",
  CandidateFollowUp = "candidateFollowUp",
  ClientFollowUp = "clientFollowUp",
  ClientInterview = "clientInterview",
  Hiring = "hiring",
  BillableMPO = "BillableMPO",
  BillableEnglishOral = "BillableEnglishOral",
  BillableEnglishWritten = "BillableEnglishWritten",
  BillableFrench = "BillableFrench",
  BillableWord = "BillableWord",
  BillableExcel = "BillableExcel",
  BillablePowerPoint = "BillablePowerPoint",
  BillableDigComp = "BillableDigComp"
}
