/* eslint-disable no-await-in-loop */
import React, { useCallback } from "react"
import { useDropzone } from "react-dropzone"
import CloudUploadIcon from "@material-ui/icons/CloudUpload"
import { CircularProgress, Fade } from "@material-ui/core"
import { useMutateAddDocument, useMutateAddFolder, useMutatePutDocumentRequest } from "api/hooks/add"
import { useTranslation } from "react-i18next"
import { Modal } from "components/modal/modal"
import useStyles from "../styles"

interface IAddFilesProps {
  opened: boolean
  toggle: () => void
  contactId: string
  name?: string
  folderId?: string
  jobTagId: string
}

export const AddFiles: React.FC<IAddFilesProps> = (props) => {
  const { opened, toggle, contactId, name, folderId, jobTagId } = props
  const classes = useStyles()
  const { t: translate } = useTranslation()
  const [query, setQuery] = React.useState("idle")
  const { mutateAsync: addFolder } = useMutateAddFolder(contactId, jobTagId)
  const { mutateAsync: putFileRequest } = useMutatePutDocumentRequest(contactId)
  const { mutateAsync: addFile } = useMutateAddDocument(contactId, jobTagId)

  const onDrop = useCallback(async (acceptedFiles) => {
    setQuery("progress")

    let currentFolderId = folderId
    if (!currentFolderId) {
      const { data: createdFolder } = await addFolder({ name })
      currentFolderId = createdFolder.folderId!
    }

    for (let i = 0; i < acceptedFiles.length; i++) {
      const f = acceptedFiles[i]
      const cvFileExtension = f.type
      // eslint-disable-next-line no-await-in-loop
      const addedFile = await putFileRequest({
        path: `${name}`,
        name: `${f.name}`,
        contentType: `${cvFileExtension}`
      })

      await addFile({ url: addedFile.data, file: f })

      if (i === acceptedFiles.length - 1) {
        setQuery("success")
      }
    }
  }, [])

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({ onDrop })

  const files = acceptedFiles.map((file) => (
    <div key={file.name}>
      {query === "success" ? (
        <span>
          {file.name} {translate(`Success`)}!
        </span>
      ) : (
        <Fade
          in={query === "progress" || query === "idle"}
          style={{
            transitionDelay: query === "progress" ? "800ms" : "0ms"
          }}
          unmountOnExit
        >
          <CircularProgress />
        </Fade>
      )}
    </div>
  ))
  return (
    <>
      <Modal title={`${translate(`Document.AddDocumentToFolder`)} ${name}`} open={opened} fullWidth maxWidth="md" close={toggle}>
        <section
          className="container"
          style={{
            height: "100%",
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          {query !== "progress" && (
            <div
              style={{
                borderWidth: "2px",
                borderStyle: "dashed",
                padding: "20px",
                textAlign: "center",
                cursor: "pointer"
              }}
              {...getRootProps({ className: "dropzone" })}
            >
              <input {...getInputProps()} />
              <p>{translate(`Document.UploadFile`)}</p>
              <CloudUploadIcon className="cloud-up-icon" style={{ fontSize: 50 }} />
            </div>
          )}
          <aside>
            <ul style={{ textAlign: "center", listStyle: "none" }}>{files}</ul>
          </aside>
        </section>
      </Modal>
    </>
  )
}
