/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useMemo } from "react"
import { Card, Link } from "@material-ui/core"
// @ts-ignore

import DataTableExtensions from "react-data-table-component-extensions"
import DataTable, { IDataTableColumn } from "react-data-table-component"
import { useTranslation } from "react-i18next"
import { IStatsDto } from "api/dto"
import { CSVLink } from "react-csv"
import { formatDate } from "utils/date"
import { capitalize } from "utils/string"
import "react-data-table-component-extensions/dist/index.css"
import GetAppIcon from "@material-ui/icons/GetApp"

interface IStatsTableProps {
  stats?: IStatsDto[]
  isLoadingStats: boolean
}
interface IHeader {
  label: string
  key: string
}
interface IDataExport {
  header: IHeader[]
  data: IStatsDto[]
  fileName: string
}
export const StatsTable: React.FC<IStatsTableProps> = (props) => {
  const { stats, isLoadingStats } = props
  const { t: translate } = useTranslation()

  const [datasExport, setDatasExport] = React.useState<IDataExport | undefined>({
    header: [],
    data: [],
    fileName: ""
  })

  const columns: IDataTableColumn<IStatsDto>[] = useMemo(
    () => [
      {
        name: translate("Statistics.Fields.Listing"),
        sortable: true,
        selector: "jobName",
        cell: (rowData) => {
          const { jobId, jobName } = rowData
          return <Link href={`/#/jobs/${jobId}`}>{jobName}</Link>
        }
      },
      {
        name: translate("Statistics.Fields.Contact"),
        sortable: true,
        selector: "contactName",
        cell: (rowData) => rowData.contactName
      },
      {
        name: translate("Statistics.Fields.DateTime"),
        sortable: true,
        selector: "createdAt",
        cell: (rowData) => formatDate(rowData.createdAt)
      },
      {
        name: translate("Statistics.Fields.Status"),
        sortable: true,
        selector: "status",
        cell: (rowData) => translate(`Job.Status.${rowData.status}`)
      },
      {
        name: translate("Statistics.Fields.Type"),
        sortable: true,
        selector: "type",
        cell: (rowData) => translate(`Statistics.${capitalize(rowData.type)}.Label`)
      },
      {
        name: translate("Statistics.Fields.User"),
        sortable: false,
        selector: "createdBy",
        cell: (rowData) => rowData.createdBy
      }
    ],
    []
  )
  const tableData = useMemo(() => ({ columns, data: stats || [] }), [columns, stats])

  useEffect(() => {
    const date = new Date()
    const dayMonthYear = ` ${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
    tableData.data.forEach((data) => {
      data.createdAt = formatDate(data.createdAt)
    })
    const newFileName = window?.location?.hash?.includes("stats") ? "Stats" : tableData?.data[0]?.jobName
    setDatasExport({
      header: [
        { label: "Affichage", key: "jobName" },
        { label: "Nom du contact", key: "contactName" },
        { label: "Date/Heure", key: "createdAt" },
        { label: "Statut", key: "status" },
        { label: "Activité recrutement", key: "type" },
        { label: "Usager", key: "createdBy" }
      ],
      data: tableData.data,
      fileName: newFileName + dayMonthYear
    })
  }, [stats])

  return (
    <Card style={{ marginTop: "20px", position: "relative" }}>
      <CSVLink
        data={datasExport?.data}
        style={{
          textDecoration: "none",

          right: ".75rem",
          top: ".75rem",
          position: "absolute",
          backgroundColor: "rgba(000, 000, 000, 0.500)",
          padding: "0.25rem",
          borderRadius: ".25rem",
          zIndex: 2,
          lineHeight: 0
        }}
        filename={datasExport?.fileName}
        headers={datasExport?.header}
      >
        <GetAppIcon style={{ color: "#fff" }} />
      </CSVLink>

      <DataTableExtensions {...tableData} export={false} print={false}>
        {/* @ts-ignore */}

        <DataTable
          noHeader
          highlightOnHover
          persistTableHead
          defaultSortAsc
          defaultSortField="contactName"
          progressPending={isLoadingStats}
          progressComponent={<div style={{ fontSize: "24px", fontWeight: 700, padding: "24px" }}>{translate("Common.Loading")}</div>}
          noDataComponent={<div style={{ fontSize: "24px", fontWeight: 700, padding: "24px" }}>{translate("Common.NoData")}</div>}
        />
      </DataTableExtensions>
    </Card>
  )
}
