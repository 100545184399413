export const REQUEST_CONCTACT_INFU_DATAS = "REQUEST_CONCTACT_INFU_DATAS"
export const RECEIVE_CONTACTS_INFU = "RECEIVE_CONTACTS_INFU"
export const RECEIVE_ERROR = "RECEIVE_ERROR"

export const requestContactsInfuDatas = (action) => ({
  type: REQUEST_CONCTACT_INFU_DATAS,
  json: action
})

export const receiveContactsInfu = (action) => ({
  type: RECEIVE_CONTACTS_INFU,
  json: action
})

export const receiveError = (err) => ({
  type: RECEIVE_ERROR,
  error: err.data
})
