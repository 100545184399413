/* eslint-disable no-unused-vars */
import React from "react"
import { TextField } from "@material-ui/core"
import { useMutateAddFolder } from "api/hooks/add"
import { IFolderDto } from "api/dto/IFolderDto"
import { useMutateUpdateFolder } from "../../../../api/hooks/update"

interface INotesFolderProps {
  contactId: string
  jobTagId: string
  folder: IFolderDto
}

export const NotesFolder: React.FC<INotesFolderProps> = (props) => {
  const { contactId, folder, jobTagId } = props
  const { mutateAsync: addFolder, isLoading: isAddingFolder } = useMutateAddFolder(contactId, jobTagId)
  const { mutateAsync: updateFolder, isLoading: isUpdatingFolder } = useMutateUpdateFolder(contactId, jobTagId, folder.folderId)

  const handleNotesChange = async (notes: string) => {
    if (folder.folderId) {
      await updateFolder({ notes })
    } else {
      await addFolder({ name: folder.name, notes })
    }
  }

  return (
    <TextField
      disabled={isAddingFolder || isUpdatingFolder}
      style={{ width: "100%" }}
      id="outlined-multiline-static"
      label="Commentaire"
      multiline
      onBlur={(event) => handleNotesChange(event.target.value)}
      rows={3}
      defaultValue={folder.notes}
      variant="outlined"
    />
  )
}
