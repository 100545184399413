/* eslint-disable max-len */
import React from "react"
import { useStyles } from "./styles"

const Logotype = () => {
  const classes = useStyles()
  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Calque_1" x="0px" y="0px" viewBox="0 0 538.8 113.7" className={classes.root}>
      <title>Logo Recruto</title>
      <linearGradient
        id="SVGID_1_"
        gradientUnits="userSpaceOnUse"
        x1="26.021"
        y1="-3714.4629"
        x2="92.4209"
        y2="-3648.063"
        gradientTransform="matrix(1 0 0 -1 0 -3624.6838)"
      >
        <stop offset="0" style={{ stopColor: "#FF2F15", stopOpacity: "0.5" }} />
        <stop offset="1" style={{ stopColor: "#FEFF2A", stopOpacity: "0.5" }} />
      </linearGradient>
      <polygon className={classes.st0} points="45.9,39.7 68.9,39.7 75.9,39.7 75.9,70 88.9,70 88.9,26.8 45.9,26.8 " />
      <linearGradient
        id="SVGID_2_"
        gradientUnits="userSpaceOnUse"
        x1="6.0958"
        y1="-3734.3379"
        x2="72.4959"
        y2="-3667.938"
        gradientTransform="matrix(1 0 0 -1 0 -3624.6838)"
      >
        <stop offset="0" style={{ stopColor: "#FF2F15", stopOpacity: "0.5" }} />
        <stop offset="1" style={{ stopColor: "#FEFF2A", stopOpacity: "0.5" }} />
      </linearGradient>
      <rect x="45.9" y="46.7" className={classes.st1} width="23" height="23.3" />
      <linearGradient
        id="SVGID_3_"
        gradientUnits="userSpaceOnUse"
        x1="45.825"
        y1="-3694.6089"
        x2="112.225"
        y2="-3628.2087"
        gradientTransform="matrix(1 0 0 -1 0 -3624.6838)"
      >
        <stop offset="0" style={{ stopColor: "#FF2F15", stopOpacity: "0.5" }} />
        <stop offset="1" style={{ stopColor: "#FEFF2A", stopOpacity: "0.5" }} />
      </linearGradient>
      <rect x="45.9" y="46.7" className={classes.st2} width="23" height="23.3" />
      <linearGradient
        id="SVGID_4_"
        gradientUnits="userSpaceOnUse"
        x1="25.95"
        y1="-3714.4338"
        x2="92.3501"
        y2="-3648.0337"
        gradientTransform="matrix(1 0 0 -1 0 -3624.6838)"
      >
        <stop offset="0" style={{ stopColor: "#FF2F15", stopOpacity: "0.5" }} />
        <stop offset="1" style={{ stopColor: "#FEFF2A", stopOpacity: "0.5" }} />
      </linearGradient>
      <polygon className={classes.st3} points="45.9,46.7 26,46.7 26,89.8 68.9,89.8 68.9,70 45.9,70 " />
      <linearGradient
        id="SVGID_5_"
        gradientUnits="userSpaceOnUse"
        x1="45.871"
        y1="-3694.6128"
        x2="112.271"
        y2="-3628.2129"
        gradientTransform="matrix(1 0 0 -1 0 -3624.6838)"
      >
        <stop offset="0" style={{ stopColor: "#FF2F15", stopOpacity: "0.5" }} />
        <stop offset="1" style={{ stopColor: "#FEFF2A", stopOpacity: "0.5" }} />
      </linearGradient>
      <polygon className={classes.st4} points="45.9,39.7 68.9,39.7 75.9,39.7 75.9,70 88.9,70 88.9,26.8 45.9,26.8 " />
      <linearGradient
        id="SVGID_6_"
        gradientUnits="userSpaceOnUse"
        x1="25.9959"
        y1="-3714.438"
        x2="92.396"
        y2="-3648.0378"
        gradientTransform="matrix(1 0 0 -1 0 -3624.6838)"
      >
        <stop offset="0" style={{ stopColor: "#FF2F15", stopOpacity: "0.5" }} />
        <stop offset="1" style={{ stopColor: "#FEFF2A", stopOpacity: "0.5" }} />
      </linearGradient>
      <rect x="45.9" y="46.7" className={classes.st5} width="23" height="23.3" />
      <linearGradient
        id="SVGID_7_"
        gradientUnits="userSpaceOnUse"
        x1="6.0709"
        y1="-3734.313"
        x2="72.471"
        y2="-3667.9128"
        gradientTransform="matrix(1 0 0 -1 0 -3624.6838)"
      >
        <stop offset="0" style={{ stopColor: "#FF2F15", stopOpacity: "0.5" }} />
        <stop offset="1" style={{ stopColor: "#FEFF2A", stopOpacity: "0.5" }} />
      </linearGradient>
      <polygon className={classes.st6} points="45.9,46.7 26,46.7 26,89.8 68.9,89.8 68.9,70 45.9,70 " />
      <g className={classes.st7}>
        <path
          className={classes.st8}
          d="M190.5,91.5l-16.2-28.1h-9.6v28.1h-14.2V15.2h27.7c14.9,0,25.4,9.2,25.4,23.9c0,10.1-5.8,18-15.2,21.5l17.7,31   L190.5,91.5L190.5,91.5z M164.7,50h12.1c7.6,0,12.1-4.6,12.1-10.7c0-6.4-4.5-10.6-12.1-10.6h-12.1V50z"
        />
        <path
          className={classes.st8}
          d="M263.2,74.3c-2.6,11.9-13.3,18.3-25.6,18.3c-17,0-27.9-11.6-27.9-28.4s12-28.2,26.9-28.2   c14.5,0,28.1,8.9,26.3,32.2h-39.6c1.1,7.8,5.6,13,14.5,13c5.3,0,10-2.4,12-6.8L263.2,74.3L263.2,74.3z M223.9,57.8h25.9   c-0.2-5.5-5-10.8-12.5-10.8C230.5,47,225.6,51,223.9,57.8z"
        />
        <path
          className={classes.st8}
          d="M298.4,92.6c-17.8,0-28.3-12.3-28.3-28.1c0-16.5,12.4-28.6,28.2-28.6c13.3,0,24.5,8.3,25.8,21.6H310   c-0.9-4.5-4.6-9.2-12-9.2c-8.3,0-13.7,6.4-13.7,16.1c0,9.6,4.9,15.9,14,15.9c6.2,0,10.2-3.5,11.7-8.1h14.4   C322.7,84.3,312.5,92.6,298.4,92.6z"
        />
        <path
          className={classes.st8}
          d="M369.4,35.9v14.2c-1-0.2-3.7-0.7-5.7-0.7c-8,0-15.1,5-15.1,18.1v24h-14.7V37h11.6l2,7.5h1.2   c3.3-4.6,7.8-8.6,16.9-8.6H369.4z"
        />
        <path
          className={classes.st8}
          d="M376.8,67.8V37h14.7v28.4c0,9.6,4.8,14.2,11.6,14.2c5.9,0,11.2-4,11.2-13.8V37H429v54.5h-11.4l-1.9-6.6h-1.2   c-2.5,3.2-6.4,7.7-16.6,7.7C385.7,92.6,376.8,84.1,376.8,67.8z"
        />
        <path
          className={classes.st8}
          d="M462.2,92.6c-9.5,0-16.6-4-16.6-17.4V48.3H435V38h3.4c5.7,0,8.6-2.9,8.6-7.6v-7.1h12.9V37h13.5v11.3h-13.5   v25.5c0,4.4,2,6.3,5,6.3c2.2,0,4.1-1,5.1-1.5l2.9,11C470.6,91.1,467,92.6,462.2,92.6z"
        />
        <path
          className={classes.st8}
          d="M477.2,64.2c0-16.9,12.9-28.3,28.8-28.3c16,0,28.7,11.4,28.7,28.3c0,16.8-12.5,28.3-28.6,28.3   C490,92.6,477.2,81,477.2,64.2z M520.4,64.2c0-8.8-5.8-15.8-14.5-15.8c-8.6,0-14.6,7-14.6,15.8S497.3,80,506,80   C514.7,80.1,520.4,73.2,520.4,64.2z"
        />
      </g>
      <linearGradient
        id="SVGID_8_"
        gradientUnits="userSpaceOnUse"
        x1="46.771"
        y1="-3695.5127"
        x2="113.171"
        y2="-3629.1128"
        gradientTransform="matrix(1 0 0 -1 0 -3624.6838)"
      >
        <stop offset="0" style={{ stopColor: "#FF2F15", stopOpacity: "0.5" }} />
        <stop offset="1" style={{ stopColor: "#FEFF2A", stopOpacity: "0.5" }} />
      </linearGradient>
      <polygon className={classes.st9} points="108.8,7 45.9,7 45.9,19.8 88.9,19.8 95.9,19.8 95.9,70 112.3,70 112.3,69.9 108.8,69.9 " />
      <linearGradient
        id="SVGID_9_"
        gradientUnits="userSpaceOnUse"
        x1="49.2709"
        y1="-3737.7129"
        x2="115.671"
        y2="-3671.3127"
        gradientTransform="matrix(1 0 0 -1 0 -3624.6838)"
      >
        <stop offset="0" style={{ stopColor: "#FF2F15", stopOpacity: "0.5" }} />
        <stop offset="1" style={{ stopColor: "#FEFF2A", stopOpacity: "0.5" }} />
      </linearGradient>
      <rect x="75.9" y="70" className={classes.st10} width="13" height="19.8" />
      <linearGradient
        id="SVGID_10_"
        gradientUnits="userSpaceOnUse"
        x1="2.696"
        y1="-3691.1379"
        x2="69.096"
        y2="-3624.7378"
        gradientTransform="matrix(1 0 0 -1 0 -3624.6838)"
      >
        <stop offset="0" style={{ stopColor: "#FF2F15", stopOpacity: "0.5" }} />
        <stop offset="1" style={{ stopColor: "#FEFF2A", stopOpacity: "0.5" }} />
      </linearGradient>
      <rect x="26" y="26.8" className={classes.st11} width="19.9" height="12.8" />
      <linearGradient
        id="SVGID_11_"
        gradientUnits="userSpaceOnUse"
        x1="6"
        y1="-3734.2839"
        x2="72.4001"
        y2="-3667.8838"
        gradientTransform="matrix(1 0 0 -1 0 -3624.6838)"
      >
        <stop offset="1.249207e-03" style={{ stopColor: "#FF2F15", stopOpacity: "0.5" }} />
        <stop offset="1" style={{ stopColor: "#FEFF2A", stopOpacity: "0.5" }} />
      </linearGradient>
      <polygon className={classes.st12} points="26,89.8 26,46.7 6,46.7 6,109.6 68.9,109.6 68.9,89.8 " />
      <polygon
        className={classes.st13}
        points="75.9,39.7 68.9,39.7 68.9,46.7 68.9,70 68.9,89.8 68.9,109.6 72.4,109.6 75.9,109.6 75.9,89.8   75.9,70 "
      />
      <polygon className={classes.st13} points="45.9,46.7 68.9,46.7 68.9,39.7 45.9,39.7 26,39.7 6,39.7 6,43.2 6,46.7 26,46.7 " />
      <polygon className={classes.st13} points="88.9,19.8 88.9,26.8 88.9,70 88.9,89.8 92.4,89.8 95.9,89.8 95.9,70 95.9,19.8 " />
      <polygon className={classes.st13} points="88.9,26.8 88.9,19.8 45.9,19.8 26,19.8 26,23.4 26,26.8 45.9,26.8 " />
      <polygon className={classes.st13} points="115.8,0 108.8,0 108.8,7 108.8,69.9 112.3,69.9 115.8,69.9 " />
      <polygon className={classes.st13} points="108.8,0 45.9,0 45.9,3.6 45.9,7 108.8,7 " />
    </svg>
  )
}

export default Logotype
