import { IContactDtoV2, IFolderDto } from "api/dto"
import { IContactJobDto } from "api/dto/IContactJobDto"
import axios, { AxiosResponse } from "axios"
import { useTranslation } from "react-i18next"
import { useMutation, useQueryClient } from "react-query"
import { useDispatch } from "react-redux"
import { updateContact } from "redux/contact/actions"
import { showSuccessToast } from "utils/toast"

export function useMutateUpdateFolder(contactId: string, jobTagId: string, folderId?: string) {
  const queryClient = useQueryClient()
  const { t: translate } = useTranslation()

  return useMutation<AxiosResponse<IFolderDto>, any, IFolderDto, any>(
    (data) => axios.post(`/contacts/${contactId}/jobs/${jobTagId}/folders/${folderId}`, data),
    {
      onMutate: async (variables) => {
        // Cancel current queries for the contactJobs list
        await queryClient.cancelQueries(`${contactId}-${jobTagId}-folders`)

        const optimisticFolder: IFolderDto = { ...variables }

        queryClient.setQueryData<IFolderDto[]>(`${contactId}-${jobTagId}-folders`, (old) => {
          if (!old) {
            return []
          }
          const folders = [...old]

          const folderIndex = folders.findIndex((c) => c.folderId === folderId)

          if (folderIndex === -1) {
            return folders
          }

          folders[folderIndex] = { ...folders[folderIndex], ...optimisticFolder }
          return folders
        })

        return { optimisticFolder }
      },
      onSuccess: () => {
        showSuccessToast(translate("Folder.UpdatedFolderSuccess"))
      }
    }
  )
}

export function useMutateUpdateJob(contactId: string, jobId: string) {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()
  const { t: translate } = useTranslation()
  // we update job with the currentJob and pass in our onMutate the whole contact to be able to pass our table
  // row data (detailPanelfn etc..)
  return useMutation<AxiosResponse<IContactJobDto>, any, any, any>((data) => axios.post(`/contacts/${contactId}/jobs/${jobId}`, data.currentJob), {
    onMutate: async (variables) => {
      // Cancel current queries for the contactJobs list
      await queryClient.cancelQueries(`contacts-job-${jobId}`)
      // dispatch optimistics to redux so we can invalidate only from cache
      dispatch(updateContact(variables))
    },
    onSuccess: () => {
      showSuccessToast(translate("Job.UpdatedJobSuccess"))
    }
  })
}

export function useMutationUpdateContact(jobId?: string) {
  const { t: translate } = useTranslation()
  const queryClient = useQueryClient()

  return useMutation(({ contactId, ...data }: { contactId: string; tagsToRemove: string[] }) => axios.post(`/contacts/${contactId}`, data), {
    onSuccess: () => {
      queryClient.invalidateQueries(jobId ? `contacts-job-${jobId}` : "contacts")
      showSuccessToast(translate("Contact.DeleteCandidacySuccess"))
    }
  })
}

export function useMutationEditContact(id: string) {
  const { t: translate } = useTranslation()
  const queryClient = useQueryClient()

  return useMutation(({ ...data }: Partial<IContactDtoV2>) => axios.post(`/contacts/${id}`, data), {
    onSuccess: () => {
      queryClient.invalidateQueries(`contacts`)
      showSuccessToast(translate("Contact.EditContactSuccess"))
    }
  })
}

export function useMutationUpdateContactAddJobs(contactId: string, jobIds?: string[]) {
  const { t: translate } = useTranslation()

  return useMutation((tagsToAdd: string[]) => axios.post(`/contacts/${contactId}`, { tagsToAdd }), {
    onSuccess: () => {
      showSuccessToast(translate("Job.AddTagSuccess"))
    }
  })
}

export function useMutationUpdateContactAddTags(contactId: string, jobIds?: string[]) {
  const { t: translate } = useTranslation()

  return useMutation((tagsToAdd: string[]) => axios.post(`/contacts/${contactId}`, { tagsToAdd }), {
    onSuccess: () => {
      showSuccessToast(translate("Job.SendSurvey"))
    }
  })
}
