import React, { useState } from "react"
import { TextField } from "@material-ui/core"

const normalizeInput = (value, previousValue) => {
  if (!value) return value
  const currentValue = value.replace(/[^\d]/g, "")
  const cvLength = currentValue.length

  if (!previousValue || value.length > previousValue.length) {
    if (cvLength < 4) return currentValue
    if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`
  }
}

export const PhoneNumber = ({ applicationData, setApplicationData, applicationElements, labelName }) => {
  const [phoneState, setPhoneState] = useState(applicationData[applicationElements])

  const handleChange = ({ target: { value } }) => {
    const ValuePhone = normalizeInput(value)
    setPhoneState(ValuePhone)
    applicationData[applicationElements] = ValuePhone
    setApplicationData(applicationData)
  }

  return (
    <div className="input-container">
      <TextField
        id="outlined-required"
        label={`${labelName}`}
        variant="outlined"
        fullWidth
        defaultValue={phoneState}
        value={phoneState}
        onChange={handleChange}
      />
    </div>
  )
}
