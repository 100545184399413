import { TextField } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import { useQueryJobs } from "api/hooks"
import { useMutateTransferContact } from "api/hooks/add"
import { ModalWrapper } from "components/modal/modalWrapper"
import React, { useMemo } from "react"
import { Controller } from "react-hook-form"
import { useTranslation } from "react-i18next"

interface ITransferModalProps {
  row: any
  opened: boolean
  toggle: () => void
}

export const TransferModal: React.FunctionComponent<ITransferModalProps> = (props) => {
  const { row, opened, toggle } = props
  const { t: translate } = useTranslation()
  const { data: jobs, isLoading: isLoadingJobs } = useQueryJobs()
  const { mutateAsync: transfer } = useMutateTransferContact(row.Id, row.currentJob.jobTagId)
  const currentJobs = useMemo(() => (jobs as { jobTitle: string; jobTagId: string }[]).filter((j) => !row.Tags.includes(j.jobTagId)), [jobs, row])

  const onSubmit = async (data: any) => {
    await transfer({ transferTo: data.job.jobTagId, jobName: data.job.jobTitle })
    toggle()
  }

  return (
    <ModalWrapper onSubmit={onSubmit} title={translate("Job.TransferContact")} saveText={translate("Job.Transfer")} opened={opened} toggle={toggle}>
      {({ control }) => (
        <Controller
          name="job"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Autocomplete
              disabled={isLoadingJobs}
              options={currentJobs || []}
              style={{ marginTop: "10px" }}
              getOptionLabel={(option) => option.jobTitle}
              getOptionSelected={(option, v) => option.jobTitle === v.jobTitle}
              renderInput={(params) => <TextField {...params} label={translate("Job.SelectJobToTransfer")} variant="outlined" />}
              {...field}
              onChange={(_, data) => field.onChange(data)}
            />
          )}
        />
      )}
    </ModalWrapper>
  )
}
