import { IContactDto, IContactDtoV2 } from "api/dto/IContactDto"
// import environment from "config/env"

interface IContactFields {
  id: string
  contactName: string
  postalCode: string
  phoneNumber: string
  email: string
  city: string
  phoneNumber2: string
  customRecruto: string
  customAncia: string
  customClient: string
}

export function mapV2Contact(contact: IContactDtoV2, jobId?: string): IContactDto {
  return {
    Id: contact.id || contact.contactJob?.split(":")[1] || "",
    ContactName: [contact.firstName, contact.middleName, contact.lastName].filter((c) => c != null).join(" "),
    BirthDay: contact.birthday,
    Website: contact.website,
    City: contact.addresses ? (contact.addresses[0] ? contact.addresses[0].locality || "" : "") : "",
    Email: contact.emails ? contact.emails[0] : "",
    Emails: contact.emails ?? [],
    LastUpdated: contact.modifiedAt,
    PhoneWithExtension1: contact.phoneNumbers && contact.phoneNumbers.length > 0 ? contact.phoneNumbers[0] : "",
    PhoneWithExtension2: contact.phoneNumbers && contact.phoneNumbers.length > 1 ? contact.phoneNumbers[1] : "",
    PostalCodePlusZipFour1: contact.addresses
      ? contact.addresses[0]
        ? contact.addresses[0].postal_code || contact.addresses[0].zip_code || contact.addresses[0].zip_four || ""
        : ""
      : "",
    Tags: contact.tags || [],
    jobs: contact.jobs || [],
    notesList: contact.notesList || [],
    addresses: contact.addresses || [],
    phoneNumbers: contact.phoneNumbers || [],
    socialAccounts: contact.socialAccounts || [],
    currentJob: contact.jobs?.find((j: any) => j.jobTagId === jobId)
  }
}

export function generateContactFields(): IContactFields {
  // const { version } = environment.api
  // if (version === "v1") {
  return {
    id: "Id",
    contactName: "ContactName",
    postalCode: "PostalCodePlusZipFour1",
    phoneNumber: "PhoneWithExtension1",
    email: "Email",
    city: "City",
    phoneNumber2: "PhoneWithExtension2",
    customRecruto: "Custom_DateentrevueRECRUTO",
    customAncia: "Custom_DateentrevueANCIA",
    customClient: "Custom_Dateentrevueclient"
  }
  // }

  // return {
  //   id: "id",
  //   contactName: "contactName", //
  //   postalCode: "addresses[0].postal_code",
  //   phoneNumber: "phoneNumbers[0]",
  //   email: "emails[0]",
  //   city: "addresses[0].locality",
  //   phoneNumber2: "phoneNumbers[1]",
  //   customRecruto: "customRecruto",
  //   customAncia: "customAncia",
  //   customClient: "customClient"
  // }
}
