import React, { useEffect, useMemo, useState } from "react"
import countBy from "lodash/countBy"
import { Box, Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core"
import { useQueryStats } from "api/hooks"
import { useTranslation } from "react-i18next"
import { capitalize } from "utils/string"
import { StatsTable } from "components/stats/statsTable"
import moment from "moment"
import { Modal } from "components/modal/modal"
import TableContainer from "@material-ui/core/TableContainer"
import InitialLoading from "../../../components/loading"
import { useContactStatStyles } from "./styles"
import { defaultTemplates } from "./stats"

interface IContactStatsProps {
  isOpen: boolean
  setOpen: any
  currentJobId: string
}

export const StatsPerJobsModal: React.FC<IContactStatsProps> = (props) => {
  const { setOpen, currentJobId, isOpen } = props
  const { t: translate } = useTranslation()

  // todo hummm ??
  const params = useMemo(
    () => ({
      start: moment().subtract(30, "days").toISOString(),
      jobId: currentJobId
    }),
    [currentJobId]
  )

  const { data: stats, isLoading: isLoadingStats, isFetching, refetch } = useQueryStats(params)

  const classes = useContactStatStyles()
  const countStats = useMemo(() => countBy(stats, (s) => s.type || []), [stats])
  const groupByStats = useMemo(() => Object.entries(countStats), [countStats])

  useEffect(() => {
    refetch()
  }, [])

  return (
    <Modal title={translate(`Statistics.StatsPerJobTitle`)} open={isOpen} fullWidth maxWidth="lg" close={() => setOpen(false)}>
      {isLoadingStats ? (
        <div>
          <InitialLoading fullScreen={false} />
        </div>
      ) : !stats || stats.length <= 0 ? (
        <div>
          <span>{translate(`Statistics.NoStats`)}</span>
        </div>
      ) : (
        <>
          <div className={classes.root}>
            <Grid container alignItems="flex-start">
              <Grid item xs={6} sm={12}>
                <Box margin={1}>
                  <TableContainer style={{ maxHeight: 440 }}>
                    <Table stickyHeader aria-label="sticky table" size="small">
                      <TableHead>
                        <TableRow className={classes.heading}>
                          {defaultTemplates.map((status) => (
                            <TableCell key={status} className={classes.centerHead}>
                              {translate(`Statistics.${capitalize(status)}.Label`)}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody className={classes.body}>
                        <TableRow>
                          {defaultTemplates.map((status) => {
                            const currentStatus = groupByStats.find(([type]) => type === status)
                            return (
                              <TableCell key={`${status}count`} className={classes.center}>
                                {currentStatus ? currentStatus[1] : 0}
                              </TableCell>
                            )
                          })}
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Grid>
            </Grid>
          </div>
          <StatsTable stats={stats} isLoadingStats={isLoadingStats || isFetching} />
        </>
      )}
    </Modal>
  )
}
