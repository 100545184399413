import { AppBar, Tabs, Tab } from "@material-ui/core"
import { TabPanel } from "components/tabs/tabPanel"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { JobsTab } from "./tabs/jobsTab"
import { ResumeTab } from "./tabs/resumeTab"

export const ContactTabs: React.FunctionComponent<{ data?: any }> = (props) => {
  const { data } = props
  const [tabIndex, setTabIndex] = useState(0)
  const { t: translate } = useTranslation()
  return (
    <>
      <AppBar position="static" color="default">
        <Tabs
          value={tabIndex}
          onChange={(e, newValue) => setTabIndex(newValue)}
          scrollButtons="on"
          indicatorColor="primary"
          textColor="primary"
          aria-label="scrollable force tabs example"
          centered
          variant="fullWidth"
        >
          <Tab label={translate("Contact.Tabs.Resume")} />
          <Tab label={translate("Contact.Tabs.Jobs")} />
        </Tabs>
      </AppBar>
      <TabPanel value={tabIndex} index={0}>
        <ResumeTab data={data} />
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <JobsTab data={data} />
      </TabPanel>
    </>
  )
}
