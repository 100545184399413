import React from "react"
import { useTranslation } from "react-i18next"
import { Modal } from "../../../../components/modal/modal"
import { useMutationDeleteVault } from "../../../../api/hooks/delete"

interface IDeleteProps {
  tokenId: string
  open: boolean
  onClose: () => void
}

const DeleteVaultConfirmation: React.FC<IDeleteProps> = (props) => {
  const { tokenId, open, onClose } = props
  const { t: translate } = useTranslation()

  const { mutateAsync: deleteToken } = useMutationDeleteVault(tokenId)

  const submit = async () => {
    onClose()
    await deleteToken()
  }

  return (
    <Modal
      title={translate(`Document.Vault.DeletedTokenRequestTitle`)}
      open={open}
      fullWidth
      maxWidth="md"
      close={onClose}
      action={submit}
      saveText={translate("Common.Delete")}
    >
      <p>{translate(`Document.Vault.DeletedTokenRequest`)}</p>
    </Modal>
  )
}

export default DeleteVaultConfirmation
