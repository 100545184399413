/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from "react"
import CssBaseline from "@material-ui/core/CssBaseline"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormControl from "@material-ui/core/FormControl"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { Button, Checkbox } from "@material-ui/core"
import { useQueryTags } from "api/hooks"
import { TagCategory } from "api/dto/ITagDto"
import ValidateField from "./ValidateField"
import { PhoneNumber } from "./PhoneNumber"

const useStyles = makeStyles((theme) => ({
  main: {
    backgroundColor: "#eee",
    padding: 5,
    borderRadius: "2em"
  },
  textfield: {
    width: "80%"
  },
  enRanking: {
    marginTop: 50,
    marginBottom: 50,
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },

  root: {
    flexGrow: 1,
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2)
    }
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary
  }
}))

const ConfirmationStep = ({ normalizeApplicationData, setOpen, applicationData, setApplicationData }) => {
  const classes = useStyles()
  const [experienceTags] = useState(applicationData.experienceTags ? applicationData.experienceTags : [])
  const [formationTags] = useState(applicationData.formationTags ? applicationData.formationTags : [])
  const { data: tags } = useQueryTags()
  const [disabledButton, setDisabledButton] = React.useState(true)

  const changeStateButton = () => {
    if (applicationData.email !== "" && applicationData.givenName !== "" && applicationData.familyName !== "") {
      setDisabledButton(false)
    } else {
      setDisabledButton(true)
    }
  }

  const handleClickAlert = () => {
    normalizeApplicationData()
    setOpen(false)
  }

  function getNormalizedTagList(category, tagList) {
    return (tagList || tags || []).filter((t) => t.category === category).map((t) => ({ tag: t.id, label: t.name }))
  }

  const [englishLevel, setEnglishLevel] = useState(
    applicationData.languageTags.length === 0 ? "" : applicationData.languageTags[0].tag ? applicationData.languageTags[0].tag : "1631"
  )

  const allFormationTags = getNormalizedTagList(TagCategory.formation)
  const allExperienceTags = getNormalizedTagList(TagCategory.experience)

  return (
    <>
      <CssBaseline />
      <Container maxWidth="lg" style={{ marginTop: "15px" }}>
        <div className={classes.main}>
          <Grid container spacing={1}>
            <Grid container item xs={12}>
              <Grid item xs={12}>
                <h2
                  style={{
                    fontSize: 24,
                    margin: "15px 10%",
                    lineHeight: "1.2"
                  }}
                >
                  Veuillez valider et compléter les informations que nous avons pu trouver à partir de votre CV.
                </h2>
              </Grid>
            </Grid>
            <div className={classes.root}>
              <Grid container spacing={3} alignItems="center" justifyContent="center" style={{ padding: "1em 2em" }}>
                <Grid item xs={12} md={4} style={{ margin: "1em" }}>
                  <ValidateField
                    applicationData={applicationData}
                    setApplicationData={setApplicationData}
                    applicationElements="givenName"
                    labelName="Prénom"
                    requireField
                  />
                </Grid>
                <Grid item xs={12} md={4} style={{ margin: "1em" }}>
                  <ValidateField
                    applicationData={applicationData}
                    setApplicationData={setApplicationData}
                    applicationElements="familyName"
                    labelName="Nom"
                    requireField
                  />
                </Grid>
                <Grid item xs={12} md={4} style={{ margin: "1em" }}>
                  <ValidateField
                    applicationData={applicationData}
                    setApplicationData={setApplicationData}
                    applicationElements="email"
                    labelName="Email"
                    requireField
                  />
                </Grid>

                <Grid item xs={12} md={4} style={{ margin: "1em" }}>
                  <PhoneNumber
                    applicationData={applicationData}
                    setApplicationData={setApplicationData}
                    applicationElements="phone"
                    labelName="Numéro de téléphone"
                  />
                </Grid>
                <Grid item xs={12} md={12} style={{ margin: "1em" }}>
                  <span style={{ fontSize: "24px", fontWeight: 600 }}>Adresse</span>
                </Grid>
                <Grid item xs={12} md={4} style={{ margin: "1em" }}>
                  <ValidateField
                    applicationData={applicationData}
                    applicationDataAddress={applicationData.address}
                    setApplicationData={setApplicationData}
                    applicationElements="address"
                    addressSuite="postalCode"
                    labelName="Code Postal"
                    requireField={false}
                  />
                </Grid>
                <Grid item xs={12} md={4} style={{ margin: "1em" }}>
                  <ValidateField
                    applicationData={applicationData}
                    applicationDataAddress={applicationData.address}
                    setApplicationData={setApplicationData}
                    applicationElements="address"
                    addressSuite="city"
                    labelName="Ville"
                    requireField={false}
                  />
                </Grid>
                <Grid item xs={12} md={4} style={{ margin: "1em" }}>
                  <ValidateField
                    applicationData={applicationData}
                    applicationDataAddress={applicationData.address}
                    setApplicationData={setApplicationData}
                    applicationElements="address"
                    addressSuite="line1"
                    labelName="Adresse Civique"
                    requireField={false}
                  />
                </Grid>
              </Grid>

              <Grid container item xs={12} className={classes.enRanking}>
                <label
                  style={{
                    fontSize: 20,
                    fontWeight: "bold"
                  }}
                  htmlFor="basic-url"
                >
                  Niveau d'anglais
                </label>
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    defaultValue={englishLevel}
                    aria-label="position"
                    name="position"
                    onChange={(e) => {
                      setEnglishLevel(e.target.value)
                      applicationData.languageTags = e.target.value
                      setApplicationData(applicationData)
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <span>Aucun</span>
                      <FormControlLabel value="1631" control={<Radio color="primary" />} label="1" labelPlacement="bottom" />
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div style={{ height: 18 }}> </div>
                      <FormControlLabel value="1623" control={<Radio color="primary" />} label="2" labelPlacement="bottom" />
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <span>Intermédiaire</span>
                      <FormControlLabel value="1625" control={<Radio color="primary" />} label="3" labelPlacement="bottom" />
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div style={{ height: 18 }}> </div>
                      <FormControlLabel value="1627" control={<Radio color="primary" />} label="4" labelPlacement="bottom" />
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <span>Bilingue</span>
                      <FormControlLabel value="1629" control={<Radio color="primary" />} label="5" labelPlacement="bottom" />
                    </div>
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid style={{ justifyContent: "space-around" }} container item spacing={3} xs={12}>
                <Grid item xs={10}>
                  <Autocomplete
                    multiple
                    id="tags-outlined"
                    options={allExperienceTags}
                    getOptionLabel={(option) => option.label}
                    filterSelectedOptions
                    defaultValue={experienceTags}
                    onChange={(event, values) => {
                      const contactExperienceTags = []
                      for (const i in values) {
                        contactExperienceTags[i] = values[i].tag
                      }
                      setApplicationData((prevState) => {
                        return {
                          ...prevState,
                          experienceTags: contactExperienceTags
                        }
                      })
                    }}
                    renderInput={(params) => <TextField {...params} variant="outlined" label="Experiences" />}
                  />
                </Grid>
                <Grid item xs={10}>
                  <Autocomplete
                    multiple
                    id="tags-outlined"
                    options={allFormationTags}
                    getOptionLabel={(option) => option.label}
                    filterSelectedOptions
                    defaultValue={formationTags}
                    onChange={(event, values) => {
                      const contactFormationTags = []
                      for (const i in values) {
                        contactFormationTags[i] = values[i].tag
                      }
                      setApplicationData((prevState) => {
                        return {
                          ...prevState,
                          formationTags: contactFormationTags
                        }
                      })
                    }}
                    renderInput={(params) => <TextField {...params} variant="outlined" label="Formations" />}
                  />
                </Grid>
              </Grid>
              <div
                style={{
                  width: "100%",
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column"
                }}
              >
                <FormControlLabel
                  control={<Checkbox checked={!disabledButton} onChange={changeStateButton} name="check" color="primary" />}
                  label="J'ai validé et remplis les informations obligatoires ci-dessus"
                />
                <Button
                  disabled={disabledButton}
                  variant="contained"
                  onClick={() => handleClickAlert()}
                  style={{ marginTop: "1.5em", marginBottom: "1.5em" }}
                  color="secondary"
                >
                  Envoyer La Candidature
                </Button>
              </div>
            </div>
          </Grid>
        </div>
      </Container>
    </>
  )
}

export default ConfirmationStep
