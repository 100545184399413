import { ITagDto } from "api/dto"
import React from "react"
import { nanoid } from "nanoid"
import { ISearch } from "./ISearch"

interface ISearchPreviewProps {
  search: ISearch
}

function isTag(o: any): o is ITagDto {
  return "category" in o
}

const SearchPreviewFn: React.ForwardRefRenderFunction<any, ISearchPreviewProps> = (props, fowardedRef) => {
  const { search } = props
  return (
    <div ref={fowardedRef} style={{ display: "none" }}>
      {search.map((s) => (
        <>
          <div key={nanoid()} style={{ display: "flex" }}>
            <div style={{ marginRight: "10px" }}>(</div>
            {s.parameters.map((p) => {
              return (
                <div key={nanoid()} style={{ display: "flex" }}>
                  {`${p.field}: ${
                    Array.isArray(p.value)
                      ? p.value[0] != null && isTag(p.value[0])
                        ? `\\[${p.value.map((t) => t.id).join(", ")}\\]`
                        : `(${p.value})`
                      : `(${p.value})`
                  }`}
                  {p.operator ? <div style={{ margin: "0 10px" }}>{` ${p.operator} `}</div> : ""}
                </div>
              )
            })}
            <div style={{ marginLeft: "10px" }}>)</div>
          </div>
          {s.operator && (
            <div key={nanoid()} style={{ padding: "0 10px" }}>
              {` ${s.operator} `}
            </div>
          )}
        </>
      ))}
    </div>
  )
}

export const SearchPreview = React.forwardRef(SearchPreviewFn)
