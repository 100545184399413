// eslint-disable-next-line import/no-extraneous-dependencies
import { makeStyles } from "@material-ui/styles"

export const useStyles = makeStyles(() => ({
  root: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    background: "rgba(255, 255, 255, 0.5)",
    zIndex: 99999,
    height: "100%"
  },
  content: {
    height: "calc(100% - 54px)"
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%"
  },
  loadingBar: {
    color: "red"
  }
}))

export default { useStyles }
