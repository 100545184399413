import { makeStyles } from "@material-ui/core/styles"

export const useStylesModal = makeStyles((theme) => ({
  title: {
    borderBottom: "1px solid #5f5f5f59"
  },
  button: {
    borderTop: "1px solid #5f5f5f59"
  },
  backdrop: {
    color: "#fff"
  }
}))
