export const REQUEST_JOBS_DATAS = "REQUEST_JOBS_DATAS"
export const RECEIVE_JOBS = "RECEIVE_JOBS"
export const RECEIVE_ERROR = "RECEIVE_ERROR"

export const requestJobsDatas = (action) => ({
  type: REQUEST_JOBS_DATAS,
  json: action
})

export const receiveJobs = (action) => ({
  type: RECEIVE_JOBS,
  json: action
})

export const receiveError = (err) => ({
  type: RECEIVE_ERROR,
  error: err.data
})
