import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useContactJobs } from "utils/jobs"

export const withHooksHOC = (Component: any) => {
  return (props: any) => {
    const { currentJobId } = useContactJobs()
    const { t: translate } = useTranslation()

    return <Component currentJobId={currentJobId} translate={translate} {...props} />
  }
}
