/* eslint-disable no-shadow */
export enum PrequalifTabs {
  notes = 0,
  stats = 1,
  history = 2,
  prequalif = 3,
  documents = 4
}

export class TabsHelper {
  static selectedTab: any = {}

  static setTab(contactId: string, tabId: any) {
    this.selectedTab[contactId] = tabId
  }

  static getTab(contactId: string) {
    return this.selectedTab[contactId] || 0
  }
}
