import initialState from "./intial"

import { SET_MUI_GROUPING_STATUS } from "../actions/grouping"

import {
  SET_MUI_OPTIONS_PAGESIZE,
  SET_MUI_OPTIONS_SORTING,
  SET_MUI_OPTIONS_SELECTION,
  SET_MUI_OPTIONS_GROUPING,
  SET_MUI_OPTIONS_FILTERING,
  SET_MUI_OPTIONS_HIDDEN,
  SET_MUI_OPTIONS_PADDING,
  SET_MUI_OPTIONS_DRAGGABLE
} from "../actions/options"

function muiReducer(state = initialState, action) {
  switch (action.type) {
    // GROUPING REDUCER
    case SET_MUI_GROUPING_STATUS:
      return {
        ...state,
        grouping: {
          ...state.grouping,
          status: action.json
        }
      }

    // OPTIONS REDUCER
    case SET_MUI_OPTIONS_PAGESIZE:
      return {
        ...state,
        options: {
          ...state.options,
          pageSize: action.json
        }
      }
    case SET_MUI_OPTIONS_SORTING:
      return {
        ...state,
        options: {
          ...state.options,
          sorting: action.json
        }
      }
    case SET_MUI_OPTIONS_SELECTION:
      return {
        ...state,
        options: {
          ...state.options,
          selection: action.json
        }
      }
    case SET_MUI_OPTIONS_GROUPING:
      return {
        ...state,
        options: {
          ...state.options,
          grouping: action.json
        }
      }
    case SET_MUI_OPTIONS_FILTERING:
      return {
        ...state,
        options: {
          ...state.options,
          filtering: action.json
        }
      }
    case SET_MUI_OPTIONS_HIDDEN:
      return {
        ...state,
        options: {
          ...state.options,
          hidden: action.json
        }
      }
    case SET_MUI_OPTIONS_PADDING:
      return {
        ...state,
        options: {
          ...state.options,
          padding: action.json
        }
      }
    case SET_MUI_OPTIONS_DRAGGABLE:
      return {
        ...state,
        options: {
          ...state.options,
          draggable: action.json
        }
      }
    default:
      return state
  }
}

export default muiReducer
