import { makeStyles } from "@material-ui/styles"

const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    minHeight: "100vh",
    height: "100%"
  },
  button: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  shiftContent: {
    paddingLeft: drawerWidth
  },
  content: {
    borderRadius: "5px 0 0",
    flexGrow: 1,
    backgroundColor: "#f4f5f9",
    padding: theme.spacing(3)
  },
  buttonContainer: {
    display: "flex",
    position: "absolute",
    right: 0,
    bottom: 0
  },
  addCv: {
    display: "flex",
    marginRight: "30px",
    borderWidth: "2px",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
    textTransform: "none",
    right: 0,
    bottom: 0,
    borderColor: theme.palette.text.secondary,
    transition: "0.3s",
    padding: "4px 8px",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#eeeeee"
    }
  },
  headerContact: {
    display: "flex"
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
  }
}))

export default useStyles
