import React from "react"
import { makeStyles } from "@material-ui/styles"
import Dispatcher from "./Dispatcher"

const Settings = () => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <h1>Paramètres REDUX</h1>
      <Dispatcher />
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3)
  }
}))

export default Settings
