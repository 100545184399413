import React from "react"
import { Link as RouterLink } from "react-router-dom"
import clsx from "clsx"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { AppBar, Toolbar, Hidden, IconButton } from "@material-ui/core"
import MenuIcon from "@material-ui/icons/Menu"
import InputIcon from "@material-ui/icons/Input"
import Logotype from "../../../../components/logo/Logotype"
import { clickLogout } from "../../../../redux/auth/actions/auth"
import { useStyles } from "./styles"
import { SearchBox } from "./SearchBox"

const Topbar = (props) => {
  const { dispatch, auth, className, onSidebarOpen } = props
  const classes = useStyles()

  const handleSignOut = (e) => {
    e.preventDefault()
    dispatch(clickLogout())
    localStorage.clear()
    sessionStorage.clear()
    window.location.href = "/"
  }

  return (
    <AppBar className={clsx(classes.root, className)}>
      <Toolbar>
        <RouterLink to="/">
          <Logotype />
        </RouterLink>

        <div className={classes.searchBox}>
          <SearchBox />
        </div>

        <div className={classes.flexGrow} />
        <div>{auth.user.ContactName}</div>

        <RouterLink to="/login" onClick={handleSignOut} className={classes.link}>
          <IconButton className={classes.icon} title="Déconnexion">
            <InputIcon />
            <Hidden mdDown>
              <span className={classes.label}>Déconnexion</span>
            </Hidden>
          </IconButton>
        </RouterLink>
        <Hidden lgUp>
          <IconButton onClick={onSidebarOpen} className={classes.icon}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  )
}

Topbar.propTypes = {
  auth: PropTypes.object.isRequired,
  className: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  onSidebarOpen: PropTypes.func
}

const mapStateToProps = (state) => {
  return state
}
export default connect(mapStateToProps)(Topbar)
