import MuiButton from "./MuiButton"
import MuiIconButton from "./MuiIconButton"
import MuiPaper from "./MuiPaper"
import MuiTableCell from "./MuiTableCell"
import MuiTableHead from "./MuiTableHead"
import MuiTypography from "./MuiTypography"
import MuiFormGroup from "./MuiFormGroup"
import MuiTableBody from "./MuiTableBody"
import MuiToolbar from "./MuiToolbar"
import MuiTableSortLabel from "./MuiTableSortLabel"
import MuiTableFooter from "./MuiTableFooter"

export default {
  MuiTableFooter,
  MuiButton,
  MuiIconButton,
  MuiPaper,
  MuiTableCell,
  MuiTableHead,
  MuiTypography,
  MuiFormGroup,
  MuiTableBody,
  MuiToolbar,
  MuiTableSortLabel
}
