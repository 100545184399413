import React from "react"
import { Switch, Route } from "react-router-dom"
import { ContactSearchTable } from "components/contacts/contactSearchTable"

export const ContactsRouter: React.FC = () => {
  return (
    <Switch>
      <Route path="/contacts" component={ContactSearchTable} />
    </Switch>
  )
}
