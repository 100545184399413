import React, { useState } from "react"
import axios from "axios"
import { IFolderDto } from "api/dto"
import { Button } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { ReactComponent as DocumentIcon } from "sass/images/document.svg"
import { ReactComponent as LinkIcon } from "sass/images/link.svg"
import { ReactComponent as AddDocumentIcon } from "sass/images/add-document.svg"
import { ReactComponent as AddLinkIcon } from "sass/images/add-link.svg"
import { formatDate } from "utils/date"
import { AddFiles } from "./addFiles"
import { NotesFolder } from "./notes"
import useStyles from "../styles"
import { DeleteDocument } from "../deleteDocument"
import { AddUrl } from "./addUrl"

interface IFolderProps {
  folder: IFolderDto
  contactId: string
  jobTagId: string
  isLoadingFolders: boolean
  contactName: string
}

export const Folder: React.FunctionComponent<IFolderProps> = (props) => {
  const { t: translate } = useTranslation()
  const { folder, contactId, jobTagId, isLoadingFolders, contactName } = props
  const [addFilesModalOpened, setAddFilesModalOpened] = useState(false)
  const [addUrlModalOpened, setAddUrlModalOpened] = useState(false)
  const classes = useStyles()

  const decodeEncoded = (file: any) => {
    let decodeFile = file

    try {
      decodeFile = decodeURIComponent(file).replace(/\+/g, " ")
    } catch (error) {
      decodeFile = file.replace(/\+/g, " ")
    }

    return decodeFile
  }

  // replace with reactQuery
  const fetchUrl = async (documentId: string) => {
    const { data } = await axios.get(`/contacts/${contactId}/documents/${documentId}`)
    window.open(data)
  }

  return (
    <>
      <li key={folder.name} className={classes.row}>
        <div className={`${classes.type} ${classes.displayFlexCenter}`}>
          <span>{folder.name}</span>
        </div>

        <div className={`${classes.notes} ${classes.displayFlexCenter}`}>
          <NotesFolder contactId={contactId} jobTagId={jobTagId} folder={folder} />
        </div>

        <div className={`${classes.displayFlexCenter} ${classes.flexColumn} ${classes.userNotes}`}>
          {folder.contactNotes?.length &&
            folder.contactNotes.map((item) => (
              <div key={item.id}>
                <ul className={`${classes.userNotesList}`}>
                  <li className={`${classes.userNotesDate}`}>{formatDate(item.createdAt)}</li>
                  <li className={`${classes.userNotesDescription}`}>{item.text}</li>
                </ul>
              </div>
            ))}
        </div>

        <div className={`${classes.files} ${classes.displayFlexCenterFiles}`}>
          {!isLoadingFolders && folder.documents
            ? folder.documents.map((file) => (
                <div key={file.documentId} className={classes.docOrLink}>
                  {file.url ? (
                    <span className={classes.nameSvgDocOrLink} onClick={() => window.open(file.url, "_blank")}>
                      <LinkIcon width={18} height={30} style={{ marginRight: "10px" }} />
                      <div className={classes.nameDocOrLink}>{decodeEncoded(file.name)}</div>
                    </span>
                  ) : (
                    <span className={classes.nameSvgDocOrLink} onClick={() => fetchUrl(file.documentId)}>
                      <DocumentIcon width={18} height={30} style={{ marginRight: "10px" }} />
                      <div className={classes.nameDocOrLink}>{decodeEncoded(file.name)}</div>
                    </span>
                  )}

                  <DeleteDocument contactId={contactId} jobId={jobTagId} files={file} contactName={contactName} />
                </div>
              ))
            : null}
        </div>
        <div className={`${classes.addFiles} ${classes.addLinkOrDoc}`}>
          <Button
            variant="contained"
            className={classes.addFilesButton}
            style={{ marginBottom: "5px", color: "#fff", backgroundColor: "#7D7D7D" }}
            onClick={() => setAddFilesModalOpened(true)}
          >
            <div>{translate("Document.AddDocument")}</div>
            <AddDocumentIcon height={18} width={18} opacity={0.6} style={{ overflow: "visible" }} />
          </Button>
          <Button
            variant="contained"
            className={classes.addFilesButton}
            style={{ color: "#fff", backgroundColor: "#7D7D7D" }}
            onClick={() => setAddUrlModalOpened(true)}
          >
            {translate("Document.AddUrl")}
            <AddLinkIcon height={18} width={18} />
          </Button>
        </div>
      </li>

      <AddFiles
        opened={addFilesModalOpened}
        toggle={() => setAddFilesModalOpened(false)}
        contactId={contactId}
        name={folder.name}
        folderId={folder.folderId}
        jobTagId={jobTagId}
      />

      <AddUrl
        opened={addUrlModalOpened}
        toggle={() => setAddUrlModalOpened(false)}
        contactId={contactId}
        folderName={folder.name}
        folderId={folder.folderId}
        jobTagId={jobTagId}
      />
    </>
  )
}
