import { useQuerySovren } from "api/hooks"
import React from "react"
import { Route, Switch } from "react-router-dom"
import { Sovren } from "./sovren"

export const SovrenSearchList: React.FC = () => {
  const { data: sovren, isFetching } = useQuerySovren()

  return (
    <Switch>
      <Route path="/search" render={() => <Sovren sovren={sovren} isLoading={isFetching} />} />
    </Switch>
  )
}
