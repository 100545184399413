import { Grid, InputLabel, TextField } from "@material-ui/core"
import React from "react"
import { useTranslation } from "react-i18next"
import { ModalWrapper } from "components/modal/modalWrapper"
import { Controller } from "react-hook-form"
import { Autocomplete } from "@material-ui/lab"
import { useMutationEditContact } from "../../api/hooks/update"

interface IUpdateContactModalProps {
  row: any
  opened: boolean
  toggle: () => void
}

export const UpdateContactModal: React.FunctionComponent<IUpdateContactModalProps> = (props) => {
  const { row, opened, toggle } = props
  const { t: translate } = useTranslation()
  const { mutateAsync: editContact } = useMutationEditContact(row.Id)
  const onSubmit = async (data: any) => {
    const {
      firstName,
      lastName,
      line1,
      line2,
      locality,
      postal_code: postalCode,
      zip_code: zipCode,
      region,
      // faceBookName,
      // twitterBookName,
      linkedInBookName,
      candidateEmail,
      supervisorEmail,
      homePhone,
      cellularPhone,
      birthday
    } = data

    const socialAccounts: { name?: string; type?: "Facebook" | "Twitter" | "LinkedIn" }[] = []
    // if (faceBookName) {
    //   socialAccounts.push({
    //     type: "Facebook",
    //     name: faceBookName
    //   })
    // }
    //
    // if (twitterBookName) {
    //   socialAccounts.push({
    //     type: "Twitter",
    //     name: twitterBookName
    //   })
    // }

    if (linkedInBookName) {
      socialAccounts.push({
        type: "LinkedIn",
        name: linkedInBookName
      })
    }

    await editContact({
      emails: [candidateEmail, "", supervisorEmail], // Requested by https://unicornpowered.atlassian.net/browse/REC-202
      firstName,
      lastName,
      phoneNumbers: [homePhone, cellularPhone],
      socialAccounts,
      birthday,
      addresses:
        // eslint-disable-next-line camelcase
        line1 || line2 || locality || region || postalCode || zipCode
          ? [
              {
                line1,
                line2,
                locality,
                region,
                postal_code: postalCode,
                zip_code: zipCode,
                field: "BILLING"
              }
            ]
          : []
    })
    toggle()
  }

  const getSocialAccountByType = (type: "Facebook" | "Twitter" | "LinkedIn") => {
    const accounts = row.socialAccounts || []
    return accounts.find((acc: { name: string; type: "Facebook" | "Twitter" | "LinkedIn" }) => acc.type === type)?.name
  }

  return (
    <ModalWrapper
      defaultValues={{
        emails: row.Email,
        candidateEmail: row.Emails[0],
        supervisorEmail: row.Emails[1],
        firstName: row.ContactName.split(" ")[0],
        lastName: row.ContactName.split(" ")?.slice(1)?.join(" "),
        line1: row.addresses[0]?.line1,
        line2: row.addresses[0]?.line2,
        locality: row.addresses[0]?.locality,
        postal_code: row.addresses[0]?.postal_code,
        region: row.addresses[0]?.region,
        homePhone: row.phoneNumbers[0],
        cellularPhone: row.phoneNumbers[1],
        birthday: row.BirthDay,
        // faceBookName: getSocialAccountByType("Facebook"),
        // twitterBookName: getSocialAccountByType("Twitter"),
        linkedInBookName: getSocialAccountByType("LinkedIn")
      }}
      onSubmit={onSubmit}
      title={translate("Contact.UpdateContact")}
      saveText={translate("Common.Save")}
      opened={opened}
      toggle={toggle}
    >
      {({ formState: { errors }, control }) => (
        <>
          <div className="content" style={{ paddingBottom: 0 }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <div className="form-input">
                  <InputLabel className="input-label required" htmlFor="firstName">
                    {translate("Contact.Fields.FirstName")}
                  </InputLabel>
                  <Controller
                    name="firstName"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <TextField className={errors?.firstName ? "is-invalid" : ""} {...field} fullWidth type="text" variant="outlined" />
                    )}
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="form-input">
                  <InputLabel className="input-label required" htmlFor="lastName">
                    {translate("Contact.Fields.LastName")}
                  </InputLabel>
                  <Controller
                    name="lastName"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <TextField className={errors?.lastName ? "is-invalid" : ""} {...field} fullWidth type="text" variant="outlined" />
                    )}
                  />
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <div className="form-input">
                  <InputLabel className="input-label" htmlFor="candidateEmail">
                    {translate("Contact.Fields.CandidateEmail")}
                  </InputLabel>
                  <Controller
                    name="candidateEmail"
                    control={control}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <TextField className={errors?.candidateEmail ? "is-invalid" : ""} {...field} fullWidth type="email" variant="outlined" />
                    )}
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="form-input">
                  <InputLabel className="input-label" htmlFor="supervisorEmail">
                    {translate("Contact.Fields.SupervisorEmail")}
                  </InputLabel>
                  <Controller
                    name="supervisorEmail"
                    control={control}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <TextField className={errors?.supervisorEmail ? "is-invalid" : ""} {...field} fullWidth type="email" variant="outlined" />
                    )}
                  />
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <div className="form-input">
                  <InputLabel className="input-label" htmlFor="homePhone">
                    {translate("Contact.Fields.HomePhone")}
                  </InputLabel>
                  <Controller
                    name="homePhone"
                    control={control}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <TextField className={errors?.homePhone ? "is-invalid" : ""} {...field} fullWidth type="tel" variant="outlined" />
                    )}
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="form-input">
                  <InputLabel className="input-label" htmlFor="cellularPhone">
                    {translate("Contact.Fields.CellularPhone")}
                  </InputLabel>
                  <Controller
                    name="cellularPhone"
                    control={control}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <TextField className={errors?.cellularPhone ? "is-invalid" : ""} {...field} fullWidth type="tel" variant="outlined" />
                    )}
                  />
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <div className="form-input">
                  <InputLabel className="input-label" htmlFor="line1">
                    {translate("Contact.Fields.Line1")}
                  </InputLabel>
                  <Controller
                    name="line1"
                    control={control}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <TextField className={errors?.line1 ? "is-invalid" : ""} {...field} fullWidth type="text" variant="outlined" />
                    )}
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="form-input">
                  <InputLabel className="input-label" htmlFor="line2">
                    {translate("Contact.Fields.Line2")}
                  </InputLabel>
                  <Controller
                    name="line2"
                    control={control}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <TextField className={errors?.line2 ? "is-invalid" : ""} {...field} fullWidth type="text" variant="outlined" />
                    )}
                  />
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <div className="form-input">
                  <InputLabel className="input-label" htmlFor="locality">
                    {translate("Contact.Fields.Locality")}
                  </InputLabel>
                  <Controller
                    name="locality"
                    control={control}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <TextField className={errors?.locality ? "is-invalid" : ""} {...field} fullWidth type="text" variant="outlined" />
                    )}
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="form-input">
                  <InputLabel className="input-label" htmlFor="postal_code">
                    {translate("Contact.Fields.PostalCode")}
                  </InputLabel>
                  <Controller
                    name="postal_code"
                    control={control}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <TextField className={errors?.postal_code ? "is-invalid" : ""} {...field} fullWidth type="text" variant="outlined" />
                    )}
                  />
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <div className="form-input">
                  <InputLabel className="input-label" htmlFor="region">
                    {translate("Contact.Fields.Province")}
                  </InputLabel>
                  <Controller
                    name="region"
                    control={control}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <TextField className={errors?.region ? "is-invalid" : ""} {...field} fullWidth type="text" variant="outlined" />
                    )}
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="form-input">
                  <InputLabel className="input-label" htmlFor="zip_code">
                    {translate("Contact.Fields.ZipCode")}
                  </InputLabel>
                  <Controller
                    name="zip_code"
                    control={control}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <TextField className={errors?.zip_code ? "is-invalid" : ""} {...field} fullWidth type="text" variant="outlined" />
                    )}
                  />
                </div>
              </Grid>
            </Grid>

            {/* <div className="form-input">
              <InputLabel className="input-label" htmlFor="faceBookName">
                {translate("Contact.Fields.SocialAccounts.Facebook")}
              </InputLabel>
              <Controller
                name="faceBookName"
                control={control}
                rules={{ required: false }}
                render={({ field }) => (
                  <TextField className={errors?.email ? "is-invalid" : ""} {...field} fullWidth type="text" variant="outlined" />
                )}
              />
            </div>

            <div className="form-input">
              <InputLabel className="input-label" htmlFor="twitterBookName">
                {translate("Contact.Fields.SocialAccounts.Twitter")}
              </InputLabel>
              <Controller
                name="twitterBookName"
                control={control}
                rules={{ required: false }}
                render={({ field }) => (
                  <TextField className={errors?.email ? "is-invalid" : ""} {...field} fullWidth type="text" variant="outlined" />
                )}
              />
            </div> */}

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <div className="form-input">
                  <InputLabel className="input-label" htmlFor="birthday">
                    {translate("Contact.Fields.BirthDate")}
                  </InputLabel>
                  <Controller
                    name="birthday"
                    control={control}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <TextField className={errors?.birthday ? "is-invalid" : ""} {...field} fullWidth type="date" variant="outlined" />
                    )}
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="form-input">
                  <InputLabel className="input-label" htmlFor="linkedInBookName">
                    {translate("Contact.Fields.SocialAccounts.LinkedIn")}
                  </InputLabel>
                  <Controller
                    name="linkedInBookName"
                    control={control}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <TextField className={errors?.email ? "is-invalid" : ""} {...field} fullWidth type="text" variant="outlined" />
                    )}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        </>
      )}
    </ModalWrapper>
  )
}
