import { makeStyles } from "@material-ui/styles"

const drawerWidth = 240

export const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap"
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1
    },
    visibility: "visible !important",
    transform: "none !important"
  },
  root: {
    backgroundColor: "#FFB50C",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: theme.spacing(2),
    borderRight: "0",
    boxShadow: "none",
    zIndex: 101,
    marginTop: 64
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  }
}))

export default useStyles
