import { colors } from "@material-ui/core"

const white = "#FFFFFF"

export default {
  white,
  primary: {
    contrastText: white,
    dark: colors.orange[900],
    main: colors.orange[600],
    light: colors.orange[400]
  },
  secondary: {
    contrastText: white,
    dark: colors.red.A400,
    main: colors.red[900],
    light: colors.red[400]
  },
  black: {
    contrastText: white,
    dark: colors.grey[900],
    charcoal: colors.grey[700],
    main: colors.grey[400],
    light: colors.grey[200]
  },
  button: {
    contrastText: white,
    dark: colors.grey[900],
    main: colors.grey[400],
    light: colors.grey[200]
  },
  success: {
    contrastText: white,
    dark: colors.green[900],
    main: colors.green[600],
    light: colors.green[400]
  },
  info: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue[600],
    light: colors.blue[400]
  },
  warning: {
    contrastText: white,
    dark: colors.yellow[900],
    main: colors.yellow[600],
    light: colors.yellow[400]
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400]
  },
  text: {
    primary: colors.blueGrey[900],
    secondary: colors.blueGrey[600],
    link: colors.blue[600]
  },
  background: {
    default: "#F4F6F8",
    paper: white
  },
  fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
  icon: {
    dark: colors.grey[900],
    main: colors.grey[600],
    light: colors.grey[300]
  },
  divider: colors.grey[200]
}
