import { TextField, Card, IconButton } from "@material-ui/core"
import { Close, Description } from "@material-ui/icons"
import { useQueryContacts } from "api/hooks"
import { SearchBox } from "components/search/searchBox"
import isEmpty from "lodash/isEmpty"
import React, { useMemo, useState } from "react"
import DataTable, { IDataTableColumn } from "react-data-table-component"
import { useTranslation } from "react-i18next"
import { ContactTabs } from "./contactTabs"

export const ContactSearchTable: React.FC = () => {
  const { t: translate } = useTranslation()
  const [contactQuery, setContactQuery] = useState<string | undefined>()
  const [jobQuery, setJobQuery] = useState<string | undefined>()
  const [jobStatus, setJobStatus] = useState<string>("none")
  const [currentTags, setCurrentTags] = useState<any[]>([])

  const wholeQuery = useMemo(
    () =>
      contactQuery != null && !isEmpty(contactQuery)
        ? currentTags.length > 0
          ? `${contactQuery} AND tags:\\[${currentTags.map((t) => t.id).join(", ")}\\]`
          : contactQuery
        : currentTags.length > 0
        ? `tags:\\[${currentTags.map((t) => t.id).join(", ")}\\]`
        : undefined,
    [contactQuery, currentTags]
  )

  const [filterText, setFilterText] = useState<string | undefined>(undefined)
  const { data, isLoading, isFetching, refetch } = useQueryContacts({
    withParsing: false,
    contactQuery: wholeQuery,
    jobQuery,
    jobStatus
  })

  const contacts = useMemo(
    () =>
      ((data as any) || []).map((contact: any) => {
        const searchable = {}

        Object.entries(contact).forEach(([key, v]) => {
          const value =
            typeof v === "string"
              ? v.toLowerCase()
              : Array.isArray(v) && v.length > 0
              ? typeof v[0] === "string"
                ? v.join(" ").toLowerCase()
                : v
                    .map((c) => Object.values(c).join(" "))
                    .join(", ")
                    .toLowerCase()
              : v
          if (key !== "tags") {
            Object.assign(searchable, { [key]: value })
          }
        })

        return searchable
      }),
    [data]
  )

  const ids = useMemo(() => {
    if (!filterText) {
      return data
    }
    return ((contacts as any) || [])
      .filter((contact: any) => {
        return Object.values(contact).some((v: any) => (typeof v === "string" ? v.includes(filterText.toLowerCase()) : false))
      })
      .map((c: any) => c.id)
  }, [data, contacts, filterText])

  const filteredItems = useMemo(() => {
    if (!filterText) {
      return data
    }
    return ((data as any) || [])?.filter((c: any) => ids.includes(c.id))
  }, [data, ids, filterText])

  const columns: IDataTableColumn[] = useMemo(
    () => [
      {
        name: translate("Contact.Fields.FirstName"),
        sortable: true,
        selector: "firstName"
      } as IDataTableColumn,
      {
        name: translate("Contact.Fields.LastName"),
        sortable: true,
        selector: "lastName"
      } as IDataTableColumn,
      {
        name: translate("Contact.Fields.PhoneNumbers"),
        sortable: true,
        selector: "phoneNumbers",
        cell: (rowData) => rowData.phoneNumbers?.join(", ")
      } as IDataTableColumn,
      {
        name: translate("Contact.Fields.Address"),
        sortable: true,
        selector: "addresses",
        cell: (rowData) =>
          rowData.addresses && rowData.addresses.length > 0
            ? `${rowData.addresses[0].line1 ? `${rowData.addresses[0].line1}, ` : ""}${rowData.addresses[0].locality}`
            : ""
      } as IDataTableColumn,
      {
        name: translate("Contact.Fields.resume"),
        sortable: false,
        selector: "website",
        cell: ({ website }) =>
          website && (
            <IconButton href={website} target="_blank">
              <Description />
            </IconButton>
          )
      } as IDataTableColumn,
      {
        name: translate("Contact.Fields.Emails"),
        sortable: false,
        selector: "emails",
        cell: (rowData) => rowData.emails?.join(", ")
      } as IDataTableColumn
    ],
    []
  )

  const conditionalRowStyles = [
    {
      when: (rowData: any) => rowData.jobs.length >= 1,
      style: {
        backgroundColor: "rgba(141, 182, 254, 0.4)"
      }
    }
  ]

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setFilterText("")
      }
    }

    return <FilterComponent onFilter={(e: any) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
  }, [filterText])

  return (
    <>
      <Card style={{ marginBottom: "20px" }}>
        <SearchBox
          search={refetch}
          contactQuery={contactQuery}
          setContactQuery={setContactQuery}
          jobQuery={jobQuery}
          setJobQuery={setJobQuery}
          jobStatus={jobStatus}
          setJobStatus={setJobStatus}
          currentTags={currentTags}
          setTags={setCurrentTags}
        />
      </Card>
      <Card>
        <div>
          <DataTable
            highlightOnHover
            columns={columns}
            data={filteredItems}
            persistTableHead
            defaultSortAsc
            expandableRows
            noHeader
            defaultSortField="firstName"
            conditionalRowStyles={conditionalRowStyles}
            progressPending={isLoading || isFetching}
            noDataComponent={<div style={{ fontSize: "24px", fontWeight: 700, padding: "24px" }}>{translate("Common.NoData")}</div>}
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            progressComponent={<div style={{ fontSize: "24px", fontWeight: 700, padding: "24px" }}>{translate("Common.Loading")}</div>}
            expandableRowsComponent={<ContactTabs />}
          />
        </div>
      </Card>
    </>
  )
}

const FilterComponent: React.FC<{
  filterText: string | undefined
  onFilter: (event: any) => void
  onClear: () => void
}> = ({ filterText, onFilter, onClear }) => {
  const { t: translate } = useTranslation()

  return (
    <>
      <TextField id="search" type="text" placeholder={translate("Common.Filter")} aria-label="Search Input" value={filterText} onChange={onFilter} />
      <div style={{ cursor: "pointer" }} onClick={onClear}>
        <Close />
      </div>
    </>
  )
}
