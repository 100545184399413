import React from "react"
import isEmpty from "lodash/isEmpty"
import environment from "config/env"
import { useTranslation } from "react-i18next"
import { Typography, IconButton } from "@material-ui/core"
import Box from "@material-ui/core/Box"
import DeleteIcon from "@material-ui/icons/Delete"
import { useMutationDeleteVault } from "api/hooks/delete"
import DeleteVaultConfirmation from "containers/formulaires/vouteDeDocuments/modals/DeleteVaultConfirmation"
import useStyles from "./styles"
import { DocumentHeader } from "./components/documentHeader"
import InitialLoading from "../../../components/loading"
import { useQueryFolders, useQueryVaultLink } from "../../../api/hooks/hooks"
import { Folder } from "./components/folder"

export const VouteDeDocuments = ({ contactId, jobTagId, ContactName }) => {
  const classes = useStyles()
  // const [token, setToken] = useState("")
  const { data: folders, isLoading: isLoadingFolders } = useQueryFolders(contactId, jobTagId)
  const { data: token } = useQueryVaultLink(contactId, jobTagId)
  const { t: translate } = useTranslation()
  const [showVaultDeleteConfirmation, setShowVaultDeleteConfirmation] = React.useState(false)

  const { mutateAsync } = useMutationDeleteVault(token?.id)

  const deleteToken = async () => {
    setShowVaultDeleteConfirmation(true)
  }

  return (
    <div
      style={{
        minHeight: "5em",
        margin: "1em",
        width: "100%"
      }}
    >
      {token && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "baseline"
          }}
        >
          <Typography component="span">
            {translate("Document.Vault.Url")}
            <a target="_blank" href={environment.api.vaultUrl + token.id} rel="noreferrer">
              {environment.api.vaultUrl + token.id}
            </a>
          </Typography>
          <IconButton onClick={() => deleteToken()}>
            <DeleteIcon />
          </IconButton>
        </Box>
      )}

      {showVaultDeleteConfirmation && (
        <DeleteVaultConfirmation open={showVaultDeleteConfirmation} tokenId={token.id} onClose={() => setShowVaultDeleteConfirmation(false)} />
      )}

      <DocumentHeader />
      {isLoadingFolders ? (
        <InitialLoading fullScreen={false} />
      ) : (
        <ul className={classes.container}>
          {isEmpty(folders) ? (
            <InitialLoading fullScreen={false} />
          ) : (
            folders.map((folder) => (
              <Folder
                key={folder.name}
                folder={folder}
                contactName={ContactName}
                contactId={contactId}
                jobTagId={jobTagId}
                isLoadingFolders={isLoadingFolders}
              />
            ))
          )}
        </ul>
      )}
    </div>
  )
}
