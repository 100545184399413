import { makeStyles } from "@material-ui/styles"

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.black.light,
    padding: theme.spacing(1),
    display: "flex",
    flexFlow: "column",
    flex: 1
  },
  wrapper: {
    margin: theme.spacing(2),
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  box: {
    padding: "1em 3em"
  },
  addNotes: {
    padding: "10px",
    margin: "1em 0"
  },
  raison: { textAlign: "center", fontSize: 18 },
  raisonField: { marginTop: "5px !important" },
  h3: {
    padding: theme.spacing(1, 0),
    fontSize: 20,
    fontWeight: 600,
    textAlign: "left",
    textDecoration: "underline",
    textTransform: "uppercase"
  },
  footer: {
    padding: theme.spacing(2, 0),
    display: "flex",
    flexDirection: "row-reverse",
    alignItems: "center",
    justifyContent: "space-between"
  },
  dialogContent: {
    backgroundColor: "#fff",
    padding: theme.spacing(1, 4)
  },
  center: {
    justifyContent: "center",
    alignItems: "center"
  },
  tab: {
    display: "flex",
    alignItems: "center",
    flexFlow: "column",
    flex: "1 0",
    "& >div": {
      width: "100%"
    }
  }
}))

export default { useStyles }
