import React from "react"
import { useTranslation } from "react-i18next"
import useStyles from "../styles"

export const DocumentHeader = () => {
  const { t: translate } = useTranslation()
  const classes = useStyles()

  return (
    <ul className={classes.container}>
      <li className={classes.row}>
        <div className={`${classes.type} ${classes.displayFlexCenter}`}>
          <span>{translate("Document.FileType")}</span>
        </div>

        <div className={`${classes.notes} ${classes.displayFlexCenter}`}>
          <span>{translate("Document.Comments")}</span>
        </div>

        <div className={`${classes.notes} ${classes.displayFlexCenter}`}>
          <span>{translate("Document.FromCandidate")}</span>
        </div>

        <div className={`${classes.files} ${classes.displayFlexCenter}`}>
          <span>{translate("Document.FilesAndLinks")}</span>
        </div>
        <div className={`${classes.addFiles} ${classes.displayFlexCenter}`}>
          <span>{translate("Document.AddFileOrLink")}</span>
        </div>
      </li>
    </ul>
  )
}
