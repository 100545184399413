import React from "react"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import Backdrop from "@material-ui/core/Backdrop"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import { useTranslation } from "react-i18next"
import { useStylesModal } from "./stylesModal"

interface IModalProps {
  title: string
  open: boolean
  close?: () => void
  closeText?: string

  action?: (() => void) | JSX.Element
  backdrop?: boolean
  fullWidth?: boolean
  maxWidth?: false | "xs" | "sm" | "md" | "lg" | "xl"
  saveText?: string
  dialogContentClass?: string
}

export const Modal: React.FC<IModalProps> = (props) => {
  const { t: translate } = useTranslation()
  const classes = useStylesModal()
  const { fullWidth, closeText, dialogContentClass, open, title, children, maxWidth, saveText, action, close, backdrop = false } = props
  return (
    <Dialog
      fullWidth={fullWidth}
      BackdropComponent={backdrop ? () => <Backdrop className={classes.backdrop} open={open} /> : undefined}
      maxWidth={maxWidth}
      open={open}
      aria-labelledby="max-width-dialog-title"
    >
      <DialogTitle className={classes.title} id="max-width-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent className={dialogContentClass}>{children}</DialogContent>
      <DialogActions className={classes.button}>
        <Button onClick={close} variant="text" color="secondary">
          {!closeText ? translate("Common.Close") : closeText}
        </Button>
        {action &&
          (typeof action === "function" ? (
            <Button onClick={action} id="searchTag" variant="outlined" color="primary">
              {saveText || translate("Common.Save")}
            </Button>
          ) : (
            action
          ))}
      </DialogActions>
    </Dialog>
  )
}
