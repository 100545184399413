import React, { useCallback, useEffect } from "react"
import Fade from "@material-ui/core/Fade"
import CircularProgress from "@material-ui/core/CircularProgress"
import { Button } from "@material-ui/core"
import CloudUploadIcon from "@material-ui/icons/CloudUpload"
import { useDropzone } from "react-dropzone"
import axios from "axios"
import { showErrorToast } from "utils/toast"
import { useStyles } from "../styles"
import { putCvInformation } from "../../../redux/cv/superFetch"

const DropZone = ({
  query,
  postNameFromButton,
  afterDropZone,
  cv,
  jobName,
  step,
  setQuery,
  setStep,
  jobId,
  findIdOfJobs,
  getResumeData,
  clientID,
  auth
}) => {
  const classes = useStyles()
  const [timer, setTimer] = React.useState(false)

  const handleClickQuery = useCallback(() => {
    setTimer(true)

    if (query !== "idle" || cv.isFetchingResumeDatas) {
      setQuery("idle")
      return
    }

    setQuery("progress")
  }, [cv.isFetchingResumeDatas, query, setQuery])

  const onDrop = useCallback(
    async (acceptedFiles) => {
      const cvFile = acceptedFiles
      const cvFileExtension = acceptedFiles[0].type
      const fileSize = acceptedFiles[0].size / 1024 / 1024 // in MiB
      if (fileSize > 2) {
        // eslint-disable-next-line no-alert
        alert("File size exceeds 2 MiB")
        return
      }

      if (!jobId) {
        // eslint-disable-next-line no-alert
        alert("Missing JobID, contact support")
        return
      }

      await ContactService.sendContactCV(cvFile, cvFileExtension, clientID, jobId, getResumeData)

      setStep({ id: 2 })

      handleClickQuery()
    },
    [auth, clientID, handleClickQuery, jobId, getResumeData, setStep]
  )
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({ onDrop })

  useEffect(() => {
    if (timer) {
      setTimeout(() => {
        if (!cv.isFetchingResumeDatas) {
          setQuery("success")
          setStep({ id: 3 })
        }
      }, 3000)
    }
    return () => clearTimeout(timer)
  }, [cv.isFetchingResumeDatas, timer, setQuery, setStep])

  const files = acceptedFiles.map((file) => (
    <div key={file.path} className={classes.placeholder}>
      {query === "success" ? (
        <span>{file.path} success!</span>
      ) : (
        <Fade
          in={query === "progress" || query === "idle"}
          style={{
            transitionDelay: query === "progress" ? "800ms" : "0ms"
          }}
          unmountOnExit
        >
          <CircularProgress />
        </Fade>
      )}
    </div>
  ))

  const PutResume = async () => {
    findIdOfJobs()
    afterDropZone()
  }
  return (
    <section
      className="container"
      style={{
        height: "100%",
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
      }}
    >
      <span style={{ marginBottom: 5 }}>{postNameFromButton !== "" ? postNameFromButton : jobName}</span>

      <div
        style={{
          borderWidth: "2px",
          borderStyle: "dashed",
          padding: "20px",
          textAlign: "center",
          cursor: "pointer"
        }}
        {...getRootProps({ className: "dropzone" })}
      >
        <input {...getInputProps()} />
        <p>
          Merci de téléverser ou <i>Drag&Drop</i> votre CV afin de débuter le processus de candidature
        </p>
        <CloudUploadIcon className="cloud-up-icon" style={{ fontSize: 50 }} />
      </div>
      <aside>
        <ul style={{ textAlign: "center", listStyle: "none" }}>{files}</ul>
      </aside>
      {step.id === 3 && (
        <Button autoFocus variant="contained" onClick={PutResume} style={{ marginTop: "1.5em" }} color="secondary">
          Suivant
        </Button>
      )}
      {/* <Button onClick={NoCv}>NoCv</Button> */}
    </section>
  )
}

export const ContactService = {
  async sendContactCV(cvFile, cvFileExtension, clientID, jobId, getResumeData) {
    try {
      const resId = await axios.get(`/contacts/resumes/${clientID}/${jobId}`)
      const res = await putCvInformation(resId.data.url, null, cvFileExtension, cvFile[0])
      getResumeData(clientID, jobId, resId.data.resumeId)

      return res
    } catch (e) {
      showErrorToast(e)
    }
  }
}

export default DropZone
