import { Grid } from "@material-ui/core"
import React from "react"
import { useTranslation } from "react-i18next"
import { useStyles } from "./styles"

export const Eversign = () => {
  const classes = useStyles()
  const { t: translate } = useTranslation()

  return (
    <div className={classes.root}>
      <Grid container spacing={3} style={{ height: "90%" }}>
        <Grid item xs={12} md={4} style={{ height: "85vh", overflow: "hidden" }}>
          <span className={classes.title}>{translate("Eversign.AnciaConfidentialityAgreement")}</span>
          <iframe
            title="Ancia – Entente de confidentialité"
            src="https://recruto.eversign.com/embedded/1cc8228b327048ceab5ab3eee63c950e"
            width="100%"
            height="100%"
            scrolling="no"
            frameBorder="1"
          />
        </Grid>
        <Grid item xs={12} md={4} style={{ height: "85vh", overflow: "hidden" }}>
          <span className={classes.title}>{translate("Eversign.AnciaSignUpForm")}</span>

          <iframe
            title="Ancia- Formulaire d’inscription"
            src="https://recruto.eversign.com/embedded/9442a442fa684f489addce3a21988f10"
            width="100%"
            height="100%"
            frameBorder="1"
            scrolling="no"
          />
        </Grid>

        <Grid item xs={12} md={4} style={{ height: "85vh", overflow: "hidden" }}>
          <span className={classes.title}>{translate("Eversign.DuvaltexSignUpForm")}</span>

          <iframe
            title="Duvaltex-Formulaire d’inscription"
            src="https://emploiduvaltex.eversign.com/embedded/e8dba8d9f8b04624b129f77eef748da5"
            width="100%"
            height="100%"
            frameBorder="1"
            scrolling="no"
          />
        </Grid>
        <Grid item xs={12} md={4} style={{ height: "85vh", overflow: "hidden" }}>
          <span className={classes.title}>{translate("Eversign.Prevost")}</span>

          <iframe
            title="Prevost"
            src="https://recruto.eversign.com/embedded/6a1ae881552f4842a283a40774455359"
            width="100%"
            height="100%"
            frameBorder="1"
            scrolling="no"
          />
        </Grid>
        <Grid item xs={12} md={4} style={{ height: "85vh", overflow: "hidden" }}>
          <span className={classes.title}>{translate("Eversign.StasSignUpForm")}</span>
          <iframe
            title="STAS- Formulaire d’inscription"
            src="https://cvstas.eversign.com/embedded/98ee6ef59ea14929af75b399dcbf691b"
            width="100%"
            height="100%"
            frameBorder="1"
            scrolling="no"
          />
        </Grid>
        <Grid item xs={12} md={4} style={{ height: "85vh", overflow: "hidden" }}>
          <span className={classes.title}>{translate("Eversign.MatraSignUpForm")}</span>
          <iframe
            title="Matra - Formulaire d’inscription"
            src="https://matra.eversign.com/embedded/2b43c0c092554f05b461d2258deeff5e"
            width="100%"
            height="100%"
            frameBorder="1"
            scrolling="no"
          />
        </Grid>
        <Grid item xs={12} md={4} style={{ height: "85vh", overflow: "hidden" }}>
          <span className={classes.title}>{translate("Eversign.GroupeM7SignUpForm")}</span>
          <iframe
            title="GroupeM7 - Formulaire d’inscription"
            src="https://metal7.eversign.com/embedded/06ad737d0940422bb5d375cf3b52aefb"
            width="100%"
            height="100%"
            frameBorder="1"
            scrolling="no"
          />
        </Grid>
      </Grid>
    </div>
  )
}
