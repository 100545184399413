import { useEffect } from "react"
import { Route, Switch, Redirect } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { MuiThemeProvider } from "@material-ui/core/styles"
import { ConfirmProvider } from "material-ui-confirm"
import "config/i18n/i18n"
import { RestrictedRoute } from "components/routes/restrictedRoute"
import { MainApp } from "containers/mainApp"
import useNavigation from "hooks/useNavigation"
import InitialLoading from "components/loading"
import { useQueryJobs } from "api/hooks"
import theme from "./theme"
import environment from "./config/env"
import { LoginComponent } from "./containers"
import "./sass/styles.scss"
import { fetchLoginState } from "./redux/auth/actions/auth"
import { setDefaultUrl } from "./redux/utils/axios"

setDefaultUrl(environment.api.baseUrl)

export const App = () => {
  const {
    location: { pathname }
  } = useNavigation()
  const auth = useSelector((state: any) => state.auth)
  const dispatch = useDispatch()
  useQueryJobs()

  useEffect(() => {
    const currentPathname = document.location.pathname
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(fetchLoginState({ pathname: currentPathname }))
  }, [pathname, dispatch])

  if (!auth.isPrepared) {
    return <InitialLoading />
  }

  return (
    <MuiThemeProvider theme={theme}>
      <ConfirmProvider>
        <Switch>
          <Route exact path="/login" component={LoginComponent} />
          <RestrictedRoute path="/" component={MainApp} />
          <Redirect to={{ pathname: "/" }} />
        </Switch>
      </ConfirmProvider>
    </MuiThemeProvider>
  )
}
