import React from "react"
import { useStyles } from "./styles"

export const ImportantLink = () => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <iframe title="Important Link" src="https://embednotion.com/embed/uwhddq1k" width="100%" height="100%" frameBorder="0" />
    </div>
  )
}
