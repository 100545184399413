import React from "react"
import PropTypes from "prop-types"
import Card from "@material-ui/core/Card"
import CardHeader from "@material-ui/core/CardHeader"
import { Button } from "@material-ui/core"
import useStyles from "./styles"

function ContactsCard() {
  const classes = useStyles()

  return (
    <Card className={classes.card}>
      <CardHeader title={<span style={{ fontSize: 19 }}>Tous les contacts</span>} subheader={<p>Voir la liste de tous les contacts</p>} />
      <Button variant="contained" color="primary" disableElevation href="/#/contacts">
        Voir tous les contacts
      </Button>
    </Card>
  )
}

ContactsCard.propTypes = {
  loading: PropTypes.bool
}

export default ContactsCard
