import React, { useState, useCallback, useEffect } from "react"
import { Field } from "react-final-form"
import { TextField } from "final-form-material-ui"
import { Typography, Paper, Grid, Dialog, DialogContent, DialogActions } from "@material-ui/core"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import AppBar from "@material-ui/core/AppBar"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import Box from "@material-ui/core/Box"
import { JobStatus } from "api/dto/IContactJobDto"
import { useTranslation } from "react-i18next"
import clsx from "clsx"
import { TabsHelper } from "utils/tabs"
import AddNotes from "./components/AddNotes"
import { VouteDeDocuments } from "./vouteDeDocuments/vouteDeDocuments"
import { useStyles } from "./styles"
import { ContactNotes } from "./components/contactNotes"
import AddNotesPartenaire from "./components/AddNotesPartenaire"
import AddNotesClient from "./components/AddNotesClient"
import { ContactStats } from "./stats/contactStats"
import RejectedDisclaimerNotes from "./components/rejectedDisclaimerNotes"
import Prequalifications from "./components/prequalifications"

export const EachField = (props) => {
  const { labell, namess } = props
  const [namessValue, setNamessValue] = useState(namess || "")
  const [, setLabellValue] = useState(labell || "")
  useEffect(() => {
    setNamessValue(namess)
    setLabellValue(labell)
  }, [props])

  if (namess === "") {
    return <span>nothing</span>
  }
  return (
    <Field
      fullWidth
      disabled
      name={`${namessValue}`}
      component={TextField}
      type="text"
      label="nothing for now"
      rows="4"
      value={namessValue}
      multiline
      variant="outlined"
    />
  )
}

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component="span">{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`
  }
}

const useStylesTabs = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper
  }
}))

const NotesAndForm = ({ rowData, auth }) => {
  const { ContactName, Id, currentJob, notesList } = rowData
  const classes = useStyles()
  const classesTabs = useStylesTabs()
  const { t: translate } = useTranslation()
  const [value, setValue] = useState(TabsHelper.getTab(Id))
  const [tempStatus, setTempStatus] = useState("")
  const [showModalRejectedDisclaimerNotes, setShowModalRejectedDisclaimerNotes] = useState(false)
  const handleChange = useCallback((event, newValue) => {
    TabsHelper.setTab(Id, newValue)
    setValue(newValue)
  }, [])

  return (
    <div className={clsx(classesTabs.root, "panel")}>
      <div className={classes.root}>
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            scrollButtons="on"
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="scrollable force tabs example"
          >
            <Tab className="tabNotes" label={translate("Job.Tabs.Notes")} {...a11yProps(0)} />
            <Tab className="tabStatistic" label={translate("Job.Tabs.Stats")} {...a11yProps(1)} />
            <Tab className="tabNotesHistory" label={translate("Job.Tabs.NoteHistory")} {...a11yProps(2)} />
            <Tab className="tabPrequalification" label={translate("Job.Tabs.Prequalification")} {...a11yProps(3)} />
            <Tab className="tabDocumentsVault" label={translate("Job.Tabs.Documents")} {...a11yProps(4)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          {(currentJob.status === JobStatus.Desistement || currentJob.status === JobStatus.Rejected) && (
            <RejectedDisclaimerNotes
              rowData={rowData}
              setShowModalRejectedDisclaimerNotes={setShowModalRejectedDisclaimerNotes}
              status={tempStatus}
            />
          )}
          <AddNotes
            rowData={rowData}
            setShowModalRejectedDisclaimerNotes={setShowModalRejectedDisclaimerNotes}
            tempStatus={tempStatus}
            setTempStatus={setTempStatus}
          />
          <AddNotesPartenaire rowData={rowData} />
          <AddNotesClient rowData={rowData} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ContactStats contactId={Id} currentJob={currentJob} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <ContactNotes notesList={notesList} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Prequalifications
            rowData={rowData}
            setTempStatus={setTempStatus}
            setShowModalRejectedDisclaimerNotes={setShowModalRejectedDisclaimerNotes}
          />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <Paper className={classes.wrapper}>
            <Grid container alignItems="flex-start" spacing={2}>
              <VouteDeDocuments contactId={Id} jobTagId={currentJob.jobTagId} ContactName={ContactName} />
            </Grid>
          </Paper>
        </TabPanel>
        <Dialog
          open={showModalRejectedDisclaimerNotes}
          fullWidth
          maxWidth="md"
          onClose={() => setShowModalRejectedDisclaimerNotes(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <RejectedDisclaimerNotes
              rowData={rowData}
              status={tempStatus}
              setShowModalRejectedDisclaimerNotes={setShowModalRejectedDisclaimerNotes}
            />
          </DialogContent>

          <DialogActions />
        </Dialog>
      </div>
    </div>
  )
}

export default NotesAndForm
