/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Box, Button, FormControl, TextField } from "@material-ui/core"
import useNavigation from "hooks/useNavigation"
import { fetchUser } from "../../redux/auth/actions/auth"
import InitialLoading from "../../components/loading"
import LoginWrapper from "./Wrapper"

export const LoginComponent: React.FC = () => {
  const auth = useSelector((state: any) => state.auth)
  const dispatch = useDispatch()
  const { push } = useNavigation()
  const [email, setEmail] = useState()
  const [password, setPassword] = useState()

  useEffect(() => {
    if (auth.isLoggedIn) {
      push("/")
    }
  }, [auth, dispatch])

  function handleSignIn(e: any) {
    e.preventDefault()
    dispatch((fetchUser as any)({ email, password }))
  }

  return (
    <LoginWrapper>
      {(auth.isFetching || !auth.isPrepared) && <InitialLoading />}
      <Box boxShadow={2} bgcolor="background.paper" className="login-box">
        {auth.error && <p>{auth.error}</p>}
        <form className="form" onSubmit={(e) => handleSignIn(e)}>
          <FormControl fullWidth>
            <TextField
              variant="outlined"
              margin="normal"
              id="email"
              label="Adresse courriel"
              name="email"
              autoComplete="email"
              autoFocus
              type="email"
              value={email}
              onChange={(e: any) => setEmail(e.target.value)}
            />
          </FormControl>

          <FormControl fullWidth>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              name="password"
              label="Mot de passe"
              id="password"
              autoComplete="current-password"
              type="password"
              placeholder=""
              value={password}
              onChange={(e: any) => setPassword(e.target.value)}
            />
          </FormControl>
          <Box paddingTop={3}>
            <Button id="login" size="large" type="submit" fullWidth variant="contained" color="primary">
              Connexion
            </Button>
          </Box>
        </form>
      </Box>
    </LoginWrapper>
  )
}
