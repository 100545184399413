import React, { forwardRef, useCallback } from "react"
import { NavLink as RouterLink } from "react-router-dom"
import clsx from "clsx"
import { makeStyles } from "@material-ui/styles"
import { List, ListItem, ListItemIcon } from "@material-ui/core"
import PostAddIcon from "@material-ui/icons/PostAdd"
import MenuIcon from "@material-ui/icons/Menu"
import { useTranslation } from "react-i18next"
import useNavigation from "hooks/useNavigation"
import AddCvBox from "../../../../../../components/ajouter-cv/AddCv"

const useStyles = makeStyles((theme: any) => ({
  root: {
    margin: 0,
    flex: 1
  },
  item: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: "#161413",
    padding: "10px 8px",
    justifyContent: "flex-start",
    textTransform: "none",
    fontWeight: 400,
    letterSpacing: 0,
    width: "100%",
    margin: 0
  },
  icon: {
    minWidth: "40px",
    color: "#161413",
    display: "flex"
    // justifyContent: "center",
  },
  active: {
    color: "white",
    fontWeight: 600,
    "& $icon": {
      fontWeight: 600,
      color: "white"
    }
  },
  divider: {
    margin: "1em 0"
  }
}))

interface ISidebarNavProps {
  toggle: () => void
  className?: string[]
  pages: any[]
}

const CustomRouterLink = forwardRef((refProps: any, ref) => (
  <div ref={ref as any} style={{ flexGrow: 1 }}>
    <RouterLink {...refProps} />
  </div>
))

export const SidebarNav: React.FC<ISidebarNavProps> = (props) => {
  const { pages, toggle, className } = props
  const { t: translate } = useTranslation()
  const [open, setOpen] = React.useState(false)
  const [realStep, setRealStep] = React.useState(0)
  const [step, setStep] = React.useState({ id: 0 })
  const classes = useStyles()

  const { location } = useNavigation()
  const checkIsActive = useCallback((path: string) => location.pathname.split("/")[1] === path.split("/")[1], [location])

  const handleClickOpen = () => {
    setStep({ id: 0 })
    setRealStep(0)
    setOpen(true)
  }

  const handleClose = () => {
    setRealStep(0)
    setOpen(false)
  }

  return (
    <>
      <List className={clsx(classes.root, className)} style={{ display: "flex", flexFlow: "column", overflow: "hidden" }}>
        <div style={{ flex: 1 }}>
          {pages.map((page: any) => (
            <ListItem
              button
              className={clsx(classes.item, classes.button, { [classes.active]: checkIsActive(page.href) })}
              key={page.title}
              component={CustomRouterLink}
              to={page.href}
            >
              <ListItemIcon className={classes.icon}>{page.icon}</ListItemIcon>
              <span>{page.title}</span>
            </ListItem>
          ))}
          <ListItem button className={clsx(classes.item, classes.button)} disableGutters onClick={handleClickOpen}>
            <ListItemIcon className={classes.icon}>
              <PostAddIcon />
            </ListItemIcon>
            <span>{translate("Contact.AddCV")}</span>
          </ListItem>
        </div>
      </List>

      {open && (
        <AddCvBox
          open={open}
          setOpen={setOpen}
          setStep={setStep}
          step={step}
          realStep={realStep}
          setRealStep={setRealStep}
          handleClose={handleClose}
          clientIDFromButton={undefined}
          postIDFromButton={undefined}
          postNameFromButton={undefined}
        />
      )}

      <ListItem button className={`closeMenu ${clsx(classes.item, classes.button)}`} style={{ overflow: "hidden" }} disableGutters onClick={toggle}>
        <ListItemIcon className={classes.icon}>
          <MenuIcon />
        </ListItemIcon>
        <span>{translate("Common.CloseMenu")} </span>
      </ListItem>
    </>
  )
}
