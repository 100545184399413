import palette from "../palette"

export default {
  root: {
    color: palette.icon.main,
    transition: ".2s color ease-in-out",
    "&:hover": {
      color: palette.secondary.main
    }
  }
}
