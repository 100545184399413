import { RECEIVE_JOBS, REQUEST_JOBS_DATAS, RECEIVE_ERROR } from "./actions"

const initialState = {
  isFetchingJob: false,
  jobsList: [],
  hasErrorJobs: false
}

function jobReducer(state = initialState, jobs) {
  switch (jobs.type) {
    case REQUEST_JOBS_DATAS:
      return {
        ...state,
        jobsList: [],
        isFetchingJob: true
      }

    case RECEIVE_JOBS:
      return {
        ...state,
        jobsList: jobs.json,
        isFetchingJob: false,
        hasErrorJobs: false
      }

    case RECEIVE_ERROR:
      return {
        ...state,
        jobsList: [],
        isJobFetching: false,
        isFetching: false,
        hasErrorJobs: false
      }
    default:
      return state
  }
}

export default jobReducer
