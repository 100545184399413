import styled from "styled-components"
import { makeStyles } from "@material-ui/core/styles"

const Button = styled.button`
  background-color: red;
`
const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "80%",
    textAlign: "center"
  },
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  button: {
    margin: theme.spacing(2)
  },
  closeIcon: {
    position: "absolute",
    padding: "3px",
    right: "7px",
    top: "7px",
    width: "1.4em",
    height: "1.4em",
    borderRadius: "50%",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#eeeeee"
    }
  },
  placeholder: {
    marginTop: "10px",
    height: 40
  },

  formControl: {
    margin: theme.spacing(2),
    minWidth: "25vw"
  },
  chips: {
    display: "flex",
    flexWrap: "wrap"
  },
  chip: {
    margin: 2
  }
}))
export { Button, useStyles }
