interface IParameter {
  id: string
  field: string
  fieldName: string
  value?: any
  operator?: "AND" | "OR"
}

interface IGroup {
  id: string
  parameters: IParameter[]
  operator?: "AND" | "OR"
}

type ISearch = IGroup[]

// eslint-disable-next-line no-shadow
export enum QueryType {
  contact = "contact",
  job = "job"
}

export type { IParameter, IGroup, ISearch }
