import React from "react"
import { toast } from "react-toastify"

export function showInfoToast(text: string) {
  toast.info(<div style={{ display: "flex", alignItems: "center" }}>{text}</div>)
}
export function showErrorToast(text: string) {
  toast.error(<div style={{ display: "flex", alignItems: "center" }}>{text}</div>)
}
export function showSuccessToast(text: string) {
  toast.success(<div style={{ display: "flex", alignItems: "center" }}>{text}</div>, { autoClose: 2000 })
}
