import React from "react"
import { useStyles } from "./styles"

export const SocialMediaManagement = () => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <iframe title="Social media management" src="https://recruto.cldportal.com/dashboard" width="100%" height="100%" frameBorder="0" />
    </div>
  )
}
