import { Grid, InputLabel, MenuItem, Select, TextField, OutlinedInput, makeStyles, Theme } from "@material-ui/core"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import "./addUrlModal.scss"
import { ModalWrapper } from "components/modal/modalWrapper"
import { useQueryFolders } from "api/hooks"
import { Controller, useWatch } from "react-hook-form"
import { useMutateGenerateToken, useMutateSendToken } from "api/hooks/add"
import { showErrorToast } from "utils/toast"
import { LabelWithPopover } from "components/label/labelWithPopover"
import { useSelector } from "react-redux"
import { FilteredOutFolders } from "./defaultFolders"

interface ISendVaultModalProps {
  row: any
  opened: boolean
  toggle: () => void
}

export const SendVaultModal: React.FunctionComponent<ISendVaultModalProps> = (props) => {
  const { row, opened, toggle } = props
  const auth = useSelector((state: any) => state.auth)
  console.log(auth)
  const { t: translate } = useTranslation()
  const { data: folders, isLoading } = useQueryFolders(row.Id, row.currentJob.jobTagId)
  const { mutateAsync: generateToken } = useMutateGenerateToken()
  const { mutateAsync: sendToken } = useMutateSendToken()

  const options = useMemo(() => folders?.map((f) => ({ key: f.name, value: f.name })), [folders])

  const onSubmit = async (data: any) => {
    const { firstName, lastName, email, delay, required, optional, notes } = data
    const currentFolders: any[] = []

    if (required.length === 0 && optional.length === 0) {
      showErrorToast(translate("Document.MissingFolders"))
      return
    }

    required.forEach((r: any) => {
      const f = folders?.find((currentFolder: any) => currentFolder.name === r)
      if (f) {
        currentFolders.push({ name: f.name, isRequired: true })
      }
    })

    optional.forEach((o: any) => {
      const f = folders?.find((currentFolder: any) => currentFolder.name === o)
      if (f) {
        currentFolders.push({ name: f.name, isRequired: false })
      }
    })

    const recrutoName = `${auth.user.givenName} ${auth.user.familyName}`

    const tokenData = {
      firstName,
      lastName,
      contactId: row.Id,
      contactName: `${firstName} ${lastName}`,
      jobId: row.currentJob.jobTagId,
      recrutorName: recrutoName,
      delay,
      folders: currentFolders,
      notes
    }

    // await generate token
    const { data: token } = await generateToken(tokenData)

    // await send token
    await sendToken({
      from: "info@recruto.ca",
      name: "Recruto",
      to: email,
      token,
      tokenData
    })

    toggle()
  }

  return (
    <ModalWrapper
      defaultValues={{
        delay: 72,
        email: row.Email,
        firstName: row.ContactName.split(" ")[0],
        lastName: row.ContactName.split(" ")[1],
        required: [],
        optional: []
      }}
      onSubmit={onSubmit}
      title={translate("Document.Vault.SendVault")}
      saveText={translate("Document.Vault.Send")}
      opened={opened}
      toggle={toggle}
    >
      {({ formState: { errors }, control }) => (
        <>
          <div className="content" style={{ paddingBottom: 0 }}>
            <p>{translate("Document.Vault.SendValutHint")}</p>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <div className="form-input">
                  <InputLabel className="input-label" htmlFor="firstName">
                    {translate("Document.Vault.Fields.FirstName")}
                  </InputLabel>
                  <Controller
                    name="firstName"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <TextField className={errors?.firstName ? "is-invalid" : ""} {...field} fullWidth type="text" variant="outlined" />
                    )}
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="form-input">
                  <InputLabel className="input-label" htmlFor="lastName">
                    {translate("Document.Vault.Fields.LastName")}
                  </InputLabel>
                  <Controller
                    name="lastName"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <TextField className={errors?.lastName ? "is-invalid" : ""} {...field} fullWidth type="text" variant="outlined" />
                    )}
                  />
                </div>
              </Grid>
            </Grid>

            <div className="form-input">
              <InputLabel className="input-label" htmlFor="email">
                {translate("Document.Vault.Fields.Email")}
              </InputLabel>
              <Controller
                name="email"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField className={errors?.email ? "is-invalid" : ""} {...field} fullWidth type="text" variant="outlined" />
                )}
              />
            </div>

            <div className="form-input">
              <InputLabel className="input-label" htmlFor="delay">
                {translate("Document.Vault.Fields.Delay")}
              </InputLabel>
              <Controller
                name="delay"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    className={errors?.delay ? "is-invalid" : ""}
                    {...field}
                    fullWidth
                    type="number"
                    variant="outlined"
                    InputProps={{ inputProps: { min: 1, max: 3600 } }}
                  />
                )}
              />
            </div>

            <div className="form-input">
              <LabelWithPopover
                id="required-label"
                labelText={translate("Document.Vault.Fields.RequiredFolders")}
                popoverText={translate("Document.Vault.Fields.RequiredFoldersExplanation")}
              />

              <Controller
                name="required"
                control={control}
                render={({ field }) => <FolderSelect field={field} isLoadingFolders={isLoading} options={options} />}
              />
            </div>
            <div className="form-input">
              <InputLabel className="input-label" htmlFor="optional">
                {translate("Document.Vault.Fields.OptionalFolders")}
              </InputLabel>
              <Controller
                name="optional"
                control={control}
                render={({ field }) => <FolderSelect field={field} isLoadingFolders={isLoading} options={options} />}
              />
            </div>
            <div className="form-input">
              <LabelWithPopover
                id="required-label"
                labelText={translate("Document.Vault.Fields.Notes")}
                popoverText={translate("Document.Vault.Fields.NotesExplanation")}
              />

              <Controller
                name="notes"
                control={control}
                render={({ field }) => (
                  <TextField
                    className={errors?.notes ? "is-invalid" : ""}
                    {...field}
                    fullWidth
                    type="textarea"
                    rows={3}
                    multiline
                    variant="outlined"
                  />
                )}
              />
            </div>
          </div>
        </>
      )}
    </ModalWrapper>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  selected: {
    "&.Mui-selected": {
      backgroundColor: "#cecece",
      color: "#000",
      fontWeight: 600
    }
  }
}))

interface IFolderSelectProps {
  isLoadingFolders: boolean
  field: any
  options: { key: any; value: any }[] | undefined
}

const FolderSelect: React.FunctionComponent<IFolderSelectProps> = (props) => {
  const { field, isLoadingFolders, options } = props
  const { name } = field
  const classes = useStyles()
  const watchOptional: string[] = useWatch({ name: "optional" })
  const watchRequired: string[] = useWatch({ name: "required" })

  const availableOptions = useMemo(
    () =>
      options
        ?.filter((o) => (name === "required" ? !watchOptional.includes(o.value) : !watchRequired.includes(o.value)))
        .filter((o) => !FilteredOutFolders.includes(o.key)),
    [options, watchOptional, watchRequired]
  )

  return (
    <Select
      disabled={isLoadingFolders}
      style={{ width: "100%" }}
      multiple
      onClose={field.onBlur}
      input={<OutlinedInput id="select-required-multiple" />}
      {...field}
    >
      {availableOptions?.map((o) => (
        <MenuItem classes={{ selected: classes.selected }} key={o.value} value={o.value}>
          {o.key}
        </MenuItem>
      ))}
    </Select>
  )
}
