import { makeStyles } from "@material-ui/styles"

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100vh",
    background: `linear-gradient(to bottom right, ${theme.palette.secondary.dark} 0%, ${theme.palette.primary.dark} 100%)`,
    position: "relative",
    "& .login-box": {
      width: "100%",
      maxWidth: "280px",
      margin: "auto",
      padding: "2em",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate3d(-50%, -50%, 0)",
      zIndex: "9999"
    }
  },
  loginWrapper: {
    width: "100%",
    height: "100vh",
    background: "linear-gradient(to bottom right, blue 0%, orange 100%)",
    position: "relative"
  }
}))

export default useStyles
