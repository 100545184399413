import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: "100%",
    padding: theme.spacing(0, 2, 2, 2)
  },
  media: {
    height: 50
  }
}))

export default useStyles
