import React from "react"
import Loading from "react-loading"
import { useStyles } from "./styles"

const InitialLoading = ({ color = "orange", fullScreen = true, content = false }) => {
  const classes = useStyles()
  return (
    <div className={`${fullScreen ? classes.root : content ? classes.content : ""}`}>
      <div className={classes.loading}>
        <Loading type="bars" color={color} />
      </div>
    </div>
  )
}

export default InitialLoading
