import { Grid, MenuItem, Paper, Typography } from "@material-ui/core"
import React, { useCallback, useMemo, useState } from "react"
import { TextField, Select } from "final-form-material-ui"
import { Field, Form } from "react-final-form"
import FileCopyIcon from "@material-ui/icons/FileCopy"
import LibraryAddIcon from "@material-ui/icons/LibraryAdd"
import InfoIcon from "@material-ui/icons/Info"
import { useTranslation } from "react-i18next"
import { normalizeDate } from "helper"
import { useMutateUpdateJob } from "api/hooks/update"
import { JobStatus } from "api/dto"
import { ProvidePrequalif } from "hooks/usePrequalif"
import ActionsList from "./ActionsList"
import RenderCount from "../renderCount"
import { useStyles } from "../styles"
import DatePickerWrapper, { DatePickerWrapperDateTimeLocale } from "./datePickerWrapper"
import { PrequalSubmitButton } from "./prequalSubmitButton"
import { PrequalTextfieldWrapper } from "./prequalTextfieldWrapper"
import { setJobsPrequalToLocalStorage } from "./prequalLocalStorage"

const Prequalifications = ({ rowData, setTempStatus, setShowModalRejectedDisclaimerNotes }) => {
  const { ContactName, Id, Email, City, LastUpdated, PhoneWithExtension1, PhoneWithExtension2, currentJob } = rowData
  const { t: translate } = useTranslation()
  const { mutateAsync } = useMutateUpdateJob(Id, currentJob.jobTagId)
  const [classFake, setClassFake] = useState("1")
  const classes = useStyles()

  const updateContactPrequalif = useCallback(
    async (currentRow) => {
      const {
        motivations,
        formations,
        experiencesProfessionnelles,
        aptitudesTechniques,
        aptitudesLinguistiques,
        disponibiliteMobilibite,
        attentesSalariales,
        appreciationRecruteur
      } = currentRow.currentJob.prequalification

      const job = {
        ...currentRow.currentJob,
        prequalification: {
          ...currentRow.currentJob.prequalification,
          motivations: motivations || null,
          formations: formations || null,
          experiencesProfessionnelles: experiencesProfessionnelles || null,
          aptitudesTechniques: aptitudesTechniques || null,
          aptitudesLinguistiques: aptitudesLinguistiques || null,
          disponibiliteMobilibite: disponibiliteMobilibite || null,
          attentesSalariales: attentesSalariales || null,
          appreciationRecruteur: appreciationRecruteur || null
        }
      }

      await mutateAsync({ ...currentRow, currentJob: job })

      if (currentRow.currentJob.status === JobStatus.Hiring) {
        postActionInfuDatas(currentRow.Id, "03903d06-6942-4394-8501-db489898b7aa")
      }
    },
    [mutateAsync]
  )

  const onSubmit = useCallback(
    async (contact) => {
      updateContactPrequalif(contact)
    },
    [ContactName, currentJob]
  )

  const allStatus = useMemo(
    () =>
      Object.values(JobStatus).map((j) => ({
        label: translate(`Job.Status.${j}`),
        value: j
      })),
    []
  )

  return (
    <Form
      onSubmit={onSubmit}
      subscription={{ submitting: true, pristine: true, dirty: true }}
      initialValues={{ ...rowData }}
      render={({ handleSubmit, form }) => (
        <form onSubmit={handleSubmit} noValidate className={classFake}>
          <ProvidePrequalif>
            <Paper className={classes.wrapper}>
              <Grid container alignItems="flex-start" spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="h3" className={classes.h3}>
                    {ContactName}{" "}
                    <span title={currentJob.notes}>
                      <InfoIcon />
                    </span>
                  </Typography>
                  <Typography variant="h4">
                    <a href={`mailto:${Email}`}>{Email}</a>
                  </Typography>
                  {PhoneWithExtension1.length > 6 && <a href={`tel:${PhoneWithExtension1}`}>{PhoneWithExtension1}</a>}
                  {PhoneWithExtension1.length > 6 && PhoneWithExtension2.length > 6 && <br />}
                  {PhoneWithExtension2.length > 6 && <a href={`tel:${PhoneWithExtension2}`}>{PhoneWithExtension2}</a>}{" "}
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Typography variant="h6">Dernière mise-à-jour {normalizeDate(LastUpdated)}</Typography>
                  <Typography variant="h5">{City && <span>{City}</span>}</Typography>
                  <Field name="DateNaissance" component={DatePickerWrapperDateTimeLocale} label="Date de naissance" />
                </Grid>
              </Grid>
            </Paper>

            <Paper className={classes.wrapper} onScroll={(s) => console.log("scroll", s)}>
              <Grid container alignItems="flex-start" spacing={2}>
                <Grid item xs={12} sm={9}>
                  <Field
                    fullWidth
                    name="currentJob.jobTitle"
                    component={TextField}
                    value={currentJob.jobTitle}
                    type="text"
                    label="Titre de l'affichage"
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Field name="LastUpdated" component={DatePickerWrapper} label="Date de préqualification" disabled />
                </Grid>
                <div style={{ display: "none" }} />
                <Grid item xs={12} sm={6}>
                  <Field
                    fullWidth
                    id="prequalif-motivations"
                    name="currentJob.prequalification.motivations"
                    component={PrequalTextfieldWrapper}
                    type="text"
                    label="Motivations"
                    rows="4"
                    multiline
                    variant="outlined"
                  >
                    <RenderCount />
                  </Field>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    fullWidth
                    id="prequalif-formations"
                    name="currentJob.prequalification.formations"
                    component={PrequalTextfieldWrapper}
                    type="text"
                    label="Formations"
                    rows="4"
                    multiline
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    fullWidth
                    id="prequalif-experiencesProfessionnelles"
                    name="currentJob.prequalification.experiencesProfessionnelles"
                    component={PrequalTextfieldWrapper}
                    type="text"
                    label="Expériences professionnelles"
                    rows="4"
                    multiline
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    fullWidth
                    id="prequalif-aptitudesTechniques"
                    name="currentJob.prequalification.aptitudesTechniques"
                    component={PrequalTextfieldWrapper}
                    type="text"
                    label="Aptitude technique"
                    rows="4"
                    multiline
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    fullWidth
                    id="prequalif-aptitudesLinguistiques"
                    name="currentJob.prequalification.aptitudesLinguistiques"
                    component={PrequalTextfieldWrapper}
                    type="text"
                    label="Aptitudes linguistiques"
                    rows="4"
                    multiline
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    fullWidth
                    id="prequalif-disponibiliteMobilibite"
                    name="currentJob.prequalification.disponibiliteMobilibite"
                    component={PrequalTextfieldWrapper}
                    type="text"
                    label="Disponibilité et mobilité"
                    rows="4"
                    multiline
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Field
                    fullWidth
                    id="prequalif-attentesSalariales"
                    name="currentJob.prequalification.attentesSalariales"
                    component={PrequalTextfieldWrapper}
                    type="text"
                    label="Attentes salariales"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6} sm={4}>
                  <Field
                    id="prequalif-dateEntrevuePersonne"
                    name="currentJob.prequalification.dateEntrevuePersonne"
                    component={DatePickerWrapperDateTimeLocale}
                    label="Date entrevue en personne"
                  />
                </Grid>
                <Grid item xs={6} sm={4}>
                  <Field
                    id="prequalif-dateEntrevueClient"
                    name="currentJob.prequalification.dateEntrevueClient"
                    component={DatePickerWrapperDateTimeLocale}
                    label="Date entrevue client"
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Field
                    fullWidth
                    id="prequalif-appreciationRecruteur"
                    name="currentJob.prequalification.appreciationRecruteur"
                    component={PrequalTextfieldWrapper}
                    type="text"
                    label="Appréciation du recruteur"
                    rows="4"
                    multiline
                    variant="outlined"
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Field
                    name="currentJob.status"
                    label="Statuts"
                    component={Select}
                    value={currentJob.status}
                    formControlProps={{ className: "fullwidth" }}
                  >
                    {allStatus.map((s) => (
                      <MenuItem
                        key={s.label}
                        onClick={(e) => {
                          if (e.currentTarget.dataset.value === JobStatus.Desistement || e.currentTarget.dataset.value === JobStatus.Rejected) {
                            setTempStatus(e.currentTarget.dataset.value)
                            setShowModalRejectedDisclaimerNotes(true)
                          }
                        }}
                        value={s.value}
                      >
                        {s.label}
                      </MenuItem>
                    ))}
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <div className={classes.footer}>
                    <ActionsList rowData={rowData} />
                    <div
                      style={{
                        flexGrow: 1
                      }}
                    >
                      <PrequalSubmitButton />
                    </div>
                    <div>
                      <span
                        style={{
                          cursor: "pointer",
                          padding: "0.5em"
                        }}
                        title="Copier la préqualification"
                      >
                        <FileCopyIcon onClick={() => setJobsPrequalToLocalStorage(currentJob)} />
                      </span>
                      <span
                        style={{
                          cursor: "pointer",
                          padding: "0.5em"
                        }}
                        title="Coller la préqualification"
                      >
                        <LibraryAddIcon onClick={() => setJobsPrequalFromLocalStorage(currentJob, setClassFake)} />
                      </span>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </ProvidePrequalif>
        </form>
      )}
    />
  )
}

export default Prequalifications
