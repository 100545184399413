import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column"
  },
  center: {
    textAlign: "center"
  },
  block: { display: "block", fontWeight: "bold" },
  bold: {
    fontWeight: "bold"
  },
  icon: {
    cursor: "pointer",
    padding: "1px"
  },
  addLinkOrDoc: {
    display: "flex",
    flexFlow: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center"
  },
  docOrLink: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",
    minWidth: "200px",
    maxWidth: "200px",
    cursor: "pointer"
  },
  nameSvgDocOrLink: {
    display: "flex",
    alignItems: "center"
  },
  nameDocOrLink: {
    maxWidth: "140px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  },
  displayFlexCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center"
  },
  displayFlexCenterFiles: {
    display: "flex",
    justifyContent: "center",
    overflow: "auto"
  },
  row: {
    display: "flex",
    paddingTop: "5px",
    paddingBottom: "5px",
    borderBottom: "1px solid #aaaaaa",
    borderTop: "1px solid #aaaaaa",
    width: "100%"
  },
  addFiles: {
    width: "12%"
  },
  addFilesButton: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    padding: "9px 14px"
  },
  addBoxIcon: {
    cursor: "pointer"
  },
  type: {
    width: "21%"
  },
  validation: {
    width: "10%"
  },
  notes: {
    margin: "1em",
    width: "38%",
    "&li": {
      width: "100%"
    }
  },
  files: {
    whiteSpace: "nowrap",
    width: "27%",
    flexDirection: "column",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  urlFiles: {
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline"
    }
  },
  userNotes: {
    margin: "1em",
    width: "38%",
    div: {
      marginBottom: "1rem"
    }
  },
  userNotesList: {
    listStyleType: "none",
    marginBottom: "1rem"
  },
  flexColumn: {
    flexDirection: "column"
  },
  userNotesDate: {
    fontSize: "12px"
  },
  userNotesDescription: {
    fontSize: "12px",
    textAlign: "left"
  }
}))

export default useStyles
