/* eslint-disable no-unused-vars */
import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Accordion from "@material-ui/core/Accordion"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import Typography from "@material-ui/core/Typography"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%"
  },
  times: {
    flexBasis: "15%"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "23.33%",
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  }
}))

const decodeEntities = (() => {
  // this prevents any overhead from creating the object each time
  const element = document.createElement("div")

  function decodeHTMLEntities(str: any) {
    if (str && typeof str === "string") {
      // strip script/html tags
      str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gim, "")
      str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim, "")
      element.innerHTML = str
      str = element.textContent
      element.textContent = ""
    }

    return str
  }

  return decodeHTMLEntities
})()

export const ContactNotes: React.FC<{ notesList: any[] }> = (props) => {
  const { notesList } = props
  const classes = useStyles()
  const [expanded, setExpanded] = React.useState<string | null>()

  const handleChange = (panel: string, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : null)
  }

  return (
    <div className={classes.root}>
      {notesList.length !== 0 ? (
        notesList.map((notes, i) => {
          const formatedDate = notes.last_updated
          const date = new Date(formatedDate) // formated_Date - SDK returned date
          const notesTime = `${date.getFullYear()}/${
            date.getMonth() + 1
          }/${date.getDate()}, ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`

          return (
            <Accordion key={`${notes.id}`} expanded={expanded === `panel${i}`} onChange={(e, isExpanded) => handleChange(`panel${i}`, isExpanded)}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel${i}bh-content`} id={`panel${i}bh-header`}>
                <Typography className={classes.times}>{notesTime}</Typography>
                <Typography className={classes.heading}>{notes.type}</Typography>
                <Typography className={classes.secondaryHeading}>{notes.title}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{decodeEntities(notes.body)}</Typography>
              </AccordionDetails>
            </Accordion>
          )
        })
      ) : (
        <span>Aucune note dans infu pour cette candidature</span>
      )}
    </div>
  )
}
