import { createReducer } from "redux-act"
import jwt from "jwt-decode"

import { failFetchingLoginState, fetchUser, failFetchingUser, login, logout, failFetchingApi, fetchAuth } from "../actions/auth"

const initial = {
  auth: {
    isPrepared: false,
    isLoggedIn: false,
    user: {
      project: ""
    },
    isFetching: false,
    error: undefined,
    jwt: "",
    authUser: null,
    pathname: null
  }
}

function hydrateActions(stringifiedActions) {
  try {
    return JSON.parse(stringifiedActions)
  } catch (e) {
    // console.error("stringifiedActions:", stringifiedActions)
    // console.error("Could not rehydrate the actions from the token", e)

    return []
  }
}

const authReducer = createReducer(
  {
    [failFetchingLoginState]: (state, payload) => ({
      ...state,
      isPrepared: true,
      pathname: payload.pathname
    }),
    [fetchUser]: (state) => ({
      ...state,
      isFetching: true,
      error: undefined
    }),
    [failFetchingUser]: (state, err) => ({
      ...state,
      isFetching: false,
      error: err
    }),
    [login]: (state, payload) => {
      const actionsParsed = hydrateActions(jwt(payload.jwt).actions)
      return {
        ...state,
        isPrepared: true,
        isLoggedIn: true,
        user: {
          ...jwt(payload.jwt),
          actions: actionsParsed
        },
        isFetching: false,
        error: undefined,
        jwt: payload.jwt,
        pathname: payload.pathname
      }
    },
    [logout]: () => ({
      ...initial.auth,
      isPrepared: true
    }),
    [failFetchingApi]: (state, err) => {
      return {
        ...state,
        error: err
      }
    },
    [fetchAuth]: (state, payload) => {
      return {
        ...state,
        authUser: payload
      }
    }
  },
  initial.auth
)

export default authReducer
