export const REQUEST_SOVREN_DATAS = "REQUEST_SOVREN_DATAS"
export const REQUEST_SOVREN_DATAS_MATCH = "REQUEST_SOVREN_DATAS_MATCH"
export const RECEIVE_SOVREN = "RECEIVE_SOVREN"
export const RECEIVE_SOVREN_MATCH = "RECEIVE_SOVREN_MATCH"
export const RECEIVE_ERROR = "RECEIVE_ERROR"

export const requestSovrenDatas = (action) => ({
  type: REQUEST_SOVREN_DATAS,
  json: action
})
export const requestSovrenDatasMatch = (action) => ({
  type: REQUEST_SOVREN_DATAS_MATCH,
  json: action
})

export const receiveSovren = (action) => ({
  type: RECEIVE_SOVREN,
  json: action
})
export const receiveSovrenMatch = (action) => ({
  type: RECEIVE_SOVREN_MATCH,
  json: action
})

export const receiveError = (err) => ({
  type: RECEIVE_ERROR,
  error: err.data
})
