import { ITagDto } from "api/dto"
import { IContactDto, IContactDtoV2 } from "api/dto/IContactDto"
import { IStatParams, IStatsDto } from "api/dto/IStatsDto"
import { mapV2Contact } from "api/mapping/ContactMapper"
import axios from "axios"
import environment from "config/env"
import { isEmpty } from "lodash"
import { useQuery, UseQueryResult } from "react-query"
import { useDispatch } from "react-redux"
import { receiveContactsDatas, requestContactsDatas } from "redux/contact/actions"
import { DefaultFolders } from "../../containers/formulaires/vouteDeDocuments/components/defaultFolders"

const ONE_HOUR = 3600000

export function useQueryFolders(contactId: string, jobId: string) {
  return useQuery(`${contactId}-${jobId}-folders`, async () => {
    const { data: currentFolders } = await axios.get(
      `/${environment.api.ressourcesUrl.contacts}/${contactId}/${environment.api.ressourcesUrl.jobs}/${jobId}/folders`
    )

    const folders = []
    for (let i = 0; i < DefaultFolders.length; i++) {
      const f = DefaultFolders[i]
      const foundEntity = currentFolders.find((t: any) => t.name === f.name)
      if (foundEntity) {
        folders.push(foundEntity)
      } else {
        folders.push(f)
      }
    }

    return folders
  })
}

export function useQueryContacts(
  params: {
    jobId?: string
    withParsing?: boolean
    contactQuery?: string
    jobQuery?: string
    jobStatus?: string
    withDispatch?: boolean
  } = {
    withParsing: true,
    withDispatch: false
  }
): UseQueryResult<IContactDto[] | IContactDtoV2[], Error> {
  const dispatch = useDispatch()
  const { contactQuery, jobId, jobQuery, jobStatus, withDispatch, withParsing } = params
  return useQuery(jobId ? `contacts-job-${jobId}` : "contacts", async () => {
    dispatch(requestContactsDatas())

    if (jobId) {
      const { data } = await axios.get(`${environment.api.ressourcesUrl.jobs}/${jobId}`)

      const contacts = data.contacts.map((c: any) => mapV2Contact(c, jobId))
      if (withDispatch) {
        dispatch(receiveContactsDatas(contacts))
      }

      return contacts
    }

    const { data } = await axios.get(environment.api.ressourcesUrl.contacts, {
      params: {
        contact: !isEmpty(contactQuery) ? contactQuery : undefined,
        job:
          jobStatus && jobStatus !== "none"
            ? jobQuery && !isEmpty(jobQuery)
              ? `${jobQuery} AND ${jobStatus}`
              : jobStatus
            : !isEmpty(jobQuery)
            ? jobQuery
            : undefined
      }
    })
    if (!withParsing) {
      return data.contacts
    }

    return data.contacts.map((c: any) => mapV2Contact(c))
  })
}

export function useQuerySovren() {
  return useQuery(
    "sovren",
    async () => {
      const { data: sovren } = await axios.post(`/${environment.api.ressourcesUrl.contacts}/ui/searcher`)
      return sovren
    },
    {
      staleTime: 20000
    }
  )
}

export function useQueryJobs() {
  return useQuery(
    "jobs",
    async () => {
      const { data: contacts } = await axios.get(`/${environment.api.ressourcesUrl.jobs}`)
      return contacts
    },
    {
      staleTime: 20000
    }
  )
}

export function useQueryTags(): UseQueryResult<ITagDto[], Error> {
  return useQuery(
    "tags",
    async () => {
      const { data: tags } = await axios.get(`/${environment.api.ressourcesUrl.tags}`)
      return tags
    },
    {
      staleTime: ONE_HOUR,
      cacheTime: ONE_HOUR
    }
  )
}

export function useQueryStats(params: IStatParams) {
  const queryParams: string[] = []
  Object.entries(params)
    .filter(([k, v]) => !isEmpty(v))
    .forEach(([k, v]) => {
      queryParams.push(`${k}=${v}`)
    })

  const keys = { ...params } as any

  delete keys.start
  delete keys.end

  return useQuery<IStatsDto[]>(
    `contact-job-stats-${Object.values(keys).join("-")}`,
    async () => {
      const { data } = await axios.get(`/${environment.api.ressourcesUrl.stats}?${queryParams.join("&")}`)
      return data
    },
    {
      staleTime: 10000,
      enabled: false
    }
  )
}

export function useQueryVaultLink(contactId: string, jobId: string) {
  return useQuery(`${contactId}-${jobId}-token`, async () => {
    const { data: token } = await axios.get(`/${environment.api.ressourcesUrl.tokens}/find?contactId=${contactId}&jobId=${jobId}`)
    return token
  })
}
