import { TextField } from "@material-ui/core"
import React from "react"
import { useTranslation } from "react-i18next"

export interface ContactTextBoxProps {
  setContactId: (contactId: string) => void
}

const ContactTextBox: React.FC<ContactTextBoxProps> = (props) => {
  const { setContactId } = props
  const { t: translate } = useTranslation()
  return (
    <TextField
      id="outlined-basic"
      style={{ width: "100%" }}
      label={translate("Statistics.ContactId")}
      variant="outlined"
      onBlur={(e) => setContactId(e.target.value)}
    />
  )
}

export default ContactTextBox
