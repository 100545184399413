import DateFnsUtils from "@date-io/date-fns"
import format from "date-fns/format"

import frLocale from "date-fns/locale/fr"
import enLocale from "date-fns/locale/en-US"

class FrLocalizedUtils extends DateFnsUtils {
  getDatePickerHeaderText(date) {
    return format(date, "d MMM yyyy", { locale: this.locale })
  }
}

export const localeMap = {
  fr: frLocale,
  en: enLocale
}

export const localeFormatMap = {
  fr: "d MMM yyyy HH:mm",
  en: "MMMM d, yyyy HH:mm"
}

export const localeCancelLabelMap = {
  fr: "annuler",
  en: "cancel"
}

export const localeUtilsMap = {
  en: DateFnsUtils,
  fr: FrLocalizedUtils
}
