import { makeStyles } from "@material-ui/styles"

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.black.dark,
    boxShadow: "none",
    zIndex: theme.zIndex.drawer + 1
  },
  flexGrow: {
    flexGrow: 1
  },
  icon: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.primary.main,
    padding: 0,
    fontSize: "1rem"
  },
  label: {
    display: "inline-block",
    color: "#fff",
    fontSize: "1rem",
    marginRight: "2em",
    paddingLeft: ".25em"
  },
  link: {
    textDecoration: "none",
    marginRight: ".75em"
  },
  searchBox: {
    marginLeft: "2em"
  },
  searchItem: {
    cursor: "pointer",
    padding: ".5em",
    borderBottom: "1px solid",
    "&:hover": {
      backgroundColor: "lightgray"
    }
  },
  displayBlock: {
    display: "block"
  },
  SearchWrapper: {
    position: "relative"
  },
  Search: {
    position: "relative",
    width: "25vw"
  },
  StyledInputBase: {
    width: "inherit",
    height: "inherit",
    backgroundColor: "white",
    borderRadius: "4px",
    ".muiinputbase-input": {
      paddingLeft: "0",
      width: "100%",
      paddingTopop: "10.5px",
      paddingBottom: "10.5px"
    }
  },
  SearchResultsWrapper: {
    width: "100%",
    backgroundColor: "white",
    maxHeight: "80vh",
    overflowY: "auto",
    position: "absolute",
    color: "black"
  },
  SearchTextField: {
    width: "inherit",
    height: "inherit",
    backgroundColor: "white",
    borderRadius: "4px",
    ".muiinputbase-input": {
      paddingleft: 0,
      width: "100%",
      paddingTop: "10.5px",
      paddingBottom: "10.5px"
    }
  }
}))
