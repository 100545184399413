import React from "react"
import { Grid } from "@material-ui/core"
import MiniTile from "../../components/skeleton/MiniTile"
import { useStyles } from "./styles"
import ContactsCard from "../../components/contacts-card/ContactsCard"

const Dashboard = () => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Grid container alignItems="flex-start" spacing={2}>
        {/* 1 */}
        <Grid item xs={6} sm={3}>
          <ContactsCard />
        </Grid>
        <Grid item xs={6} sm={3}>
          <MiniTile />
        </Grid>
        <Grid item xs={6} sm={3}>
          <MiniTile />
        </Grid>
        <Grid item xs={6} sm={3}>
          <MiniTile />
        </Grid>

        {/* 2 */}
        <Grid item xs={12} sm={6}>
          <MiniTile />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MiniTile />
        </Grid>

        {/* 3 */}
        <Grid item xs={12} sm={12}>
          <MiniTile />
        </Grid>
      </Grid>
    </div>
  )
}

export default Dashboard
