import React from "react"
import clsx from "clsx"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Drawer } from "@material-ui/core"
import ContactIcon from "@material-ui/icons/Contacts"
import DashboardIcon from "@material-ui/icons/Dashboard"
import EqualizerIcon from "@material-ui/icons/Equalizer"
import BallotIcon from "@material-ui/icons/Ballot"
import PeopleAltIcon from "@material-ui/icons/PeopleAlt"
import PublicIcon from "@material-ui/icons/Public"
import LinkIcon from "@material-ui/icons/Link"
import { useTranslation } from "react-i18next"
import CreateIcon from "@material-ui/icons/Create"
import { SidebarNav } from "./components"
import { useStyles } from "./styles"

const Sidebar = (props) => {
  const { t: translate } = useTranslation()

  const { open, variant, toggle, className } = props
  const classes = useStyles()
  const pages = [
    {
      title: `${translate("Sidebar.Dashboard")}`,
      href: "/dashboard",
      icon: <DashboardIcon />
    },
    {
      title: `${translate("Sidebar.ContactList")}`,
      href: "/contacts",
      icon: <ContactIcon />
    },
    {
      title: `${translate("Sidebar.JobsList")}`,
      href: "/jobs",
      icon: <BallotIcon />
    },

    {
      title: `${translate("Sidebar.SearchCV")}`,
      href: "/search",
      icon: <PeopleAltIcon />
    },

    {
      title: `${translate("Sidebar.StatsList")}`,
      href: "/stats",
      icon: <EqualizerIcon />
    },
    {
      title: `${translate("Sidebar.SocialMediaManagement")}`,
      href: "/socialmediamanagement",
      icon: <PublicIcon />
    },
    {
      title: `${translate("Sidebar.ImportantLinks")}`,
      href: "/importantlinks",
      icon: <LinkIcon />
    },
    {
      title: `${translate("Sidebar.Eversign")}`,
      href: "/eversign",
      icon: <CreateIcon />
    }
  ]

  return (
    <Drawer
      anchor="left"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open
        })
      }}
      open={open}
      variant={variant}
    >
      <div className={clsx(classes.root, className)}>
        <SidebarNav opened={open} toggle={toggle} className={classes.nav} pages={pages} />
      </div>
    </Drawer>
  )
}

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
}

const mapStateToProps = (state) => {
  return state
}
export default connect(mapStateToProps)(Sidebar)
