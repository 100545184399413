import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { Button } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { useTranslation } from "react-i18next"
import { SendVaultModal } from "containers/formulaires/vouteDeDocuments/components/sendVaultModal"
import DeleteCandidacy from "containers/jobs/modals/DeleteCandidacy"
import { SimilarResumeModal } from "components/resume/similarResumeModal"
import { TransferModal } from "components/jobs/transferModal"
import { AddTagToCandidateModal } from "components/jobs/addTagToCandidateModal"
import ActionsList from "./components/ActionsList"
import { fetchSovrenMatch } from "../../redux/sovren/superFetch"
import { UpdateContactModal } from "../../components/contacts/updateContactModal"

interface IActionsProps {
  rowData: any
  jobId: string
  currentJob: any
}

export const useStyles = makeStyles((theme) => ({
  root: {
    margin: 20
  }
}))

const useStylesForTag = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    backgroundColor: theme.palette.background.paper
  },
  actions: {
    display: "flex",
    justifyContent: "space-around"
  },
  jcStart: {
    justifyContent: "start"
  }
}))

export const Actions: React.FunctionComponent<IActionsProps> = (props) => {
  const { rowData, jobId, currentJob } = props
  const classesForTag = useStylesForTag()
  const classes = useStyles()
  const { t: translate } = useTranslation()

  const dispatch = useDispatch()

  const [sendVaultModalOpened, setSendVaultModalOpened] = useState(false)
  const [sovrenMatchModalOpened, setSovrenMatchModalOpened] = useState(false)
  const [transferModalOpened, setTransferModalOpened] = useState(false)
  const [deleteModalOpened, setDeleteModalOpened] = useState(false)
  const [addTagToCandidateModalOpened, setAddTagToCandidateModalOpened] = useState(false)
  const [updateContactModalOpened, setUpdateContactModalOpened] = useState(false)

  const openTypeFormUrl = () => {
    window.open(`https://cx205.typeform.com/to/arkICTvx#contact=${rowData.ContactName}&affichage=${rowData.currentJob.jobTitle}`, "_blank")
  }

  useEffect(() => {
    dispatch(fetchSovrenMatch(rowData.Id))
  }, [rowData.Id])

  return (
    <div className={classesForTag.root}>
      <ActionsList rowData={rowData} />

      <div>
        <Button className={classes.root} variant="outlined" color="primary" onClick={() => setSendVaultModalOpened(true)}>
          {translate("Document.Vault.SendVault")}
        </Button>
      </div>

      <div>
        <Button className={classes.root} variant="outlined" color="primary" onClick={() => setSovrenMatchModalOpened(true)}>
          {translate("Resume.FindResume")}
        </Button>
      </div>

      <div>
        <Button className={classes.root} variant="outlined" color="primary" onClick={() => openTypeFormUrl()}>
          {translate("Contact.AlertCandidate")}
        </Button>
      </div>

      <div>
        <Button className={classes.root} variant="outlined" color="primary" onClick={() => setAddTagToCandidateModalOpened(true)}>
          {translate("Job.AddToCandidate")}
        </Button>
      </div>

      <div>
        <Button className={classes.root} variant="outlined" color="primary" onClick={() => setTransferModalOpened(true)}>
          {translate("Job.TransferContact")}
        </Button>
      </div>

      {/* <div>
          <Button className={classes.root} variant="outlined" color="primary" onClick={() => setDeleteModalOpened(true)}>
            {translate("Contact.DeleteThisCandidate")}
          </Button>
        </div> */}

      <div>
        <Button style={{ margin: 20 }} variant="outlined" color="primary" onClick={() => setUpdateContactModalOpened(true)}>
          {translate("Contact.UpdateThisCandidate")}
        </Button>
      </div>

      {sendVaultModalOpened && <SendVaultModal row={rowData} opened={sendVaultModalOpened} toggle={() => setSendVaultModalOpened(false)} />}
      {updateContactModalOpened && (
        <UpdateContactModal row={rowData} opened={updateContactModalOpened} toggle={() => setUpdateContactModalOpened(false)} />
      )}
      {sovrenMatchModalOpened && <SimilarResumeModal opened={sovrenMatchModalOpened} toggle={() => setSovrenMatchModalOpened(false)} />}
      {transferModalOpened && <TransferModal row={rowData} opened={transferModalOpened} toggle={() => setTransferModalOpened(false)} />}
      {addTagToCandidateModalOpened && (
        <AddTagToCandidateModal row={rowData} opened={addTagToCandidateModalOpened} toggle={() => setAddTagToCandidateModalOpened(false)} />
      )}

      {deleteModalOpened && (
        <DeleteCandidacy
          open
          tagsToRemove={rowData?.jobs?.map(({ jobTagId }: { jobTagId: string }) => jobTagId) ?? []}
          contactId={rowData.Id}
          contactName={rowData.ContactName}
          jobId={jobId}
          onClose={() => setDeleteModalOpened(false)}
        />
      )}
    </div>
  )
}
