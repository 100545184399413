import { IFolderDto } from "api/dto"
import { IDocumentDto } from "api/dto/IDocumentDto"
import { IStatsDto } from "api/dto/IStatsDto"
import axios, { AxiosResponse } from "axios"
import environment from "config/env"
import { useTranslation } from "react-i18next"
import { useMutation, useQueryClient } from "react-query"
import { showErrorToast, showSuccessToast } from "utils/toast"

const filesAxiosUploader = axios.create()

export function useMutateAddFolder(contactId: string, jobId: string) {
  const { t: translate } = useTranslation()
  const queryClient = useQueryClient()

  return useMutation<AxiosResponse<IFolderDto>, any, IFolderDto, any>((data) => axios.post(`/contacts/${contactId}/jobs/${jobId}/folders`, data), {
    onSuccess: (res, variables) => {
      queryClient.setQueryData<IFolderDto[]>(`${contactId}-${jobId}-folders`, (old) => {
        if (!old) {
          return []
        }
        const folders = [...old]

        const folderIndex = folders.findIndex((c) => c.name === variables.name)

        if (folderIndex === -1) {
          return folders
        }

        folders[folderIndex] = { ...variables, folderId: res.data.folderId }
        return folders
      })
      showSuccessToast(translate("Folder.AddedFolderSuccess"))
    },
    onError: () => {
      showErrorToast(translate("Folder.AddedFolderFailed"))
    }
  })
}

export function useMutatePutDocumentRequest(contactId: string) {
  const { t: translate } = useTranslation()
  return useMutation<AxiosResponse<string>, any, { path: string; name: string; contentType: string }, any>(
    (data) => axios.put(`/contacts/${contactId}/documents`, data),
    {
      onError: () => {
        showErrorToast(translate("Document.PutRequestFailed"))
      }
    }
  )
}

export function useMutateAddDocument(contactId: string, jobId: string) {
  const { t: translate } = useTranslation()
  const queryClient = useQueryClient()

  return useMutation<AxiosResponse<IDocumentDto>, any, { url: string; file: any }, any>(
    ({ url, file }: { url: string; file: any }) => {
      return filesAxiosUploader.put(url, file, {
        headers: {
          "content-type": file.type
        }
      })
    },
    {
      onSuccess: () => {
        setTimeout(() => {
          queryClient.invalidateQueries(`${contactId}-${jobId}-folders`)
        }, 4000)
      },
      onError: () => {
        showErrorToast(translate("Folder.AddDocumentFailed"))
      }
    }
  )
}

export function useMutateAddUrlDocument(contactId: string, jobId: string) {
  const { t: translate } = useTranslation()
  const queryClient = useQueryClient()

  return useMutation<AxiosResponse<IDocumentDto>, any, any, any>((data) => axios.put(`/contacts/${contactId}/documents`, data), {
    onSuccess: () => {
      queryClient.invalidateQueries(`${contactId}-${jobId}-folders`)
    },
    onError: () => {
      showErrorToast(translate("Folder.AddDocumentFailed"))
    }
  })
}

export function useMutateAddStat() {
  const { t: translate } = useTranslation()
  return useMutation<AxiosResponse<IStatsDto>, any, IStatsDto, any>((data) => axios.post(environment.api.ressourcesUrl.stats, data), {
    onError: (res) => {
      showErrorToast(translate("Statistics.AddStatsFailed"))
    }
  })
}

export function useMutateGenerateToken() {
  return useMutation<AxiosResponse<string>, any, any, any>((data) => axios.post(`${environment.api.ressourcesUrl.tokens}/generate`, data))
}

export function useMutateSendToken() {
  const { t: translate } = useTranslation()
  return useMutation<AxiosResponse<string>, any, any, any>((data) => axios.post(`${environment.api.ressourcesUrl.tokens}/send`, data), {
    onSuccess: (data, variables) => {
      showSuccessToast(translate("Document.EmailSentSuccess").replace("{0}", variables.to))
    }
  })
}

export function useMutateTransferContact(contactId: string, jobId: string) {
  const { t: translate } = useTranslation()
  const queryClient = useQueryClient()
  return useMutation<AxiosResponse<string>, any, any, any>(
    (data) => axios.post(`${environment.api.ressourcesUrl.contacts}/${contactId}/jobs/${jobId}/transfer`, data),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(`contacts-job-${jobId}`)
        showSuccessToast(translate("Job.ContactTransfered"))
      }
    }
  )
}
