import React, { useState } from "react"
import PropTypes from "prop-types"
import { useTheme } from "@material-ui/styles"
import { useMediaQuery } from "@material-ui/core"
import { Sidebar, Topbar } from "./components"
import useStyles from "./styles"

const Main = (props) => {
  const { children } = props
  const classes = useStyles()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"), {
    defaultMatches: true
  })
  const shouldOpenSidebar = !!isDesktop

  const [openSidebar, setOpenSidebar] = useState(shouldOpenSidebar)

  return (
    <div className={classes.root}>
      <Topbar />
      <Sidebar toggle={() => setOpenSidebar(!openSidebar)} open={openSidebar} variant="persistent" />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  )
}

Main.propTypes = {
  children: PropTypes.any.isRequired
}

export default Main
