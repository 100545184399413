import React, { useState, useEffect } from "react"
import { Grid } from "@material-ui/core"
import { useQueryStats } from "api/hooks"
import moment from "moment"
import { StatsTable } from "components/stats/statsTable"
import { useTranslation } from "react-i18next"
import InitialLoading from "../../../components/loading"
import { useContactStatStyles } from "./styles"

interface IContactStatsProps {
  contactId: string
  currentJob: any
}

export const ContactStats: React.FC<IContactStatsProps> = (props) => {
  const { contactId, currentJob } = props
  const classes = useContactStatStyles()
  const { t: translate } = useTranslation()

  const [params, setParams] = useState({
    start: moment().subtract(30, "days").toISOString(),
    contactId,
    jobId: currentJob.jobTagId
  })
  const { data: stats, isLoading: isLoadingStats, refetch } = useQueryStats(params)

  useEffect(() => {
    refetch()
  }, [])

  if (isLoadingStats) {
    return <InitialLoading fullScreen={false} />
  }

  if (!stats || stats.length <= 0) {
    return <span>{translate("Statistics.NoStats")}</span>
  }

  return (
    <Grid className={classes.root} container alignItems="flex-start" spacing={2}>
      <Grid item xs={6} sm={12}>
        <StatsTable stats={stats} isLoadingStats={isLoadingStats} />
      </Grid>
    </Grid>
  )
}
