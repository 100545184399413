import { Grid, List, ListItem, ListItemIcon, ListItemText, Paper, Typography } from "@material-ui/core"
import { IResumeDto, IEducationDto, IEmploymentDto, IInfosDto, ILanguageDto } from "api/dto/IResumeDto"
import isEmpty from "lodash/isEmpty"
import React, { useMemo } from "react"
import DataTable, { IDataTableColumn } from "react-data-table-component"
import Avatar from "@material-ui/core/Avatar"
import SchoolIcon from "@material-ui/icons/School"
import WorkIcon from "@material-ui/icons/Work"
import { useTranslation } from "react-i18next"

export const ResumeTab: React.FunctionComponent<{ data?: any }> = (props) => {
  const { data } = props
  const resume = useMemo(() => data.resume as IResumeDto, [data])

  if (!resume) {
    return <div>No Resume Here</div>
  }

  return (
    <Paper style={{ padding: "20px" }}>
      <Grid container spacing={3}>
        <ContactInfos infos={resume.contactInfo} />
        {resume.experiences && resume.experiences.skills && resume.experiences.skills.length > 0 && <Skills skills={resume.experiences.skills} />}
        {resume.educations && resume.educations.length > 0 && <Educations educations={resume.educations} />}
        {resume.employments && resume.employments.length > 0 && <Employments employments={resume.employments} />}
        {resume.languages && resume.languages.length > 0 && <Languages languages={resume.languages} />}
      </Grid>
    </Paper>
  )
}
const ContactInfos: React.FunctionComponent<{ infos: IInfosDto }> = (props) => {
  const { infos } = props
  const { t: translate } = useTranslation()

  const address = useMemo(() => infos?.ContactMethod?.find?.((t) => t.PostalAddress != null)?.PostalAddress ?? "", [infos])
  const email = useMemo(() => infos?.ContactMethod?.find?.((t) => t.InternetEmailAddress != null)?.InternetEmailAddress ?? "", [infos])
  const phone = useMemo(() => infos?.ContactMethod?.find?.((t) => t.Telephone != null)?.Telephone ?? "", [infos])

  return (
    <Grid container item xs={12}>
      <Grid item xs={12} style={{ fontWeight: 700 }}>
        <Typography variant="h2" component="h3">
          {translate("Contact.Infos")}
        </Typography>
      </Grid>
      <Grid container direction="row" style={{ padding: "10px" }}>
        {infos?.PersonName && (
          <Grid container item xs={12} style={{ marginTop: "20px" }}>
            <Grid item xs={2}>
              <Typography variant="body2" style={{ fontWeight: 700 }} component="span">
                {translate("Contact.Fields.FullName")}
              </Typography>
            </Grid>
            <Grid item xs>
              {[infos.PersonName.GivenName, infos.PersonName.MiddleName, infos.PersonName.FamilyName]
                .filter((n) => n != null && !isEmpty(n))
                .join(" ")}
            </Grid>
          </Grid>
        )}
        {phone && (
          <Grid container item xs={12} style={{ marginTop: "10px" }}>
            <Grid item xs={2}>
              <Typography variant="body2" style={{ fontWeight: 700 }} component="span">
                {translate("Contact.Fields.Phone")}
              </Typography>
            </Grid>
            <Grid item xs>
              {phone.FormattedNumber}
            </Grid>
          </Grid>
        )}
        {email && (
          <Grid container item xs={12} style={{ marginTop: "10px" }}>
            <Grid item xs={2}>
              <Typography variant="body2" style={{ fontWeight: 700 }} component="span">
                {translate("Contact.Fields.Email")}
              </Typography>
            </Grid>
            <Grid item xs>
              {email}
            </Grid>
          </Grid>
        )}
        {address && (
          <Grid container item xs={12} style={{ marginTop: "10px" }}>
            <Grid item xs={2}>
              <Typography variant="body2" style={{ fontWeight: 700 }} component="span">
                {translate("Contact.Fields.Address")}
              </Typography>
            </Grid>
            <Grid item xs>
              {[
                ...(address.DeliveryAddress?.AddressLine || []),
                address.Municipality,
                ...(address.Region || []),
                address.PostalCode,
                address.CountryCode
              ]
                .filter((t) => t != null)
                .join(", ")}
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

const Skills: React.FunctionComponent<{ skills: string[] }> = (props) => {
  const { skills } = props
  const { t: translate } = useTranslation()
  return (
    <Grid container item xs={12}>
      <Grid item xs={12} style={{ fontWeight: 700 }}>
        <Typography variant="h2" component="h3">
          {translate("Contact.Skills")}
        </Typography>
      </Grid>
      <Grid container item xs={12} style={{ marginTop: "20px", padding: "10px" }}>
        {skills.join(", ")}
      </Grid>
    </Grid>
  )
}

const Employments: React.FunctionComponent<{ employments: IEmploymentDto[] }> = (props) => {
  const { employments } = props
  const { t: translate } = useTranslation()
  return (
    <Grid container item xs={12}>
      <Grid item xs={12} style={{ fontWeight: 700 }}>
        <Typography variant="h2" component="h3">
          {translate("Contact.Employments")}
        </Typography>
      </Grid>
      <Grid container item xs={12} style={{ marginTop: "20px" }}>
        <Grid item xs={12} md={6}>
          <div>
            <List dense>
              {employments.map((e) => (
                // eslint-disable-next-line react/jsx-key
                <ListItem key={e.PositionHistory?.length > 0 ? e.PositionHistory[0].Description : ""}>
                  <ListItemIcon>
                    <Avatar>
                      <WorkIcon />
                    </Avatar>
                  </ListItemIcon>
                  <ListItemText primary={e.EmployerOrgName} secondary={e.PositionHistory?.length > 0 ? e.PositionHistory[0].Description : null} />
                </ListItem>
              ))}
            </List>
          </div>
        </Grid>
      </Grid>
    </Grid>
  )
}

const Educations: React.FunctionComponent<{ educations: IEducationDto[] }> = (props) => {
  const { educations } = props
  const { t: translate } = useTranslation()
  return (
    <Grid container item xs={12}>
      <Grid item xs={12} style={{ fontWeight: 700 }}>
        <Typography variant="h2" component="h3">
          {translate("Contact.Educations")}
        </Typography>
      </Grid>
      <Grid container item xs={12} style={{ marginTop: "20px" }}>
        <Grid item xs={12} md={6}>
          <div>
            <List dense>
              {educations.map((e) => (
                // eslint-disable-next-line react/jsx-key

                <ListItem key={`education-${e.Degree[0].Comments}`}>
                  <ListItemIcon>
                    <Avatar>
                      <SchoolIcon />
                    </Avatar>
                  </ListItemIcon>
                  <ListItemText
                    primary={e.DegreeMajor && <div>{e.DegreeMajor.map((d) => (d.Name || []).join(", "))}</div>}
                    secondary={e.School && e.School.map((s) => s.SchoolName).join(", ")}
                  />
                </ListItem>
              ))}
            </List>
          </div>
        </Grid>
      </Grid>
    </Grid>
  )
}

const Languages: React.FunctionComponent<{ languages: ILanguageDto[] }> = (props) => {
  const { languages } = props
  const { t: translate } = useTranslation()

  const columns = useMemo(
    () => [
      {
        name: translate("Contact.Fields.LanguageCode"),
        width: "20%",
        selector: "LanguageCode"
      } as IDataTableColumn,
      {
        name: translate("Contact.Fields.Speak"),
        selector: "Speak",
        width: "15%",
        cell: (data) => (
          <div>
            <input readOnly type="checkbox" checked={data.Speak === "true"} />
          </div>
        )
      } as IDataTableColumn,
      {
        name: translate("Contact.Fields.Read"),
        selector: "Read",
        width: "15%",
        cell: (data) => (
          <div>
            <input readOnly type="checkbox" checked={data.Read === "true"} />
          </div>
        )
      } as IDataTableColumn,
      {
        name: translate("Contact.Fields.Write"),
        selector: "Write",
        width: "15%",
        cell: (data) => (
          <div>
            <input readOnly type="checkbox" checked={data.Write === "true"} />
          </div>
        )
      } as IDataTableColumn,
      {
        name: translate("Contact.Fields.Comments"),
        selector: "Comments",
        allowOverflow: true,
        wrap: true
      } as IDataTableColumn
    ],
    []
  )

  return (
    <Grid container item xs={12}>
      <Grid item xs={12} style={{ fontWeight: 700 }}>
        <Typography variant="h2" component="h3">
          {translate("Contact.Languages")}
        </Typography>
      </Grid>
      <Grid container item xs={12} style={{ marginTop: "20px", padding: "10px" }}>
        <Grid container item xs={12} direction="column">
          <div>
            <DataTable columns={columns} data={languages} persistTableHead noHeader />
          </div>
        </Grid>
      </Grid>
    </Grid>
  )
}
