import { take, call, put } from "redux-saga/effects"
import * as AWSCognito from "amazon-cognito-identity-js"
import * as Sentry from "@sentry/browser"
import {
  fetchLoginState,
  failFetchingLoginState,
  fetchUser,
  failFetchingUser,
  login,
  clickLogout,
  logout,
  authUser,
  fetchAuth,
  failFetchingApi
} from "../actions/auth"
import superFetch from "../modules/superFetch"
import { userPool } from "../../utils/axios"

export const getSession = () =>
  new Promise((resolve) => {
    const cognitoUser = userPool.getCurrentUser()
    cognitoUser.getSession((err, result) => {
      if (result) {
        cognitoUser.getUserAttributes((error, attrs) => {
          if (error) {
            resolve({ payload: null, err: error })
          } else {
            const payload = {}
            payload.user = {}
            attrs.forEach((attr) => {
              payload.user[attr.Name] = attr.Value
            })
            Sentry.setUser({
              email: payload.user.email,
              username: [payload.user.given_name, payload.user.family_name].filter((t) => t != null).join(" ")
            })
            payload.jwt = result.getIdToken().getJwtToken()
            resolve({ payload })
          }
        })
      } else {
        resolve({ payload: null, err })
      }
    })
  })

export const cognitoSignIn = (params) =>
  new Promise((resolve) => {
    const { email, password } = params
    const authenticationDetails = new AWSCognito.AuthenticationDetails({
      Username: email,
      Password: password
    })

    const cognitoUser = new AWSCognito.CognitoUser({
      Username: email,
      Pool: userPool
    })

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {
        cognitoUser.getUserAttributes((err, attrs) => {
          const payload = {}
          attrs.forEach((attr) => {
            payload[attr.Name] = attr.Value
          })
          payload.jwt = result.getIdToken().getJwtToken()
          resolve({ payload })
        })
      },
      onFailure: (err) => {
        resolve({ payload: null, err })
      }
    })
  })

const getAccessToken = (cognitoUser) =>
  new Promise((resolve) => {
    cognitoUser.getSession((err, result) => {
      if (result) {
        const token = result.getAccessToken().getJwtToken()
        resolve({ token })
      } else {
        resolve({ token: null, err })
      }
    })
  })

const globalSignOut = (cognitoUser) =>
  new Promise((resolve) => {
    cognitoUser.globalSignOut({
      onSuccess: (result) => {
        resolve({ result })
      },
      onFailure: (err) => {
        resolve({ result: null, err })
      }
    })
  })

export function* handleFetchLoginState() {
  while (true) {
    const action = yield take(`${fetchLoginState}`)

    const cognitoUser = userPool.getCurrentUser()

    if (cognitoUser) {
      const { payload, err } = yield call(getSession, cognitoUser)

      if (payload && !err) {
        yield put(login({ ...payload, ...action.payload }))
      }
      yield put(failFetchingLoginState(action.payload))
    }
    yield put(failFetchingLoginState(""))
  }
}

export function* handleLogout() {
  while (true) {
    yield take(`${clickLogout}`)

    const cognitoUser = userPool.getCurrentUser()

    if (cognitoUser) {
      const { token, err } = yield call(getAccessToken, cognitoUser)

      if (token && !err) {
        const { result, err: error } = yield call(globalSignOut, cognitoUser)
        if (result && !error) {
          yield put(logout())
        }
      }
    }
  }
}

export function* handleLogin() {
  while (true) {
    const action = yield take(`${fetchUser}`)
    const { email, password } = action.payload

    if (email && password) {
      const { payload, err } = yield call(cognitoSignIn, action.payload)

      if (!payload && err) {
        yield put(failFetchingUser(`${err.statusCode}: ${err.message}`))
      }

      yield put(login(payload))
    }
    // todo TRADUCTION
    yield put(failFetchingUser("Please set email and password"))
  }
}

export function* handleApi() {
  while (true) {
    const action = yield take(`${authUser}`)

    const { payload, err } = yield call(superFetch, {
      url: action.payload.path,
      type: "POST",
      custom: {
        mode: "cors",
        headers: {
          Authorization: `${action.payload.jwt}`
        }
      }
    })

    if (payload && !err) {
      yield put(fetchAuth(payload))
    }
    yield put(failFetchingApi(err))
  }
}
