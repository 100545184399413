import React from "react"
import { Link } from "react-router-dom"
import MaterialTable from "material-table"
import "moment/locale/fr-ca"
import { useQueryJobs } from "api/hooks"
import { useSelector } from "react-redux"
import InitialLoading from "../../../components/loading"
import { PatchedPagination } from "./tablePagination"

export const Jobs: React.FC = () => {
  const mui = useSelector((state) => (state as any).mui)
  const { localization } = mui
  const { data: jobsList, isLoading: isLoadingJobs, isError: hasErrorJobs } = useQueryJobs()
  if (isLoadingJobs) {
    return <InitialLoading fullScreen={false} content />
  }

  if (hasErrorJobs) {
    return <span className="error">An error occured</span>
  }

  return (
    <div
      style={{
        opacity: `${!isLoadingJobs ? "1" : ".5"}`,
        transition: ".2s opacity ease-in-out"
      }}
    >
      <MaterialTable
        title={
          <div style={{ display: "flex" }}>
            <h2>Liste des affichages actifs</h2>
          </div>
        }
        columns={[
          {
            title: "Affichages",
            field: "jobTitle",
            render: (rowData) => {
              const { jobTagId, jobTitle } = rowData
              return <Link to={`/jobs/${jobTagId}`}>{jobTitle}</Link>
            },
            cellStyle: {
              width: "75%"
            },
            headerStyle: {
              width: "75%"
            }
          },
          {
            title: "Tag",
            field: "jobTagId",
            cellStyle: {
              width: "25%",
              textAlign: "right",
              paddingRight: "3em"
            },
            headerStyle: {
              width: "25%",
              textAlign: "right"
            }
          }
        ]}
        data={jobsList || []}
        options={{
          filtering: false,
          grouping: false,
          padding: "dense",
          paging: true,
          pageSize: (jobsList as any).length < 10 ? 10 : 20,
          selection: false,
          sorting: true
        }}
        components={{
          Pagination: PatchedPagination
        }}
        localization={localization}
      />
    </div>
  )
}
