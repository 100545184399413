import { Button, MenuItem, TextField } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import { useQueryTags } from "api/hooks"
import TuneIcon from "@material-ui/icons/TuneOutlined"
import BackspaceIcon from "@material-ui/icons/BackspaceOutlined"
import LabelledOutline from "components/outlined/labelledOutline"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { statusMap } from "utils/status"
import { nanoid } from "nanoid"
import { SearchModal } from "./searchModal"
import { QueryType, ISearch } from "./ISearch"

interface ISearchBoxProps {
  search: () => void

  contactQuery?: string
  setContactQuery: (query: string) => void

  jobQuery?: string
  setJobQuery: (query: string) => void

  jobStatus: any
  setJobStatus: (jobStatus: string) => void

  currentTags: any
  setTags: (tags: any[]) => void
}

export const SearchBox: React.FC<ISearchBoxProps> = (props) => {
  const { search, contactQuery, setContactQuery, jobQuery, setJobQuery, jobStatus, currentTags, setTags, setJobStatus } = props
  const { data: tags } = useQueryTags()
  const [queryType, setQueryType] = useState(QueryType.contact)
  const [open, setOpen] = useState(false)
  const { t: translate } = useTranslation()
  const [searchJobQuery, setSearchJobQuery] = useState<ISearch>([{ id: nanoid(), parameters: [] }])
  const [searchContactQuery, setSearchContactQuery] = useState<ISearch>([{ id: nanoid(), parameters: [] }])

  return (
    <div className="search-box">
      <div className="search">
        <LabelledOutline className="search-fields" label={translate("Search.ContactFields")}>
          <div style={{ display: "flex" }}>
            <Autocomplete
              multiple
              options={tags || []}
              size="small"
              className="ByTagsSearch"
              style={{ width: 300, marginRight: "20px" }}
              value={currentTags || []}
              getOptionLabel={(option) => option.name}
              getOptionSelected={(option, v) => option.name === v.name}
              renderInput={(params) => <TextField {...params} label={translate("Search.ByTags")} variant="outlined" />}
              onChange={(e, newValues) => setTags(newValues)}
              limitTags={2}
            />
            <TextField
              id="terms-textfield"
              variant="outlined"
              size="small"
              style={{ width: 400, paddingRight: "20px" }}
              placeholder={translate("Search.StartSearch")}
              value={contactQuery}
              onChange={(e) => setContactQuery(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  search()
                }
              }}
            />
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Button
              size="small"
              id="clear-contact-search"
              color="secondary"
              variant="outlined"
              onClick={() => {
                setContactQuery("")
                setTags([])
                setSearchContactQuery([{ id: nanoid(), parameters: [] }])
              }}
            >
              <BackspaceIcon />
            </Button>
            <Button
              size="small"
              id="search-advanced-contact"
              color="default"
              variant="outlined"
              style={{ marginRight: 10 }}
              onClick={() => {
                setQueryType(QueryType.contact)
                setOpen(true)
              }}
            >
              <TuneIcon />
            </Button>
          </div>
        </LabelledOutline>
        <LabelledOutline className="search-fields" label={translate("Search.JobFields")}>
          <div>
            <TextField
              size="small"
              id="status-list"
              label={translate("Search.ByStatus")}
              variant="outlined"
              style={{ width: 300, marginRight: 20 }}
              select
              SelectProps={{
                value: jobStatus,
                onChange: (e: any) => setJobStatus(e.target.value),
                multiline: true
              }}
            >
              <MenuItem value="none">{translate("Search.JobStatus")}</MenuItem>
              {Object.entries(statusMap).map(([key, value]) => (
                <MenuItem key={key} id={`terms-${value.replaceAll(" ", "-").toLowerCase()}`} value={key}>
                  {value}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              variant="outlined"
              size="small"
              placeholder={translate("Search.StartSearch")}
              id="terms-textfield"
              style={{ width: 400, paddingRight: "20px" } as any}
              value={jobQuery}
              onChange={(e) => setJobQuery(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  search()
                }
              }}
            />
          </div>
          <div>
            <Button
              size="small"
              id="clear-job-search"
              color="secondary"
              variant="outlined"
              onClick={() => {
                setJobQuery("")
                setJobStatus("none")
                setSearchJobQuery([{ id: nanoid(), parameters: [] }])
              }}
            >
              <BackspaceIcon />
            </Button>
            <Button
              size="small"
              id="search-advanced-job"
              color="default"
              style={{ marginRight: 10 }}
              variant="outlined"
              onClick={() => {
                setQueryType(QueryType.job)
                setOpen(true)
              }}
            >
              <TuneIcon />
            </Button>
          </div>
        </LabelledOutline>

        <div />
        <div style={{ display: "flex" }}>
          <Button id="search-button" color="primary" variant="outlined" onClick={() => search()}>
            {translate("Common.Search")}
          </Button>
        </div>
      </div>
      {open && (
        <SearchModal
          open={open}
          search={queryType === QueryType.contact ? searchContactQuery : searchJobQuery}
          setSearch={queryType === QueryType.contact ? setSearchContactQuery : setSearchJobQuery}
          toggle={() => {
            setOpen(false)
          }}
          queryType={queryType}
          setSearchString={queryType === QueryType.contact ? setContactQuery : setJobQuery}
        />
      )}
    </div>
  )
}
