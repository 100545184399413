import React, { useCallback, useEffect, useState } from "react"
import SearchIcon from "@material-ui/icons/Search"
import axios from "axios"
import debounce from "lodash/debounce"
import { TextField } from "@material-ui/core"
import InputAdornment from "@material-ui/core/InputAdornment"
import { useTranslation } from "react-i18next"
import { useStyles } from "../styles"
import { ContactDetailsModal } from "../../../../../containers/contacts/contactDetailsModal"

export const SearchBox: React.FC = () => {
  const classes = useStyles()
  const [results, setResults] = useState([])
  const [query, setQuery] = React.useState(null)
  const [modalVisible, setModalVisible] = React.useState(true)
  const [isSearching, setIsSearching] = useState(false)
  const [isFocused, setIsFocused] = useState(false)
  const [selectedContact, setSelectedContact] = useState(null)
  const { t: translate } = useTranslation()

  const debouncedFetchData = useCallback(
    debounce(async (q) => {
      setQuery(q)
      if (q !== null) {
        setIsSearching(true)
        const { data } = await axios.get(`/contacts?q=${q}`)
        setResults(data.contacts)
        setIsSearching(false)
      }
    }, 500),
    []
  )

  useEffect(() => {
    setModalVisible(selectedContact !== null)
  }, [selectedContact])

  const onBlur = () => {
    setTimeout(() => {
      setIsFocused(false)
    }, 500)
  }

  const onFocus = () => {
    setIsFocused(true)
  }

  return (
    <div className={classes.SearchWrapper}>
      <div className={classes.Search}>
        <TextField
          className={classes.SearchTextField}
          size="medium"
          InputProps={{
            "aria-label": "search",
            placeholder: translate("Search.GlobalSearch"),
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            style: {
              padding: ".5em"
            }
          }}
          onChange={(e: any) => debouncedFetchData(e?.target?.value)}
          onBlur={onBlur}
          onFocus={onFocus}
        />
      </div>
      {isFocused && (
        <div className={classes.SearchResultsWrapper}>
          {isSearching && (
            <ul>
              <li className={classes.searchItem}>Recherche en cours...</li>
            </ul>
          )}
          {!isSearching && results.length === 0 && (
            <ul>
              <li className={classes.searchItem}>Aucun résultat</li>
            </ul>
          )}
          {!isSearching && results.length > 0 && (
            <ul>
              {results.map((res: any) => (
                <li className={classes.searchItem} key={`${res.emails[0]}${res.lastName}${res.firstName}`} onClick={() => setSelectedContact(res)}>
                  <span className={classes.displayBlock}>
                    {res.firstName} {res.lastName}
                  </span>
                  {res.emails[0] ? <span className={classes.displayBlock}>{res.emails[0]}</span> : ""}
                  {res.phoneNumbers[0] ? <span className={classes.displayBlock}>{res.phoneNumbers[0]}</span> : ""}
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
      {selectedContact && <ContactDetailsModal row={selectedContact} opened={modalVisible} toggle={() => setSelectedContact(null)} />}
    </div>
  )
}
