import { Button, Dialog, DialogContent, DialogTitle, InputLabel, TextField } from "@material-ui/core"
import { useMutateAddFolder, useMutateAddUrlDocument } from "api/hooks/add"
import CloseIcon from "@material-ui/icons/Close"
import { FormProvider, useForm } from "react-hook-form"
import React from "react"
import { useTranslation } from "react-i18next"
import "./addUrlModal.scss"

interface IAddUrlProps {
  opened: boolean
  toggle: () => void
  folderId?: string
  contactId: string
  jobTagId: string
  folderName?: string
}

export const AddUrl: React.FunctionComponent<IAddUrlProps> = (props) => {
  const { opened, toggle, contactId, folderName, folderId, jobTagId } = props
  const { t: translate } = useTranslation()
  const methods = useForm({ reValidateMode: "onChange" })
  const {
    handleSubmit,
    register,
    formState: { errors }
  } = methods

  const { mutateAsync: addFolder } = useMutateAddFolder(contactId, jobTagId)
  const { mutateAsync: addUrl } = useMutateAddUrlDocument(contactId, jobTagId)

  const onSubmit = async ({ url, name }: { url: string; name: string }) => {
    let currentFolderId = folderId
    if (!currentFolderId) {
      const { data: createdFolder } = await addFolder({ name: folderName })
      currentFolderId = createdFolder.folderId!
    }

    await addUrl({
      contactId,
      jobId: jobTagId,
      path: folderName,
      url,
      name
    })

    toggle()
  }

  return (
    <FormProvider {...methods}>
      <Dialog className="add-url-modal" fullWidth open={opened}>
        <DialogTitle id="max-width-dialog-title">
          <div className="title-section">
            <div>{translate("Document.AddUrl")}</div>
            <div className="description">{translate("Document.AddUrlDescription")}</div>
          </div>
          <div className="close-button">
            <CloseIcon style={{ cursor: "pointer" }} onClick={toggle} />
          </div>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="content">
              <div className="form-input">
                <InputLabel className="input-label" htmlFor="url">
                  {translate("Document.Url")}
                </InputLabel>
                <TextField
                  className={errors?.url ? "is-invalid" : ""}
                  {...register("url", { required: true })}
                  fullWidth
                  type="url"
                  variant="outlined"
                />
              </div>
              <div className="form-input">
                <InputLabel className="input-label" htmlFor="url">
                  {translate("Document.UrlName")}
                </InputLabel>
                <TextField
                  className={errors?.name ? "is-invalid" : ""}
                  {...register("name", { required: true })}
                  fullWidth
                  type="text"
                  variant="outlined"
                />
              </div>
            </div>
            <div className="modal-footer">
              <Button color="primary" variant="text" onClick={toggle}>
                {translate("Common.Cancel")}
              </Button>
              <Button color="secondary" variant="text" type="submit">
                {translate("Common.Add")}
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </FormProvider>
  )
}
