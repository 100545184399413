import moment from "moment"
import { createContext, useCallback, useContext, useEffect } from "react"
import { useForm, useFormState } from "react-final-form"

const prequalContext = createContext<ReturnType<typeof useProvidePrequalif>>({} as any)

export function ProvidePrequalif({ children }: { children: any }) {
  const prequal = useProvidePrequalif()
  return <prequalContext.Provider value={prequal}>{children}</prequalContext.Provider>
}

export const usePrequalif = () => useContext(prequalContext)

const useProvidePrequalif = () => {
  const { getState, submit } = useForm()
  const state = useFormState()
  const { dirty } = state

  const savePrequalLocalStorage = useCallback(() => {
    const { values } = getState()
    if (values) {
      const prequalString = localStorage.getItem(`prequalif-${values.Id}-${values.currentJob.jobTagId}`)

      let prequal = { [moment().format("YYYYMMDDhhmm")]: values.currentJob.prequalification }
      if (prequalString != null) {
        const old = JSON.parse(prequalString)
        prequal = { ...old, ...prequal }
      }

      localStorage.setItem(`prequalif-${values.Id}-${values.currentJob.jobTagId}`, JSON.stringify(prequal))
    }
  }, [getState])

  const saveJob = useCallback(() => {
    const { values } = getState()
    if (values && dirty) {
      savePrequalLocalStorage()
      submit()
    }
  }, [getState, submit, dirty])

  useEffect(() => {
    savePrequalLocalStorage()
  }, [])

  return {
    saveJob
  }
}
