export const SET_MUI_OPTIONS_PAGESIZE = "SET_MUI_OPTIONS_PAGESIZE"
export const SET_MUI_OPTIONS_SORTING = "SET_MUI_OPTIONS_SORTING"
export const SET_MUI_OPTIONS_SELECTION = "SET_MUI_OPTIONS_SELECTION"
export const SET_MUI_OPTIONS_GROUPING = "SET_MUI_OPTIONS_GROUPING"
export const SET_MUI_OPTIONS_PADDING = "SET_MUI_OPTIONS_PADDING"
export const SET_MUI_OPTIONS_FILTERING = "SET_MUI_OPTIONS_FILTERING"
export const SET_MUI_OPTIONS_HIDDEN = "SET_MUI_OPTIONS_HIDDEN"
export const SET_MUI_OPTIONS_DRAGGABLE = "SET_MUI_OPTIONS_DRAGGABLE"

export const setMuiOptionsPageSize = (action) => ({
  type: SET_MUI_OPTIONS_PAGESIZE,
  json: action
})

export const setMuiOptionsSorting = (action) => ({
  type: SET_MUI_OPTIONS_SORTING,
  json: action
})

export const setMuiOptionsSelection = (action) => ({
  type: SET_MUI_OPTIONS_SELECTION,
  json: action
})

export const setMuiOptionsGrouping = (action) => ({
  type: SET_MUI_OPTIONS_GROUPING,
  json: action
})

export const setMuiOptionsPadding = (action) => ({
  type: SET_MUI_OPTIONS_PADDING,
  json: action
})

export const setMuiOptionsFiltering = (action) => ({
  type: SET_MUI_OPTIONS_FILTERING,
  json: action
})

export const setMuiOptionsHidden = (action) => ({
  type: SET_MUI_OPTIONS_HIDDEN,
  json: action
})

export const setMuiOptionsDraggable = (action) => ({
  type: SET_MUI_OPTIONS_DRAGGABLE,
  json: action
})
