import InitialLoading from "components/loading"
import { Modal } from "components/modal/modal"
import React from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

interface ISimilarResumeModalProps {
  opened: boolean
  toggle: () => void
}

export const SimilarResumeModal: React.FunctionComponent<ISimilarResumeModalProps> = (props) => {
  const sovren = useSelector((state: any) => state.sovren)
  const { opened, toggle } = props
  const { t: translate } = useTranslation()
  return (
    <Modal maxWidth="lg" fullWidth open={opened} close={toggle} title={translate("Resume.SimilarResume")}>
      {sovren.sovrenMatch.url ? (
        <iframe style={{ height: "100vh", width: "100%" }} title="aicv CV Match" src={`${sovren.sovrenMatch.url}`} width="98%" height="90%" />
      ) : (
        <InitialLoading />
      )}
    </Modal>
  )
}
