import React from "react"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"
import { Grid, TextField } from "@material-ui/core"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { IJobDto } from "api/dto/IJobDto"
import { useTranslation } from "react-i18next"

export interface IJobPickerProps {
  jobsList: IJobDto[]
  setJobId: (jobId?: string) => void
}

export const JobPicker: React.FC<IJobPickerProps> = (props) => {
  const { jobsList, setJobId } = props
  const { t: translate } = useTranslation()

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid item xs={12}>
        <Autocomplete
          id="combo-job-list"
          options={jobsList}
          onChange={(e, value) => setJobId(value?.jobTagId)}
          getOptionLabel={(option: any) => option.jobTitle}
          renderInput={(params) => <TextField {...params} label={translate("Statistics.JobListing")} variant="outlined" />}
        />
      </Grid>
    </MuiPickersUtilsProvider>
  )
}
