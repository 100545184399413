import React from "react"
import "moment/locale/fr-ca"
import InitialLoading from "components/loading"

interface ISovrenProps {
  sovren: any
  isLoading: boolean
}

export const Sovren: React.FC<ISovrenProps> = (props) => {
  const { sovren, isLoading } = props

  if (isLoading) {
    return <InitialLoading fullScreen={false} content />
  }

  return <iframe title="AI CV Parser" src={`${sovren.url}`} width="100%" height="100%" />
}
