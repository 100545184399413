import { IContactJobDto } from "api/dto/IContactJobDto"
import useNavigation from "hooks/useNavigation"
import { useMemo } from "react"

export const useContactJobs = (jobs?: IContactJobDto[]) => {
  const { query } = useNavigation()
  const currentJobId = useMemo(() => (query as any).jobId, [query])

  const currentJob = useMemo(() => jobs?.find((j) => j.jobTagId === currentJobId), [jobs])

  const currentJobIndex = useMemo(() => jobs?.findIndex((j) => j.jobTagId === currentJobId), [jobs])

  return {
    currentJob,
    currentJobId,
    currentJobIndex
  }
}
