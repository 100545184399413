export const DefaultFolders = [
  {
    name: "Formulaire d'inscription",
    documents: []
  },
  {
    name: "Autre version CV - Lettre de présentation",
    documents: []
  },
  {
    name: "Diplômes et certificats",
    documents: []
  },
  {
    name: "Plumitif",
    documents: []
  },
  {
    name: "Pièces d’identité",
    documents: []
  },
  {
    name: "Permis de travail - Visa",
    documents: []
  },
  {
    name: "Contrôle de références",

    documents: []
  },
  {
    name: "Tests",
    documents: []
  },
  {
    name: "MPO",
    documents: []
  },
  {
    name: "Vérification réputation web",
    documents: []
  },
  {
    name: "Validation crédit",
    documents: []
  },
  {
    name: "Questionnaire d'entrevue",
    documents: []
  },
  {
    name: "Documents d'embauche",
    documents: []
  },
  {
    name: "Autres documents",
    documents: []
  }
]

export const FilteredOutFolders = [
  "Plumitif",
  "Contrôle de références",
  "Tests",
  "MPO",
  "Vérification Google",
  "Validation crédit",
  "Questionnaire d'entrevue"
]
