/* eslint-disable import/namespace */
import React from "react"
import { Switch, Route, Redirect } from "react-router"
import { setRequestInterceptor } from "redux/utils/axios"
import MainLayout from "layouts/Main"
import { ContactsRouter, Settings, SovrenSearchList } from "containers"
import Dashboard from "./dashboard"
import { JobSwitcher } from "./jobs/JobsList/jobSwitcher"
import { GlobalStats } from "./stats/global/globalStats"
import { SocialMediaManagement } from "./socialMediaManagement/SocialMediaManagement"
import { ImportantLink } from "./importantLink/ImportantLink"
import { Eversign } from "./eversign/Eversign"

setRequestInterceptor()

export const MainApp: React.FC = () => {
  return (
    <MainLayout>
      <Switch>
        <Route path="/dashboard" exact component={Dashboard} />
        <Route path="/contacts" exact component={ContactsRouter} />
        <Route path="/jobs" component={JobSwitcher} />
        <Route path="/search" exact component={SovrenSearchList} />
        <Route path="/stats" exact component={GlobalStats} />
        <Route path="/socialmediamanagement" component={SocialMediaManagement} />
        <Route path="/importantlinks" component={ImportantLink} />
        <Route path="/eversign" component={Eversign} />

        <Route path="/settings" exact component={Settings} />
        <Redirect to={{ pathname: "/jobs" }} />
      </Switch>
    </MainLayout>
  )
}
