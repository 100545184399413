import React, { useState, useEffect, useCallback } from "react"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import { Button } from "@material-ui/core"
import Autocomplete from "@material-ui/lab/Autocomplete"
import TextField from "@material-ui/core/TextField"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import Dialog from "@material-ui/core/Dialog"
import clsx from "clsx"
import FormControl from "@material-ui/core/FormControl"
import CloseIcon from "@material-ui/icons/Close"
import isEmpty from "lodash/isEmpty"
import { useQueryJobs, useQueryTags } from "api/hooks"
import { TagCategory } from "api/dto/ITagDto"
import { showErrorToast } from "utils/toast"
import { useTranslation } from "react-i18next"
import { FetchResumeDatas, PostCvInfo as postCvInfo } from "../../redux/cv/superFetch"
import InitialLoading from "../loading"
import { useStyles } from "./styles"
import { ClientIdCompany, CvOriginList } from "./subComponent/Object"
import HorizontalLabelPositionBelowStepper from "./subComponent/StepDot"
import DropZone from "./subComponent/DropZone"
import ConfirmationStep from "./subComponent/ConfirmationStep/ConfirmationStep"

const AddCvBox = ({
  cv,
  auth,
  postIDFromButton,
  clientIDFromButton,
  handleClose,
  step,
  setStep,
  realStep,
  setRealStep,
  postNameFromButton,
  open,
  setOpen,
  getResumeData
}) => {
  const { data: jobsList, isLoading: isLoadingJobs } = useQueryJobs()
  const [isSend, setIsSend] = useState("")
  const { data: tags, isLoading: isLoadingTags } = useQueryTags()
  const { t: translate } = useTranslation()
  const [cvOrigin, setCvOrigin] = useState("Page Carrière")
  const [jobId, setJobId] = useState(postIDFromButton)
  const [jobName, setJobName] = useState(postNameFromButton)
  const [clientID, setClientID] = useState(clientIDFromButton === "" ? "" : clientIDFromButton)
  const [query, setQuery] = useState("idle")
  const classes = useStyles()
  const [fullWidth] = React.useState(true)
  const [maxWidth] = React.useState("lg")
  const [applicationData, setApplicationData] = React.useState({
    gender: "",
    givenName: "",
    familyName: "",
    email: "",
    phone: "",
    address: {
      postalCode: "",
      city: "",
      line1: "",
      line2: ""
    },
    formationTags: [],
    languageTags: [],
    experienceTags: [],
    cvOrigin,
    nonSolicitation: false
  })

  useEffect(() => {
    if (jobId && jobsList) {
      const index = jobsList.findIndex((x) => x.jobTagId === jobId)
      setJobName(jobsList[index]?.jobTitle)
    }
  }, [jobId, jobsList])

  const handleChange = () => {
    setStep({ id: 2 })
  }

  const setClientIdFunction = (newInputValue) => {
    const ClientIDValue = newInputValue.toString().split(" -")[0]
    setClientID(ClientIDValue)
  }

  const setJobNameForPush = (newInputValue) => {
    if (!newInputValue) {
      const index = jobsList.findIndex((x) => x.jobTagId === jobId)
      setJobName(jobsList[index].jobTitle)
    } else setJobName(newInputValue)
  }

  const findIdOfJobs = useCallback(() => {
    const tag = jobsList?.find((t) => t.jobTitle === jobName)
    if (tag) {
      setJobId(tag.jobTagId)
    }
  }, [jobsList, jobName])

  const handleChangeClientID = () => {
    setStep({ id: 1 })
    if (jobId) {
      setStep({ id: 2 })
    }
  }
  const fetchResumeId = () => {
    setRealStep(1)
  }

  const normalizeApplicationData = async () => {
    const editApplicationDataObject = applicationData
    let experienceTagsArray = editApplicationDataObject.experienceTags
    let formationTagsArray = editApplicationDataObject.formationTags
    let languageTagsArray = editApplicationDataObject.languageTags
    if (typeof editApplicationDataObject.formationTags[0] === "object" || !editApplicationDataObject.formationTags.length === 0) {
      formationTagsArray = editApplicationDataObject.formationTags.map((key) => {
        return key.tag
      })
    }

    if (typeof editApplicationDataObject.languageTags === "object" || !editApplicationDataObject.languageTags.length === 0) {
      languageTagsArray = editApplicationDataObject.languageTags.map((key) => {
        return key.tag
      })
    }
    if (typeof editApplicationDataObject.languageTags === "string" || editApplicationDataObject.languageTags instanceof String) {
      languageTagsArray = editApplicationDataObject.languageTags.split(", ")
    }
    if (typeof editApplicationDataObject.experienceTags[0] === "object" || !editApplicationDataObject.formationTags.length === 0) {
      experienceTagsArray = editApplicationDataObject.experienceTags.map((key) => {
        return key.tag
      })
    }

    editApplicationDataObject.formationTags = formationTagsArray
    editApplicationDataObject.languageTags = languageTagsArray
    editApplicationDataObject.experienceTags = experienceTagsArray

    await postInfo.sendContactApplication(jobId, clientID, cv.resumeDatas.resumeToken, editApplicationDataObject, setIsSend, isSend)
  }

  const normalizeResumeData = (resumeInfos) => {
    const { formationTags } = resumeInfos
    const { languageTags } = resumeInfos
    const { experienceTags } = resumeInfos
    let { address } = resumeInfos
    if (address) {
      if (address.postalCode === undefined) address.postalCode = ""
      if (address.city === undefined) address.city = ""
      if (address.line1 === undefined) address.line1 = ""
      if (address.line2 === undefined) address.line2 = ""
    } else {
      address = {
        postalCode: "",
        city: "",
        line1: "",
        line2: ""
      }
    }
    // eslint-disable-next-line no-shadow
    const normalizeResumeData = {
      gender: resumeInfos.gender ? resumeInfos.gender : "",
      givenName: resumeInfos.givenName ? resumeInfos.givenName : "",
      familyName: resumeInfos.familyName ? resumeInfos.familyName : "",
      address,
      email: resumeInfos.email ? resumeInfos.email : "",
      phone: resumeInfos.phone ? resumeInfos.phone : "",
      formationTags: resumeInfos.formationTags
        ? Object.keys(formationTags).length === 0 && formationTags.constructor === Object
          ? []
          : getNormalizedTagList(TagCategory.formation, formationTags)
        : [],

      languageTags: resumeInfos.languageTags
        ? Object.keys(languageTags).length === 0 && languageTags.constructor === Object
          ? []
          : getNormalizedTagList(TagCategory.language, languageTags)
        : [],
      experienceTags: resumeInfos.experienceTags
        ? Object.keys(experienceTags).length === 0 && experienceTags.constructor === Object
          ? []
          : getNormalizedTagList(TagCategory.experience, experienceTags)
        : [],
      cvOrigin,
      nonSolicitation: resumeInfos.nonSolicitation ? resumeInfos.nonSolicitation : false
    }

    setApplicationData(normalizeResumeData)
  }

  function getNormalizedTagList(category, tagList) {
    const currentTagList = []
    Object.entries(tagList).forEach(([k, v]) => currentTagList.push({ id: k, name: v }))
    return (currentTagList || tags).filter((t) => t.category === category).map((t) => ({ tag: t.id, label: t.name }))
  }

  const afterDropZone = () => {
    if (!isEmpty(cv.resumeDatas)) {
      normalizeResumeData(cv.resumeDatas)
      setRealStep(realStep + 1)
    }
  }

  const NoCv = () => {
    normalizeResumeData(cv.resumeDatas)
    fetchResumeId()

    setRealStep(realStep + 1)
  }

  useEffect(() => {
    if (isEmpty(jobName) || isLoadingTags) {
      return
    }
    findIdOfJobs()
  }, [findIdOfJobs, jobName, isLoadingTags])

  return (
    <Dialog fullWidth={fullWidth} maxWidth={maxWidth} scroll="paper" open={open} onClose={handleClose} aria-labelledby="max-width-dialog-title">
      <form style={{ height: "80vh" }} className={classes.form} noValidate>
        <DialogTitle id="max-width-dialog-title">
          {translate("Contact.AddCV")} <CloseIcon onClick={handleClose} className={classes.closeIcon} />
        </DialogTitle>
        <DialogContent>
          {isLoadingJobs || isLoadingTags || cv.isFetchingResumeId ? (
            <InitialLoading />
          ) : (
            <>
              <div
                style={{
                  flexDirection: "column",
                  display: "flex",
                  alignItems: "center",
                  height: "100%"
                }}
              >
                <HorizontalLabelPositionBelowStepper realStep={realStep} />

                {realStep === 0 && (
                  <>
                    <div
                      style={{
                        flexDirection: "column",
                        display: "flex",
                        alignItems: "center",
                        height: "100%",
                        justifyContent: "center"
                      }}
                    >
                      <FormControl className={clsx(classes.formControl)}>
                        <Autocomplete
                          id="tags-outlined"
                          onChange={handleChangeClientID}
                          onInputChange={(event, newInputValue) => {
                            setCvOrigin(newInputValue)
                          }}
                          options={CvOriginList}
                          getOptionLabel={(option) => `${translate(`Contact.CvOrigins.${option.name}`)}`}
                          filterSelectedOptions
                          renderInput={(params) => <TextField {...params} variant="outlined" label={translate("Common.ApplicationSource")} />}
                        />
                      </FormControl>

                      <FormControl className={clsx(classes.formControl)}>
                        <Autocomplete
                          id="tags-outlined"
                          onChange={handleChangeClientID}
                          onInputChange={(event, newInputValue) => {
                            setClientIdFunction(newInputValue)
                          }}
                          options={ClientIdCompany}
                          getOptionLabel={(option) => `${option.id} - ${option.name}`}
                          filterSelectedOptions
                          renderInput={(params) => <TextField {...params} variant="outlined" label={translate("Common.Clients")} />}
                        />
                      </FormControl>
                      <FormControl className={clsx(classes.formControl)}>
                        <Autocomplete
                          id="tags-outlined"
                          onChange={handleChange}
                          disabled={step.id === 0}
                          values={jobName}
                          inputValue={jobName}
                          onInputChange={(event, newInputValue) => setJobNameForPush(newInputValue)}
                          options={jobsList || []}
                          getOptionLabel={(option) => option.jobTitle}
                          filterSelectedOptions
                          renderInput={(params) => <TextField {...params} variant="outlined" label={translate("Statistics.JobListing")} />}
                        />
                      </FormControl>

                      {step.id === 2 && (
                        <Button autoFocus variant="contained" onClick={fetchResumeId} style={{ marginTop: "1.5em" }} color="secondary">
                          Suivant
                        </Button>
                      )}
                    </div>
                  </>
                )}
                {realStep === 1 && (
                  <DropZone
                    step={step}
                    jobName={jobName}
                    query={query}
                    cv={cv}
                    postNameFromButton={postNameFromButton}
                    afterDropZone={afterDropZone}
                    getResumeData={getResumeData}
                    auth={auth}
                    clientID={clientID}
                    jobId={jobId}
                    realStep={realStep}
                    setQuery={setQuery}
                    setStep={setStep}
                    NoCv={NoCv}
                    findIdOfJobs={findIdOfJobs}
                  />
                )}
                {realStep === 2 && (
                  <ConfirmationStep
                    normalizeApplicationData={normalizeApplicationData}
                    tags={tags}
                    setOpen={setOpen}
                    applicationData={applicationData}
                    setApplicationData={setApplicationData}
                  />
                )}
              </div>
            </>
          )}
        </DialogContent>
        <DialogActions>
          {step > 0 && (
            <Button autoFocus onClick={setStep(step - 1)} color="primary">
              Back
            </Button>
          )}
        </DialogActions>
      </form>
    </Dialog>
  )
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getResumeData: FetchResumeDatas
    },
    dispatch
  )

const postInfo = {
  async sendContactApplication(jobTagId, clientID, resumeToken, resumeInfos, setIsSend) {
    const endpoint = `${clientID}/${jobTagId}/${resumeToken}`
    const formData = resumeInfos

    try {
      const res = await postCvInfo("contacts/resumes", endpoint, formData, setIsSend)
      return res
    } catch (e) {
      setIsSend(false)
      showErrorToast(e)
    }
  }
}

export const mapStateToProps = ({ auth, cv }) => {
  return {
    auth,
    cv
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddCvBox)
