import { Button, Grid } from "@material-ui/core"
import { useQueryJobs } from "api/hooks"
import React from "react"
import { useTranslation } from "react-i18next"
import CandidateTextBox from "./search/contactTextBox"
import { DatePicker } from "./search/datePicker"
import { JobPicker } from "./search/jobPicker"
import { StatTypePicker } from "./search/statTypePicker"

interface ISearchSectionProps {
  setContactId: (contactId: string) => void
  setJobId: (jobId?: string) => void
  setType: (type?: string) => void
  range: { start: string; end?: string }
  setRange: React.Dispatch<React.SetStateAction<{ start: string; end?: string }>>
  search: () => void
}

export const SearchContainer: React.FC<ISearchSectionProps> = (props) => {
  const { range, setRange, setContactId, setJobId, setType, search } = props
  const { t: translate } = useTranslation()
  const { data: jobsList } = useQueryJobs()

  return (
    <Grid style={{ padding: "20px", minHeight: "430px" }} container direction="column" spacing={2}>
      <Grid style={{ padding: "10px", flex: 1 }} container>
        <Grid item xs={12}>
          <DatePicker range={range} setRange={setRange} />
        </Grid>
        <Grid item xs={12}>
          <JobPicker setJobId={setJobId} jobsList={jobsList || []} />
        </Grid>
        <Grid item xs={12}>
          <CandidateTextBox setContactId={setContactId} />
        </Grid>
        <Grid item xs={12}>
          <StatTypePicker setType={setType} />
        </Grid>
      </Grid>

      <Grid style={{ justifySelf: "flex-end" }} item xs={12}>
        <Button style={{ width: "100%" }} color="primary" variant="outlined" onClick={search}>
          {translate("Common.Search")}
        </Button>
      </Grid>
    </Grid>
  )
}
