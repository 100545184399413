export const SEND_ACTION_TO_INFU = "SEND_ACTION_TO_INFU"
export const RECEIVE_RETURN_FROM_INFU = "RECEIVE_RETURN_FROM_INFU"
export const RECEIVE_ERROR = "RECEIVE_ERROR"

export const postAction = (action) => ({
  type: SEND_ACTION_TO_INFU,
  json: action
})

export const receiveActionResponse = (action) => ({
  type: RECEIVE_RETURN_FROM_INFU,
  json: action
})

export const receiveError = (err) => ({
  type: RECEIVE_ERROR,
  error: err.data
})
