const setJobsPrequalToLocalStorage = (jobs0) => {
  const a = {
    formations: jobs0.prequalification.formations,
    experiencesProfessionnelles: jobs0.prequalification.experiencesProfessionnelles,
    aptitudesTechniques: jobs0.prequalification.aptitudesTechniques,
    aptitudesLinguistiques: jobs0.prequalification.aptitudesLinguistiques,
    dateEntrevuePersonne: "",
    dateEntrevueClient: "",
    attentesSalariales: jobs0.prequalification.attentesSalariales
  }

  localStorage.setItem("prequalValue", JSON.stringify(a))
}

const setJobsPrequalFromLocalStorage = (jobs0, classFake, setClassFake) => {
  if (jobs0.prequalification) {
    if (Object.keys(jobs0.prequalification).length !== 0) {
      jobs0.prequalification = JSON.parse(localStorage.getItem("prequalValue"))
      setClassFake(`${classFake}1`)
    }
    if (Object.keys(jobs0.prequalification).length === 0) {
      jobs0.prequalification = JSON.parse(localStorage.getItem("prequalValue"))
    }
  } else {
    jobs0.prequalification = JSON.parse(localStorage.getItem("prequalValue"))
  }
}

export { setJobsPrequalToLocalStorage, setJobsPrequalFromLocalStorage }
