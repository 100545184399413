import React from "react"
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import { makeStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import { localeMap, localeFormatMap, localeCancelLabelMap, localeUtilsMap } from "../intl"

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  }
}))

const DatePickerWrapper = (props) => {
  const {
    input: { name, onChange, value, ...restInput },
    meta,
    ...rest
  } = props
  const showError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched
  const locale = "fr"
  return (
    <MuiPickersUtilsProvider utils={localeUtilsMap[locale]} locale={localeMap[locale]}>
      <DateTimePicker
        style={{ width: "100%" }}
        name={name}
        helperText={showError ? meta.error || meta.submitError : undefined}
        cancelLabel={localeCancelLabelMap[locale]}
        error={showError}
        onChange={onChange}
        value={value === "" ? null : value}
        format={localeFormatMap[locale]}
        inputProps={restInput}
        {...rest}
      />
    </MuiPickersUtilsProvider>
  )
}

const DatePickerWrapperDateTimeLocale = (props) => {
  const classes = useStyles()

  const {
    input: { name, onChange, value, ...restInput },
    meta,
    ...rest
  } = props
  const showError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched

  return (
    <TextField
      id="datetime-local"
      label={name}
      helperText={showError ? meta.error || meta.submitError : undefined}
      type="datetime-local"
      className={classes.textField}
      InputLabelProps={{
        shrink: true
      }}
      name={name}
      error={showError}
      onChange={onChange}
      value={value}
      inputProps={restInput}
      {...rest}
    />
  )
}
export default DatePickerWrapper
export { DatePickerWrapperDateTimeLocale }
