import { useQueryTags, useQueryJobs } from "api/hooks"
import useNavigation from "hooks/useNavigation"
import React from "react"
import { Switch, Route, Redirect } from "react-router"
import { JobContacts } from "./jobContacts"
import { Jobs } from "./jobs"

export const JobSwitcher: React.FC = () => {
  const { match } = useNavigation()
  const { data: tags, isLoading } = useQueryTags()
  const { data: jobsList } = useQueryJobs()
  return (
    <Switch>
      <Route exact path={`${match.url}`} component={Jobs} />
      <Route path={`${match.url}/:jobId`} render={() => <JobContacts isFetchingTags={isLoading} jobsList={jobsList || []} tags={tags || []} />} />
      <Redirect to={{ pathname: "/jobs" }} />
    </Switch>
  )
}
