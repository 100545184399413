// to translate
export const statusMap = {
  SOURCING: "Sourcing",
  B_TO_SORT: "CV à trier",
  C_ENTREVUE_TELEPHONIQUE: "Entrevue téléphonique",
  D_DOCUMENTS: "Documents",
  E_ENTREVUE_PARTENAIRE: "Entrevue Partenaire",
  F_ENTREVUE_CLIENT: "Entrevue client",
  G_REFERENCES: "Références",
  H_TESTS: "Tests",
  I_STAND_BY: "Stand-by",
  J_PRET_A_LEMBAUCHE: "Prêt à l’embauche",
  K_EMBAUCHE: "Embauché",
  L_DESISTEMENT: "Désistement",
  M_REJETE: "Rejeté",
  N_Transfert_autre_affichage: "Transfert autre affichage",
  O_JOB_FILLED: "Poste Comblé"
}
