import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import PostAddIcon from "@material-ui/icons/PostAdd"
import { useContactJobs } from "utils/jobs"
import { ITagDto, TagCategory } from "api/dto/ITagDto"
import { IJobDto } from "api/dto"
import { useTranslation } from "react-i18next"
import useNavigation from "hooks/useNavigation"
import { Button, createStyles, makeStyles } from "@material-ui/core"
import GetAppIcon from "@material-ui/icons/GetApp"
import { CSVLink } from "react-csv"
import { StatsPerJobsModal } from "containers/formulaires/stats/statsPerJobsModal"
import MaterialTable from "material-table"
import InitialLoading from "components/loading"
import { ANCIA_CLIENT_ID } from "api/constants"
import { MultiStepsModal } from "containers/formulaires/stats/addMultiStepsModal"
import { GroupBy } from "../ui-table/groupBy"
import AddCvBox from "../../../components/ajouter-cv/AddCv"
import { DefaultColumns, DetailPanelJob, ColumnsJob } from "../ui-table/options"
import useStyles from "../../../layouts/Main/styles"
import "sass/components/_contacts.scss"
import "moment/locale/fr-ca"
import GroupedRow from "../ui-table/groupedRow"
import { PatchedPagination } from "../JobsList/tablePagination"

interface IContactTableJobProps {
  tags: ITagDto[]
  isLoadingTags: boolean
  isJob: boolean
  jobsList: IJobDto[]
}

const getNoteStyle = makeStyles(() => ({
  notes: {
    "& .MuiTextField-root": {
      width: "100%"
    }
  }
}))

export const ContactTableJob: React.FC<IContactTableJobProps> = (props) => {
  const { currentJobId } = useContactJobs()
  const { tags, isLoadingTags, isJob, jobsList } = props
  const { t: translate } = useTranslation()
  const classes = useStyles()
  const mui = useSelector((state) => (state as any).mui)
  const { contactsList: contacts, isFetchingContacts } = useSelector((state: any) => state.contacts)
  const jobTags = useMemo(() => (tags || []).filter((t) => t.category === TagCategory.job) || [], [tags])
  const tagsList = useMemo(() => tags || [], [tags])
  const { options, localization } = mui
  const [openStatsPerJobsModal, setOpenStatsPerJobsModal] = useState(false)
  const { location, query } = useNavigation()
  const [open, setOpen] = useState(false)
  const [realStep, setRealStep] = useState(1)
  const [step, setStep] = useState({ id: 1 })
  const noteStyle = getNoteStyle()

  const [exportAllCandidateFromThisJobs, setExportAllCandidateFromThisJobs] = useState({
    header: [
      // { label: "Category", key: "currentJob.rejectedCategory" },
      { label: "Id", key: "Id" },
      { label: "ContactName", key: "ContactName" },
      // { label: "Notes", key: "currentJob.rejectedDisclaimerNotes" },
      // { label: "Date", key: "currentJob.modifiedAt" },
      { label: "cvOrigin", key: "currentJob.cvOrigin" }
    ],
    datas: [],
    fileName: ""
  })

  useEffect(() => {
    const remakeGroupedDatas = contacts

    remakeGroupedDatas.sort((a, b) => {
      if (a.currentJob.cvOrigin === undefined) {
        a.currentJob.cvOrigin = "N/A"
      }
      if (b.currentJob.cvOrigin === undefined) {
        b.currentJob.cvOrigin = "N/A"
      }
      return a.currentJob.cvOrigin < b.currentJob.cvOrigin ? -1 : a.currentJob.cvOrigin > b.currentJob.cvOrigin ? 1 : 0
    })
    setExportAllCandidateFromThisJobs({
      header: exportAllCandidateFromThisJobs.header,
      datas: remakeGroupedDatas,
      fileName: `AllCandidates`
    })
  }, [contacts])

  const handleClickOpenAddCv = useCallback(() => {
    setStep({ id: 0 })
    setRealStep(0)
    setOpen(true)
  }, [jobTags, currentJobId])

  const handleClose = useCallback(() => {
    setRealStep(0)
    setOpen(false)
  }, [])

  const generateColumns = useMemo(
    () => (!isJob ? DefaultColumns(currentJobId, handleClickOpenAddCv) : ColumnsJob(mui, currentJobId, handleClickOpenAddCv, noteStyle, translate)),
    [isJob, location, handleClickOpenAddCv, currentJobId, mui, translate]
  )

  if (isFetchingContacts || isLoadingTags) {
    return <InitialLoading fullScreen={false} content />
  }

  if (contacts.length === 0) {
    // todo traduction
    return <span>Aucune candidature</span>
  }

  return (
    <>
      <MaterialTable
        components={{
          GroupRow: GroupedRow,
          Pagination: PatchedPagination
        }}
        data={contacts}
        title={
          <div className={classes.headerContact}>
            <div>
              {tagsList && <JobName jobId={currentJobId} jobsList={jobsList} />}
              <div>
                <h2>{translate("Contact.AllContacts")}</h2>
              </div>
              <GroupBy />
            </div>

            <div className={classes.buttonContainer}>
              <span className={classes.addCv} onClick={() => setOpenStatsPerJobsModal(true)}>
                <div style={{ marginRight: 5 }} className="makeStyles-icon-31">
                  <PostAddIcon />
                </div>
                <div>{translate("Statistics.Statistics")}</div>
              </span>

              <Button disabled={isLoadingTags} className={classes.addCv} onClick={() => handleClickOpenAddCv()}>
                <div style={{ marginRight: 5 }} className="makeStyles-icon-31">
                  <PostAddIcon />
                </div>
                <div>{translate("Contact.AddCV")}</div>
              </Button>
              <CSVLink
                style={{ marginRight: 5 }}
                data={exportAllCandidateFromThisJobs.datas}
                className={classes.button}
                filename={exportAllCandidateFromThisJobs.fileName}
                headers={exportAllCandidateFromThisJobs.header}
              >
                <GetAppIcon style={{ color: "#000" }} />
              </CSVLink>
            </div>
          </div>
        }
        detailPanel={DetailPanelJob({ jobId: currentJobId, tags })}
        columns={generateColumns as any}
        localization={localization}
        options={{
          ...options,
          searchText: query.q,
          paging: true,
          tableLayout: "auto",
          pageSize: contacts.length < 10 ? 10 : 25,
          sorting: true
        }}
      />
      <StatsPerJobsModal isOpen={openStatsPerJobsModal} setOpen={setOpenStatsPerJobsModal} currentJobId={currentJobId} />
      <AddCvBox
        open={open}
        setStep={setStep}
        setOpen={setOpen}
        step={step}
        realStep={realStep}
        setRealStep={setRealStep}
        handleClose={handleClose}
        clientIDFromButton={ANCIA_CLIENT_ID}
        postIDFromButton={currentJobId}
        postNameFromButton={undefined}
      />
      <MultiStepsModal />
    </>
  )
}

const JobName: React.FC<{ jobsList: IJobDto[]; jobId: string }> = ({ jobId, jobsList }) => {
  const tag = jobsList.find((j) => j.jobTagId === jobId)
  return <span style={{ fontWeight: "bold", fontSize: 22 }}>{tag?.jobTitle}</span>
}
