import React from "react"
import TextField from "@material-ui/core/TextField"
import PermPhoneMsgIcon from "@material-ui/icons/PermPhoneMsg"
import InfoIcon from "@material-ui/icons/Info"
import TelegramIcon from "@material-ui/icons/Telegram"
import PostAddIcon from "@material-ui/icons/PostAdd"
import { JobStatus } from "api/dto/IContactJobDto"
import moment from "moment"
import { myColumnArrays } from "helper"
// import { StandbySwitch } from "../../../components/switch/standbySwitch"
import { NotesAndForm, Informations, Actions } from "../../formulaires"
import { formatRowStatus } from "./styles"

const colBase = [
  {
    title: "Id",
    field: "Id",
    hidden: true
  },
  {
    title: "Identifications",
    field: "ContactName",
    hidden: true
  },
  { title: "ContactName", field: "ContactName", hidden: true },
  { title: "PhoneWithExtension1", field: "PhoneWithExtension1", hidden: true },
  { title: "PhoneWithExtension2", field: "PhoneWithExtension2", hidden: true },
  { title: "Email", field: "Email", hidden: true },
  { title: "Notes", field: "Notes", hidden: true },
  {
    title: "AppreciationPartenaireDaffaire",
    field: "AppreciationPartenaireDaffaire",
    hidden: true
  },
  { title: "AppreciationClient", field: "AppreciationClient", hidden: true },
  {
    title: "RejectedDisclaimerNotes",
    field: "RejectedDisclaimerNotes",
    hidden: true
  },

  { title: "Ville", field: "City", hidden: true },
  { title: "Tags", field: "Tags", hidden: true },
  {
    title: "CV",
    field: "Website",
    hidden: true
  },
  { title: "Maj", field: "LastUpdated", hidden: true },
  { title: "Jobs", field: "jobs", hidden: true }
]

export const DefaultColumns = (jobId, handleClickOpen) => {
  return [
    ...colBase,
    // {
    //   title: "En attente",
    //   field: "onHold",
    //   defaultSort: "asc",
    //   customSort: (a, b) => {
    //     const aValue = a.currentJob.standby ? 1 : 0
    //     const bValue = b.currentJob.standby ? 1 : 0
    //     return bValue - aValue
    //   },
    //   render: (rowData) => <StandbySwitch rowData={rowData} />,
    //   cellStyle: () => ({
    //     width: "unset",
    //     minWidth: "150px",
    //     maxWidth: "150px"
    //   }),
    //   grouping: false,
    //   sorting: true
    // },
    {
      title: "Identifications",
      field: "ContactName",
      editable: "never",
      hidden: false,
      grouping: false,
      render: (rowData) => {
        // eslint-disable-next-line no-unused-vars
        // eslint-disable-next-line prettier/prettier
        const { Id, ContactName, Email, PhoneWithExtension1, PhoneWithExtension2, Website } = rowData
        let myResult = "N/A"
        if (Website != null) {
          if (Website.length !== 0) {
            myResult = rowData.Website
          }
        }
        return (
          <div style={{ width: "100%" }}>
            <strong>
              {ContactName} - {Id}
            </strong>
            <p style={{ fontSize: ".9em" }}>
              <a href={`mailto:${Email}`} target="_blank" rel="noopener noreferrer">
                {Email}
              </a>
            </p>
            <p style={{ fontSize: ".9em" }}>
              {PhoneWithExtension1.length > 6 && <a href={`tel:${PhoneWithExtension1}`}>{PhoneWithExtension1}</a>}
              {PhoneWithExtension1.length > 6 && PhoneWithExtension2 && PhoneWithExtension2.length > 6 && <br />}

              {PhoneWithExtension2 && PhoneWithExtension2.length > 6 && <a href={`tel:${PhoneWithExtension2}`}>{PhoneWithExtension2}</a>}
            </p>
            {myResult !== "N/A" ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <a target="_blank" href={myResult} rel="noopener noreferrer">
                  <span style={{ textDecoration: "underline", color: "#fb8c00", display: "flex", alignItems: "center" }}>Voir le CV</span>
                </a>
                <PostAddIcon
                  style={{
                    color: "#000000",
                    paddingLeft: "5px",
                    cursor: "pointer"
                  }}
                  onClick={handleClickOpen}
                />
              </div>
            ) : (
              <>
                <span
                  style={{
                    color: "#ff1424",
                    display: "flex",
                    alignItems: "center"
                  }}
                >
                  Aucun CV
                  <PostAddIcon
                    style={{
                      color: "#000000",
                      paddingLeft: "5px",
                      cursor: "pointer"
                    }}
                    onClick={handleClickOpen}
                  />
                </span>
              </>
            )}{" "}
          </div>
        )
      },
      cellStyle: () => {
        return {
          width: jobId ? "auto" : "100%",
          fontWeight: "bold",
          verticalAlign: "top"
        }
      },

      sorting: false,
      draggable: false
    }
  ]
}

export const ColumnsJob = (mui, jobId, handleClickOpen, noteStyle, translate) => {
  const selectStatus = mui.grouping.status ? { defaultGroupOrder: mui.grouping.status ? 0 : 1 } : {}
  return [
    {
      title: "Statut",
      field: "currentJob.status",
      ...selectStatus,
      customSort: (a, b) => myColumnArrays.indexOf(a) - myColumnArrays.indexOf(b),
      hidden: !mui.grouping.status,
      render: (status) => {
        const value = status.split(" - ")
        return `${translate(`Job.Status.${value[0]}`)} ${value[1]}`
      },
      cellStyle: (status) => formatRowStatus(status)
    },
    {
      title: "Statut",
      field: "currentJob",
      render: (contact) => {
        const hasJobs = contact.currentJob != null
        const { status } = contact.currentJob
        let statusName = translate(`Job.Status.${JobStatus.ToSort}`)
        if (hasJobs) {
          statusName = translate(`Job.Status.${status}`)
          let myColor
          switch (status) {
            case JobStatus.Sourcing:
              myColor = "#B78700"
              break
            case JobStatus.ToSort:
              myColor = "#c6d7f7"
              break

            case JobStatus.TelephoneInterview:
              myColor = "#7faafa"
              break
            case JobStatus.Documents:
              myColor = "#0f61fa"
              break
            case JobStatus.PartnerInterview:
              myColor = "#16f224"
              break
            case JobStatus.ClientInterview:
              myColor = "#15ab1e"
              break
            case JobStatus.Reference:
              myColor = "#38c5c9"
              break
            case JobStatus.Tests:
              myColor = "#2c989c"
              break
            case JobStatus.StandBy:
              myColor = "#fab693"
              break
            case JobStatus.ReadyToHire:
              myColor = "#ff8141"
              break
            case JobStatus.Hiring:
              myColor = "#ff5803"
              break
            case JobStatus.Desistement:
              myColor = "#DC143C"
              break
            case JobStatus.Rejected:
              myColor = "#B22222"
              break
            case JobStatus.Transfered:
              myColor = "#8B0000"
              break

            case JobStatus.JobFilled:
              myColor = "#246EE9"
              break

            default:
              myColor = "rgba(0,0,0,.2)"
          }

          return (
            <div>
              <span
                style={{
                  display: "inline-block",
                  height: "12px",
                  width: "12px",
                  marginRight: "10px",
                  backgroundColor: myColor,
                  borderRadius: "50%"
                }}
              />
              {hasJobs ? statusName : "En changement..."}
            </div>
          )
        }
      },
      cellStyle: (currentJob) => formatRowStatus(currentJob.status, true),
      hidden: mui.grouping.status,
      sorting: false
    },
    ...DefaultColumns(jobId, handleClickOpen),
    {
      title: "Created At",
      field: "currentJob.createdAt",
      grouping: false,
      render: (contact) => {
        const { createdAt } = contact.currentJob
        const formatedDate = createdAt ? moment(createdAt).format("YYYY-MM-DD HH:mm") : "N/D"
        return (
          <div>
            <span>{formatedDate}</span>
          </div>
        )
      },
      cellStyle: () => ({
        width: "unset",
        minWidth: "15%",
        maxWidth: "15%"
      })
    },
    {
      title: "Modified At",
      field: "currentJob.modifiedAt",
      grouping: false,
      render: (contact) => {
        const { modifiedAt } = contact.currentJob
        const formatedDate = modifiedAt ? moment(modifiedAt).format("YYYY-MM-DD HH:mm") : "N/D"
        return (
          <div>
            <span>{formatedDate}</span>
          </div>
        )
      },
      cellStyle: () => ({
        width: "unset",
        minWidth: "15%",
        maxWidth: "15%"
      })
    },
    {
      title: "Notes",
      sorting: false,
      grouping: false,
      render: (rowData) => {
        const { currentJob } = rowData
        return (
          <div className={noteStyle.notes}>
            <TextField
              id="standard-multiline-flexible"
              multiline
              maxRows={4}
              disabled
              value={
                currentJob.status === "L_DESISTEMENT" || currentJob.status === "M_REJETE" ? currentJob.rejectedDisclaimerNotes : currentJob.notes
              }
              styled={{ width: 100 }}
            />
          </div>
        )
      },
      cellStyle: () => {
        return {
          width: "100%"
        }
      }
    }
  ]
}

export const DetailPanelJob = ({ jobId, tags }) => {
  return [
    (rowData) => ({
      icon: () => {
        const otherJobs = rowData.jobs.filter((j) => j.jobTagId !== jobId)
        return (
          <InfoIcon
            style={{
              margin: ".1em",
              color: otherJobs.length > 0 ? "#759BFF" : "#333"
            }}
          />
        )
      },

      tooltip: "Informations Complémentaires",
      openIcon: () => <InfoIcon style={{ margin: ".1em", color: "#f4511e" }} />,
      render: () =>
        rowData.jobs.length !== 0 ? (
          <div className="infoComplementaire panel">
            <Informations rowData={rowData} tags={tags} />
          </div>
        ) : (
          <p style={{ margin: ".5em", padding: ".5em" }}>Ce candidat n'a aucune information Complémentaires.</p>
        )
    }),
    {
      icon: () => <PermPhoneMsgIcon id="notes-and-formulaires" style={{ margin: ".1em", color: "#333" }} />,
      tooltip: "Notes & Formulaires",
      openIcon: () => <PermPhoneMsgIcon style={{ margin: ".1em", color: "#f4511e" }} />,
      render: (rowData) => {
        const { jobs } = rowData

        return jobs.length !== 0 ? (
          <NotesAndForm rowData={rowData} />
        ) : (
          <p style={{ margin: ".5em", padding: ".5em" }}>Ce candidat n'a aucune information d'emploi.</p>
        )
      }
    },

    {
      icon: () => <TelegramIcon id="Action" style={{ margin: ".1em", color: "#333" }} />,
      tooltip: "Action",
      openIcon: () => <TelegramIcon style={{ margin: ".2em", color: "#f4511e" }} />,
      render: (rowData) => {
        const { jobs } = rowData

        return jobs.length !== 0 ? (
          <div className="infoComplementaire panel">
            <Actions rowData={rowData} jobId={jobId} />
          </div>
        ) : (
          <p style={{ margin: ".5em", padding: ".5em" }}>Ce candidat n'a aucune information Complémentaires.</p>
        )
      }
    }
  ]
}
