export function authHeader(cvFileExtension = "json") {
  // const application = ""
  // if (cvFileExtension.indexOf("image") === -1) {
  //   application = "application/"
  // }

  return {
    "content-type": `${cvFileExtension}`
  }
}

export const authHelper = {
  authHeader
}
