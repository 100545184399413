import { Button, Dialog, DialogContent, DialogTitle } from "@material-ui/core"
import React, { ReactNode } from "react"
import CloseIcon from "@material-ui/icons/Close"
import { DefaultValues, FormProvider, useForm, UseFormReturn } from "react-hook-form"
import { useTranslation } from "react-i18next"

interface IModalWrapperProps {
  title: string
  description?: string
  saveText?: string
  toggleText?: string
  opened: boolean
  toggle: () => void

  children: ((methods: UseFormReturn<any>) => ReactNode) | ReactNode

  defaultValues?: DefaultValues<any>
  onSubmit?: (data: any) => void
}

export const ModalWrapper: React.FunctionComponent<IModalWrapperProps> = (props) => {
  const { opened, toggle, saveText, toggleText, onSubmit, description, title, children, defaultValues } = props
  const { t: translate } = useTranslation()
  const methods = useForm({ mode: "all", defaultValues })
  const {
    handleSubmit,
    formState: { isSubmitting, errors, isValid }
  } = methods

  return (
    <FormProvider {...methods}>
      <Dialog className="add-url-modal" fullWidth open={opened}>
        <DialogTitle id="max-width-dialog-title">
          <div className="title-section">
            <div>{title}</div>
            {description && <div className="description">{description}</div>}
          </div>
          <div className="close-button">
            <CloseIcon style={{ cursor: "pointer" }} onClick={toggle} />
          </div>
        </DialogTitle>
        <DialogContent>
          {onSubmit ? (
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="content">{typeof children === "function" ? children(methods) : children}</div>
              <div className="modal-footer">
                <Button disabled={isSubmitting} color="primary" variant="text" onClick={toggle}>
                  {translate("Common.Cancel")}
                </Button>
                <Button disabled={!isValid || isSubmitting || Object.keys(errors).length > 0} color="secondary" variant="text" type="submit">
                  {saveText || translate("Common.Save")}
                </Button>
              </div>
            </form>
          ) : (
            <>
              <div className="content">{typeof children === "function" ? children(methods) : children}</div>
              <div className="modal-footer">
                <Button color="primary" variant="text" onClick={toggle}>
                  {toggleText || translate("Common.Cancel")}
                </Button>
              </div>
            </>
          )}
        </DialogContent>
      </Dialog>
    </FormProvider>
  )
}
