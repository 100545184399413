import axios from "axios"
import { useTranslation } from "react-i18next"
import { useMutation, useQueryClient } from "react-query"
import { showSuccessToast } from "utils/toast"

export function useMutationDeleteDocument(contactId: string, jobId: string, documentId: string) {
  const { t: translate } = useTranslation()
  const queryClient = useQueryClient()
  return useMutation(() => axios.delete(`/contacts/${contactId}/documents/${documentId}`), {
    onSuccess: (res) => {
      setTimeout(() => {
        queryClient.invalidateQueries(`${contactId}-${jobId}-folders`)
        showSuccessToast(translate("Document.DeletedSuccess"))
      }, 2000)
    }
  })
}

export function useMutationDeleteVault(id: string) {
  const { t: translate } = useTranslation()
  const queryClient = useQueryClient()
  return useMutation(() => axios.delete(`/tokens/${id}`), {
    onSuccess: ({ data }: any) => {
      queryClient.setQueryData(`${data.contactId}-${data.jobId}-token`, null)
      showSuccessToast(translate("Document.Vault.DeletedTokenSuccess"))
    }
  })
}
