import React from "react"
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"
import { Grid } from "@material-ui/core"
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date"

export interface IDatePickerProps {
  range: { start?: string; end?: string }
  setRange: React.Dispatch<React.SetStateAction<{ start: string; end?: string }>>
}

export const DatePicker: React.FC<IDatePickerProps> = (props) => {
  const { setRange, range } = props

  const handleStartDateChange = (date: MaterialUiPickersDate) => {
    if (!date) {
      return
    }

    setRange((old) => ({
      ...old,
      start: date.toISOString()
    }))
  }
  const handleEndDateChange = (date: MaterialUiPickersDate) => {
    setRange((old) => ({
      ...old,
      end: date?.toISOString()
    }))
  }
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={6}>
          <KeyboardDateTimePicker
            variant="inline"
            ampm={false}
            label="Date de débût"
            value={range?.start}
            onChange={handleStartDateChange}
            format="dd/MM/yyyy HH:mm"
          />
        </Grid>
        <Grid item xs={6}>
          <KeyboardDateTimePicker
            variant="inline"
            ampm={false}
            label="Date de fin"
            value={range?.end}
            onChange={handleEndDateChange}
            format="dd/MM/yyyy HH:mm"
          />
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  )
}
