import React from "react"
import { ReactComponent as RemoveIcon } from "sass/images/remove.svg"
import { useMutationDeleteDocument } from "api/hooks/delete"
import { Modal } from "components/modal/modal"
import { useTranslation } from "react-i18next"
import useStyles from "./styles"

interface IDeleteDocumentProps {
  contactId: string
  jobId: string
  contactName: string
  files: any
}

export const DeleteDocument: React.FC<IDeleteDocumentProps> = (props) => {
  const { contactId, files, contactName, jobId } = props
  const { t: translate } = useTranslation()
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)

  const { mutateAsync } = useMutationDeleteDocument(contactId, jobId, files.documentId)

  const deleteDocument = async () => {
    setOpen(false)
    await mutateAsync()
  }

  return (
    <>
      <RemoveIcon className={classes.icon} onClick={() => setOpen(true)} />
      <Modal
        title={`${translate(`Document.DeleteDocumentTitle`)}`}
        open={open}
        fullWidth
        maxWidth="md"
        close={() => setOpen(false)}
        action={deleteDocument}
        saveText="Delete"
      >
        <p>
          {translate(`Document.DeleteDocumentConfirmation`)}
          <span className={classes.bold}>{contactName}</span> :<span className={classes.block}>{files.fileName}</span>
        </p>
      </Modal>
    </>
  )
}

export default DeleteDocument
