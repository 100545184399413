import React from "react"
import { Link as RouterLink } from "react-router-dom"
import clsx from "clsx"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/styles"
import { Avatar, Typography } from "@material-ui/core"

const infosUser = {
  firstName: "Michel",
  lastName: "Bourbeau",
  email: "michel.bourbeau@recruto.ca",
  phone: "5819993969",
  city: "Quebec",
  state: "Québec",
  avatar: "/images/avatars/michel.bourbeau@recruto.ca.png",
  bio: "Tech Lead"
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "fit-content"
  },
  avatar: {
    width: 60,
    height: 60
  },
  name: {
    marginTop: theme.spacing(1)
  }
}))

const Profile = (props) => {
  const { className, ...rest } = props

  const classes = useStyles()

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Avatar alt="Person" className={classes.avatar} component={RouterLink} src={infosUser.avatar} to="/profile" />
      <Typography variant="h4">{`${infosUser.firstName} ${infosUser.lastName}`}</Typography>
      <Typography variant="body2">{infosUser.bio}</Typography>
    </div>
  )
}

Profile.propTypes = {
  className: PropTypes.string
}

export default Profile
