export const RECEIVE_ERROR = "RECEIVE_ERROR"
export const RECEIVE_CVINFO = "RECEIVE_CVINFO"
export const REQUEST_RESUME_ID_JOB_ID = "REQUEST_RESUME_ID_JOB_ID"
export const RECEIVE_RESUME_ID_JOB_ID = "RECEIVE_RESUME_ID_JOB_ID"
export const RECEIVE_RESUME_DATAS = "RECEIVE_RESUME_DATAS"
export const REQUEST_RESUME_DATAS = "REQUEST_RESUME_DATAS"

export const requestResumeIdJobId = (action) => ({
  type: REQUEST_RESUME_ID_JOB_ID,
  json: action
})

export const requestResumeDatas = (action) => ({
  type: REQUEST_RESUME_DATAS,
  json: action
})

export const receiveCVInfo = (action) => ({
  type: RECEIVE_CVINFO,
  json: action
})

export const receiveResumeIdJobId = (action) => ({
  type: RECEIVE_RESUME_ID_JOB_ID,
  json: action
})

export const receiveResumeDatas = (action) => ({
  type: RECEIVE_RESUME_DATAS,
  json: action
})

export const receiveError = (err) => ({
  type: RECEIVE_ERROR,
  error: err.data
})
