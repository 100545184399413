/* eslint-disable camelcase */
interface IContactDto {
  Id: string
  ContactName: string
  PostalCodePlusZipFour1?: string
  PhoneWithExtension1?: string
  Email?: string
  Emails?: string[]
  BirthDay?: string
  LastUpdated?: string
  City?: string
  PhoneWithExtension2?: string
  Custom_DateentrevueRECRUTO?: string
  Custom_DateentrevueANCIA?: string
  Custom_Dateentrevueclient?: string
  Website?: string
  Tags: string[]
  jobs: any[]
  notesList: any[]
  currentJob?: any
  addresses?: IContactAddress[]
  phoneNumbers?: string[]
  socialAccounts?: { name?: string; type?: "Facebook" | "Twitter" | "LinkedIn" }[]
}

interface IContactDtoV2 {
  id: string
  contactJob?: string
  contactName?: string
  firstName?: string
  middleName?: string
  lastName?: string
  preferredName?: string
  addresses?: IContactAddress[]
  anniversary?: string
  birthday?: string
  company?: { company_name?: string; id?: number }
  contactType?: string
  createdAt?: string
  emails?: string[]
  hasOptedIn?: boolean
  emailStatus?: string
  faxNumbers?: string[]
  jobTitle?: string
  modifiedAt?: string
  optInReason?: string
  origin?: { date: string; ip_address: string }
  phoneNumbers?: string[]
  language?: string
  socialAccounts?: { name?: string; type?: "Facebook" | "Twitter" | "LinkedIn" }[]
  sourceType?: string
  spouseName?: string
  tags?: string[]
  timeZone?: string
  website?: string
  jobs?: []
  notesList?: []
}

interface IContactAddress {
  country_code?: string
  field: string
  line1?: string
  line2?: string
  locality?: string
  /**
   * Field used to store postal codes containing a combination of letters and numbers ex. 'EC1A', 'S1 2HE', '75000'
   */
  postal_code?: string
  region?: string
  /**
   * Mainly used in the United States, this is typically numeric. ex. '85001', '90002' Note: this is to be used instead of 'postal_code', not in addition to.
   */
  zip_code?: string
  /**
   * Last four of a full zip code ex. '8244', '4320'. This field is supplemental to the zip_code field, otherwise will be ignored.
   */
  zip_four?: string
}

// eslint-disable-next-line no-shadow
export enum ContactSearchFields {
  id = "_id",
  firstName = "firstName",
  middleName = "middleName",
  lastName = "lastName",
  addresses = "addresses",
  birthday = "birthday",
  emails = "emails",
  faxNumbers = "faxNumbers",
  jobTitle = "jobTitle",
  phoneNumbers = "phoneNumbers",
  language = "language",
  socialAccounts = "socialAccounts",
  tags = "tags",
  resume = "resume.\\*"
}

export type { IContactDto, IContactDtoV2, IContactAddress }
