import { RECEIVE_RETURN_FROM_INFU, SEND_ACTION_TO_INFU, RECEIVE_ERROR } from "./actions"

const initialState = {
  isPostingAction: false,
  actionResponse: [],
  hasErrorAction: false
}

function actionReducer(state = initialState, action) {
  switch (action.type) {
    case SEND_ACTION_TO_INFU:
      return {
        ...state,
        actionResponse: [],
        isPostingAction: true
      }

    case RECEIVE_RETURN_FROM_INFU:
      return {
        ...state,
        actionResponse: action.json,
        isPostingAction: false,
        hasErrorAction: false
      }

    case RECEIVE_ERROR:
      return {
        ...state,
        hasErrorAction: true,
        isPostingAction: false
      }
    default:
      return state
  }
}

export default actionReducer
