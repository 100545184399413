/* eslint-disable no-shadow */
export interface ITagDto {
  id: string
  name: string
  category: TagCategory
}

export enum TagCategory {
  job = "job",
  formation = "formation",
  experience = "experience",
  language = "language"
}
