import { Button } from "@material-ui/core"
import React from "react"
import { useForm, useFormState } from "react-final-form"

export const PrequalSubmitButton: React.FunctionComponent = () => {
  const { submit } = useForm()
  const { submitting, dirty } = useFormState()
  return (
    <Button
      variant="contained"
      color="secondary"
      id="save-prequal"
      type="button"
      disabled={!dirty || submitting}
      onClick={(e) => submit()}
      style={{
        float: "right",
        padding: "0.5em",
        margin: "0.5em 0"
      }}
    >
      Sauvegarder
    </Button>
  )
}
