import React, { useState } from "react"
import { connect, useSelector } from "react-redux"
import { Typography, Button, DialogContentText } from "@material-ui/core"
import { makeStyles, withStyles } from "@material-ui/core/styles"

import Dialog from "@material-ui/core/Dialog"
import MuiDialogTitle from "@material-ui/core/DialogTitle"
import MuiDialogContent from "@material-ui/core/DialogContent"
import MuiDialogActions from "@material-ui/core/DialogActions"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import { bindActionCreators } from "redux"
import Switch from "@material-ui/core/Switch"
import { useTranslation } from "react-i18next"
import { postActionInfusion, postActionInfusionDel } from "../../../redux/action/superFetch"

const useStylesForTag = makeStyles((theme) => ({
  root: {
    flexGrow: 0,
    textAlign: "center",

    backgroundColor: theme.palette.background.paper
  }
}))

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
})

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions)

const ActionsList = ({ rowData, postActionInfuDatas, postActionInfuDatasDel }) => {
  const classesForTag = useStylesForTag()
  const auth = useSelector((state) => state.auth)
  const [openAction, setOpenAction] = React.useState(false)
  const [fullWidth] = React.useState(true)
  const [maxWidth] = React.useState("md")
  const { t: translate } = useTranslation()
  const groupBy = (key) => (array) =>
    array.reduce((objectsByKeyValue, obj) => {
      const value = obj[key]
      objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj)
      return objectsByKeyValue
    }, {})

  const groupByCategory = groupBy("categorie")
  const allCat = JSON.stringify(
    {
      actionsByCategory: groupByCategory(auth.user.actions)
    },
    null,
    2
  )
  const allActions = JSON.parse(allCat)

  const allActionsByCat = Object.keys(allActions.actionsByCategory)

  const handleClickOpenAction = () => {
    setOpenAction(true)
  }
  const handleCloseAction = () => {
    setOpenAction(false)
  }

  const { Tags } = rowData
  const ActionSwitch = (action) => {
    const { action: a } = action
    const { infuTagId, label } = a
    const [check, setCheck] = useState(Tags.includes(infuTagId))

    const [openconfirmation, setOpenconfirmation] = React.useState(false)
    const [openconfirmationDel, setOpenconfirmationDel] = React.useState(false)

    const handleconfirmation = (userId, ActionId) => {
      setCheck(!check)
      postActionInfuDatas(userId, ActionId)
      setOpenconfirmation(false)
    }
    const handleconfirmationDel = (userId, ActionId) => {
      setCheck(!check)
      postActionInfuDatasDel(userId, ActionId)
      setOpenconfirmationDel(false)
    }
    const handleCloseconfirmation = () => {
      setOpenconfirmation(false)
      setOpenconfirmationDel(false)
    }

    return (
      <>
        <Switch
          checked={check}
          value={infuTagId}
          onClick={() => {
            if (check === false) {
              setOpenconfirmation(!openconfirmation)
            } else setOpenconfirmationDel(!openconfirmationDel)
          }}
        />

        <Dialog
          open={openconfirmation}
          onClose={handleCloseconfirmation}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Confirmer l'action ci dessous</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <span style={{ display: "block" }}>
                Êtes-vous certain de vouloir <strong style={{ color: "#83b54a", fontWeight: "bold" }}>APPLIQUER</strong> l’action{" "}
                <strong style={{ fontWeight: "bold", display: "block" }}>{label}</strong>
              </span>
              <span style={{ display: "block" }}>
                pour la candidature de <strong style={{ fontWeight: "bold" }}>{rowData.ContactName}</strong>
              </span>
              <span style={{ display: "block" }}>
                pour l'affichage de <strong style={{ fontWeight: "bold" }}>{rowData.currentJob.jobTitle}</strong>
              </span>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseconfirmation} color="primary">
              ANNULER
            </Button>
            <Button onClick={() => handleconfirmation(rowData.Id, action.action.id)} color="primary" autoFocus>
              CONFIRMER
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openconfirmationDel}
          onClose={handleCloseconfirmation}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Confirmer l'action ci dessous</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <p>
                <span style={{ display: "block" }}>
                  Êtes-vous certain de vouloir <strong style={{ color: "#dc3545", fontWeight: "bold" }}>RETIRER</strong> l’action{" "}
                  <strong style={{ fontWeight: "bold", display: "block" }}>{label}</strong>
                </span>
                <span style={{ display: "block" }}>
                  pour la candidature de <strong style={{ fontWeight: "bold" }}>{rowData.ContactName}</strong>
                </span>
                <span style={{ display: "block" }}>
                  pour l'affichage de <strong style={{ fontWeight: "bold" }}>{rowData.currentJob.jobTitle}</strong>
                </span>
              </p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseconfirmation} color="primary">
              ANNULER
            </Button>
            <Button onClick={() => handleconfirmationDel(rowData.Id, action.action.id)} color="primary" autoFocus>
              CONFIRMER
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }

  return (
    <div className={classesForTag.root}>
      <Button style={{ margin: 20 }} variant="outlined" color="primary" onClick={() => handleClickOpenAction()}>
        Actions
      </Button>

      <Dialog onClose={handleCloseAction} aria-labelledby="customized-dialog-title" open={openAction} maxWidth={maxWidth} fullWidth={fullWidth}>
        <DialogTitle id="customized-dialog-title" onClose={handleCloseAction}>
          Actions
        </DialogTitle>
        <DialogContent dividers>
          {allActionsByCat.map((actionCategories) => (
            <div key={`actionCategories${actionCategories}`}>
              <h2 style={{ fontSize: 22, fontWeight: "bold" }}>{actionCategories}</h2>
              <div style={{ marginTop: 10, marginLeft: 20, marginBottom: 10 }}>
                {allActions.actionsByCategory[actionCategories].map((action) => (
                  <div key={`action${action.infuTagId}`} style={{ display: "flex" }}>
                    <ActionSwitch action={action} />
                    <span
                      style={{
                        marginLeft: 20,
                        marginTop: 5,
                        marginBottom: 5
                      }}
                    >
                      {action.label}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseAction} variant="contained" color="secondary">
            {translate("Common.Close")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      postActionInfuDatas: postActionInfusion,
      postActionInfuDatasDel: postActionInfusionDel
    },
    dispatch
  )

const mapStateToProps = ({ sovren, router }) => {
  return { sovren, router }
}

export default connect(mapStateToProps, mapDispatchToProps)(ActionsList)
