import React, { useState, useEffect, useMemo, useCallback, useRef } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Form, Field } from "react-final-form"
import { Select } from "final-form-material-ui"
import { Typography, Paper, Grid, Button, MenuItem } from "@material-ui/core"
import PropTypes from "prop-types"
import Box from "@material-ui/core/Box"
import TextField from "@material-ui/core/TextField"
import { JobStatus } from "api/dto/IContactJobDto"
import { useTranslation } from "react-i18next"
import { useMutateUpdateJob } from "api/hooks/update"
import { monthList } from "utils/date"
import { useMultiStepsModal } from "hooks/useMultiStepsModal"
import { saveNote } from "utils/notes"
import ActionsList from "./ActionsList"
import { postActionInfusion } from "../../../redux/action/superFetch"
import { postJobsDatas } from "../../../redux/contact/superFetch"
import { useStyles } from "../styles"

const validate = () => {
  const errors = {}

  return errors
}

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component="span">{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

const AddNotes = ({ rowData, auth, postActionInfuDatas, setTempStatus, tempStatus, setShowModalRejectedDisclaimerNotes }) => {
  const { Id, ContactName, currentJob } = rowData
  const { t: translate } = useTranslation()
  const inputRef = useRef(null)
  const classes = useStyles()
  const [notes, setNotes] = useState(currentJob.notes)
  const [textDisabled, setTextDisabled] = useState(true)
  const { mutateAsync } = useMutateUpdateJob(Id, currentJob.jobTagId)
  const months = useMemo(() => monthList, [])
  const { open } = useMultiStepsModal()
  const allStatus = useMemo(
    () =>
      Object.values(JobStatus).map((j) => ({
        label: translate(`Job.Status.${j}`),
        value: j
      })),
    []
  )

  const onSubmit = async (contact) => {
    setTextDisabled(true)
    const job = { ...contact.currentJob, notes }
    mutateAsync({ ...contact, currentJob: job })
    open({ contactName: ContactName, job: currentJob, rowData, tempStatus })

    if (contact.currentJob.status === JobStatus.Hiring) {
      postActionInfuDatas(Id, "03903d06-6942-4394-8501-db489898b7aa")
    }
  }

  const appendNewNote = useCallback(() => {
    setTextDisabled(!textDisabled)
    inputRef.current?.focus()
    const actualtext = notes || ""
    const processedtext = `${generateLine()}\n\n${actualtext}`
    setNotes(processedtext)
  }, [months, inputRef.current, setTextDisabled, setNotes, notes, textDisabled])

  const generateLine = useCallback(() => {
    const d = new Date()
    const dates = d.getDate()
    const month = d.getMonth()
    const hours = d.getHours()
    const minutes0 = d.getMinutes() < 10 ? `0${d.getMinutes()}` : d.getMinutes()
    const totalDate = `${dates} ${months[month]} ${hours}:${minutes0}`

    return `${totalDate} [${auth.user.givenName}] : `
  }, [auth])

  useEffect(() => {
    if (!textDisabled) {
      const generated = generateLine()
      inputRef.current?.setSelectionRange(generated.length, generated.length)
      inputRef.current?.focus()
    }
  }, [inputRef, textDisabled])
  const updateText = useCallback(
    (e) => {
      setNotes(e.target.value)
    },
    [setNotes]
  )

  return (
    <>
      <Form onSubmit={onSubmit} initialValues={{ ...rowData }} validate={validate}>
        {({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit} noValidate>
            <h3 className={classes.h3}>Notes d'avancement</h3>
            <Paper className={classes.wrapper}>
              <Grid container className={classes.center} spacing={2}>
                <Grid container item xs={12} sm={3} direction="column" alignItems="center">
                  <Grid item>
                    <Field name="currentJob.status" component={Select} value={currentJob.status} formControlProps={{ className: "fullwidth" }}>
                      {allStatus.map((s) => (
                        <MenuItem
                          key={s.value}
                          onClick={(e) => {
                            setTempStatus(e.currentTarget.dataset.value)
                            if (e.currentTarget.dataset.value === JobStatus.Desistement || e.currentTarget.dataset.value === JobStatus.Rejected) {
                              setShowModalRejectedDisclaimerNotes(true)
                            }
                          }}
                          value={s.value}
                        >
                          {s.label}
                        </MenuItem>
                      ))}
                    </Field>
                  </Grid>
                  <Grid item style={{ marginTop: "10px" }}>
                    <Button
                      id="add-progress-notes"
                      onClick={appendNewNote}
                      variant="contained"
                      color="primary"
                      disabled={!textDisabled || submitting}
                    >
                      {translate("Job.AddNote")}
                    </Button>
                    <ActionsList auth={auth} rowData={rowData} />
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <Field name="currentJob.notes">
                    {(props) => (
                      <TextField
                        inputRef={inputRef}
                        fullWidth
                        rows="8"
                        multiline
                        name={props.input.name}
                        value={notes}
                        onChange={updateText}
                        onBlur={(e) => saveNote("notes", Id, currentJob.jobTagId, e.target.value)}
                        disabled={submitting || textDisabled}
                        variant="outlined"
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <div className={classes.footer}>
                    <Button
                      id="save-progress-notes"
                      variant="contained"
                      color="secondary"
                      type="submit"
                      disabled={submitting}
                      style={{ float: "right" }}
                    >
                      {translate("Common.Save")}
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </form>
        )}
      </Form>
    </>
  )
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      postContactsJobsDatas: postJobsDatas,
      postActionInfuDatas: postActionInfusion
    },
    dispatch
  )

const mapStateToProps = ({ auth, contacts }) => {
  return { auth, contacts }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddNotes)
