import { makeStyles } from "@material-ui/core/styles"

export const useStyleStats = makeStyles(() => ({
  statsContainer: {
    display: "flex",
    flexWrap: "wrap"
  },
  stat: {
    padding: "4px",
    display: "flex",
    alignItems: "center"
  },
  SurveyContainer: {
    display: "flex",
    padding: "2em 1em",
    margin: "1em 0",
    border: "1px solid grey",
    flexDirection: "column"
  },
  SurveyTitle: {
    fontSize: "24px"
  },
  SurveyDescription: {
    margin: "0.5rem 0"
  },
  formControl: {
    minWidth: 120
  },
  button: {},
  buttonContainer: {
    width: "100%"
  },
  buttonBillable: {
    display: "block"
  },
  close: {
    fontSize: "14px",
    fontWeight: 600,
    padding: "4px 12px",
    borderRadius: "5px",
    color: "#f4511e",
    backgroundColor: "#ffffff",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#eeeeee"
    }
  },
  save: {
    fontSize: "16px",
    fontWeight: 600,
    padding: "6px 16px",
    borderRadius: "5px",
    color: "#FFFFFF",
    backgroundColor: "#f4511e",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#bf360c"
    }
  },
  label: { padding: "5px", lineHeight: 1, fontWeight: 400 }
}))

export const useContactStatStyles = makeStyles((theme) => ({
  root: {
    width: "100%"
  },
  wrapper: {
    margin: theme.spacing(2),
    padding: theme.spacing(2)
  },
  times: {
    flexBasis: "15%"
  },
  button: {
    margin: "0 1em",
    position: "absolute",
    right: 0,
    bottom: 0
  },
  body: { margin: "10px" },
  close: {
    fontSize: "16px",
    fontWeight: 600,
    padding: "6px 16px",
    borderRadius: "5px",
    border: "1px solid black",
    color: "#f4511e",
    backgroundColor: "#ffffff",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#eeeeee"
    }
  },
  type: {},
  heading: {
    backgroundColor: "#fff"
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  },
  center: {
    textAlign: "center"
  },

  centerHead: {
    textAlign: "center",
    fontSize: "12px",
    whiteSpace: "nowrap"
  }
}))
