import React from "react"
import { statusMap } from "utils/status"

export const JobsTab: React.FC<{ data?: any }> = (props) => {
  const { data } = props
  return (
    <div className="jobs">
      <ul>
        {data.jobs.map((j: any) => {
          return (
            <li key={j.contactId + j.jobTagId} className="job">
              <a href={`/#/jobs/${j.jobTagId}`} target="_blank" rel="noreferrer">
                {j.jobTitle}
              </a>
              <span> - </span>
              {[j.status ? (statusMap as any)[j.status] : undefined, j.notes].filter((c) => c != null).join(" - ")}
            </li>
          )
        })}
      </ul>
    </div>
  )
}
