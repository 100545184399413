import axios from "axios"
import { requestResumeDatas, receiveResumeDatas, receiveError } from "./actions"
import { authHelper } from "../../components/ajouter-cv/subComponent/authHeader"

const filesAxiosUploader = axios.create()

export function FetchResumeDatas(clientTagId, jogTagId, resumeId) {
  return (dispatch) => {
    dispatch(requestResumeDatas())
    axios({
      url: `/contacts/resumes/${clientTagId}/${jogTagId}/${resumeId}`,
      method: "get"
    })
      .then((res) => {
        return dispatch(receiveResumeDatas(res.data))
      })
      .catch((err) => dispatch(receiveError(err)))
  }
}

export function PostCvInfo(service, endpoint, formObj = {}) {
  return new Promise((resolve, reject) => {
    return axios({
      method: "post",
      url: `${service != null ? `/${service}` : ""}${endpoint != null ? `/${endpoint}` : ""}`,
      responseType: "json",
      data: formObj,
      validateStatus(status) {
        return status < 300
      }
    })
      .then((res) => {
        if (res && !res.status) throw new Error("Network Error")
        resolve(res.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export function putCvInformation(service, endpoint, cvFileExtension = "json", formObj = {}) {
  return new Promise((resolve, reject) => {
    return filesAxiosUploader({
      method: "put",
      url: service + (endpoint != null ? `/${endpoint}` : ""),
      headers: authHelper.authHeader(cvFileExtension),
      responseType: "json",
      data: formObj,
      validateStatus(status) {
        return status < 300
      }
    })
      .then((res) => {
        if (res && !res.status) throw new Error("Network Error")
        resolve(res.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
