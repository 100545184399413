export const ShortSurveyList = [
  {
    id: 10830,
    name: "Déclenche - Sondage candidat Ancia - Préqualification"
  },
  {
    id: 10846,
    name: "Déclenche - Sondage candidat Duvaltex - Préqualification"
  },
  {
    id: 11742,
    name: "Déclenche - Sondage candidat Groupe M7 - Préqualification"
  },
  {
    id: 11744,
    name: "Déclenche - Sondage candidat Matra - Préqualification"
  },
  {
    id: 10842,
    name: "Déclenche - Sondage candidat Prevost - Préqualification"
  },
  {
    id: 10834,
    name: "Déclenche - Sondage candidat Résolu - Préqualification"
  },
  {
    id: 10838,
    name: "Déclenche - Sondage candidat STAS - Préqualification"
  }
]
export const LongSurveyList = [
  {
    id: 10828,
    name: "Déclenche - Sondage candidat Ancia - Processus complet"
  },
  {
    id: 10844,
    name: "Déclenche - Sondage candidat Duvaltex - Processus complet"
  },
  {
    id: 11748,
    name: "	Déclenche - Sondage candidat Groupe M7 - Processus complet"
  },
  {
    id: 11746,
    name: "	Déclenche - Sondage candidat Matra - Processus complet"
  },
  {
    id: 10840,
    name: "	Déclenche - Sondage candidat Prevost - Processus complet"
  },
  {
    id: 10832,
    name: "Déclenche - Sondage candidat Résolu - Processus complet"
  },
  {
    id: 10836,
    name: "Déclenche - Sondage candidat STAS - Processus complet"
  }
]
