import { Grid, InputLabel, TextField } from "@material-ui/core"
import React from "react"
import { useTranslation } from "react-i18next"
import { useQueryJobs } from "api/hooks"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { Controller } from "react-hook-form"
import { Link as RouterLink } from "react-router-dom"
import { ModalWrapper } from "../../components/modal/modalWrapper"

interface IContactDetailsModalProps {
  row: any
  opened: boolean
  toggle: () => void
}

export const ContactDetailsModal: React.FunctionComponent<IContactDetailsModalProps> = (props) => {
  const { row, opened, toggle } = props
  const { t: translate } = useTranslation()
  const { data: jobs } = useQueryJobs()

  const formattedTags = (row?.tags || []).reduce((acc: any[], jobTagId: string) => {
    const elt = (jobs || []).find((job: any) => job.jobTagId === jobTagId)
    if (elt) {
      acc = acc.concat(elt)
    }
    return acc
  }, [])

  return (
    <ModalWrapper
      defaultValues={{
        emails: row.emails,
        firstName: row.firstName,
        lastName: row.lastName
      }}
      title={translate("Contact.Details")}
      opened={opened}
      toggle={toggle}
      saveText=" "
    >
      {({ formState: { errors }, control }) => (
        <>
          <div className="content" style={{ paddingBottom: 0 }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <div className="form-input">
                  <InputLabel className="input-label" htmlFor="firstName">
                    {translate("Contact.Fields.FirstName")}
                  </InputLabel>
                  <Controller
                    name="firstName"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <TextField className={errors?.firstName ? "is-invalid" : ""} {...field} fullWidth disabled type="text" variant="outlined" />
                    )}
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="form-input">
                  <InputLabel className="input-label" htmlFor="lastName">
                    {translate("Contact.Fields.LastName")}
                  </InputLabel>
                  <Controller
                    name="lastName"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <TextField className={errors?.lastName ? "is-invalid" : ""} {...field} fullWidth disabled type="text" variant="outlined" />
                    )}
                  />
                </div>
              </Grid>
            </Grid>

            <div className="form-input">
              <InputLabel className="input-label" htmlFor="email">
                {translate("Contact.Fields.Emails")}
              </InputLabel>
              <Controller
                name="email"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Autocomplete
                    multiple
                    disabled
                    id="multiple-limit-tags"
                    options={row.emails}
                    getOptionLabel={(option) => option}
                    defaultValue={[...(row.emails || [])]}
                    renderInput={(params) => <TextField {...params} variant="outlined" disabled />}
                  />
                )}
              />
            </div>

            <div className="form-input">
              <InputLabel className="input-label">{translate("Contact.Fields.Jobs")}</InputLabel>
              {!formattedTags?.length && <p>{translate("Contact.Fields.NoJobs")}</p>}
              {formattedTags?.length > 0 && (
                <ul>
                  {formattedTags &&
                    formattedTags.map((item: any) => (
                      <li key={`${item.jobTagId}`}>
                        <RouterLink onClick={toggle} to={`/jobs/${item.jobTagId}?q=${row.contactName}`}>
                          {item.jobTitle}
                        </RouterLink>
                      </li>
                    ))}
                </ul>
              )}
            </div>
          </div>
        </>
      )}
    </ModalWrapper>
  )
}
