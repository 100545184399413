import moment from "moment"

export function saveNote(name: string, Id: string, jobTagId: string, note: string) {
  const oldNote = localStorage.getItem(`prequalif-${Id}-${jobTagId}`)

  const dateAndTime = moment().format("YYYYMMDDhhmm")

  let currentNote = { [dateAndTime]: { [name]: note } }
  if (oldNote != null) {
    const old = JSON.parse(oldNote)
    const oldDateAndTime = old[dateAndTime]
    if (oldDateAndTime) {
      Object.assign(oldDateAndTime, { [name]: note })
      currentNote = old
    } else {
      currentNote = { ...old, ...currentNote }
    }
  }

  localStorage.setItem(`prequalif-${Id}-${jobTagId}`, JSON.stringify(currentNote))
}
