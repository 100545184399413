import moment from "moment"

export const monthList = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"]

export function formatDate(date?: string | Date) {
  if (!date) {
    return "N/A"
  }
  return moment(date).format("YYYY-MM-DD HH:mm")
}
