import React, { useEffect, useMemo, useState } from "react"
import { Card, Grid } from "@material-ui/core"
import { IStatParams } from "api/dto"
import { useQueryStats } from "api/hooks"
import { SearchContainer } from "components/stats/searchContainer"
import { StatsTable } from "components/stats/statsTable"
import moment from "moment"
import { StatsAggregates } from "components/stats/aggregates/statsAggregates"
import { useStyles } from "./styles"

export const GlobalStats = () => {
  const classes = useStyles()
  const [jobId, setJobId] = useState<string | undefined>()
  const [contactId, setContactId] = useState<string | undefined>()
  const [type, setType] = useState<string | undefined>()

  const defaultStart = useMemo(() => moment().subtract(30, "days").toISOString(), [])
  const [startSearch, setStartSearch] = useState(false)

  const [range, setRange] = useState<{ start: string; end?: string }>({ start: defaultStart })
  const [params, setParams] = useState<IStatParams>({ start: defaultStart })

  const { data, isLoading, isFetching, refetch: search } = useQueryStats(params)

  useEffect(() => {
    if (startSearch) {
      setParams({ ...range, jobId, contactId, type })
      setStartSearch(false)
    }
  }, [startSearch, setStartSearch, range, jobId, contactId, type])

  useEffect(() => {
    search()
  }, [params])

  return (
    <div className={classes.root}>
      <Grid container item xs={12} spacing={4} justifyContent="space-between">
        <Grid item xs={5}>
          <Card>
            <SearchContainer
              range={range}
              setRange={setRange}
              setContactId={setContactId}
              setJobId={setJobId}
              setType={setType}
              search={() => setStartSearch(true)}
            />
          </Card>
        </Grid>
        <Grid item xs={7}>
          <Card>
            <StatsAggregates stats={data || []} />
          </Card>
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={2}>
        <Grid item xs={12}>
          <StatsTable stats={data} isLoadingStats={isLoading || isFetching} />
        </Grid>
      </Grid>
    </div>
  )
}
