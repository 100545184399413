import React from "react"
import { useSelector } from "react-redux"
import { Route, Redirect, useLocation } from "react-router"

interface IRestrictedRouteProps {
  component: any
  path: string
  needAdminRights?: boolean
  exact?: boolean
}

export const RestrictedRoute: React.FC<IRestrictedRouteProps> = (props) => {
  const { component: Component, path, exact } = props
  const { pathname } = useLocation()
  const user = useSelector((state: any) => state.auth)

  if (user.isLoggedIn && pathname === "/login") {
    return <Redirect to={{ pathname: "/" }} />
  }

  if (!user.isLoggedIn) {
    return <Redirect to={{ pathname: "/login" }} />
  }

  return <Route exact={exact} path={path} render={(r) => (user ? <Component {...r} /> : <Redirect to={{ pathname: "/login" }} />)} />
}
