import axios from "axios"
import { requestContactsDatas, receiveContactsDatas, sendPostJobsDatas, receiveError } from "./actions"

export function fetchContacts(path) {
  return (dispatch) => {
    dispatch(requestContactsDatas())
    axios({
      url: `/contacts${path || ""}`,
      method: "get"
    })
      .then((res) => dispatch(receiveContactsDatas(res.data)))
      .catch((err) => {
        dispatch(receiveError(err))
      })
  }
}

// TODO => useMutate && add toasting toast
export function postJobsDatas(value, auth, contacts, contactId, jobId) {
  return (dispatch) => {
    const mynewDatas = []
    const cleanDatas = contacts.contactsList.map((el) => {
      if (el.Id !== contactId) {
        return { ...el }
      }
      return { ...value[0] }
    })
    cleanDatas.forEach((el) => {
      if (el !== undefined) {
        return mynewDatas.push(el)
      }
    })

    dispatch(sendPostJobsDatas)
    axios
      .post(`/contacts/${contactId}/jobs/${jobId}`, ...value[0].jobs)
      .then(() => {
        dispatch(receiveContactsDatas(cleanDatas))
      })
      .catch((err) => {
        dispatch(receiveError(err))
      })
  }
}
