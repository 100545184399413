import * as moment from "moment"
import "moment/locale/fr-ca"

export const parse = (e) => JSON.parse(e)
export const stringify = (e) => JSON.stringify(e)

export const normalizeDate = (e) => moment(e.substring(0, e.indexOf(":"))).format("LLLL")

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export const allRecrutoContacts = ["Michel rouleau", "Danielle Bernard", "Maude Gilbert", "Olivier Tardif"]

export const myColumnArrays = [
  "SOURCING",
  "B_TO_SORT",
  "C_ENTREVUE_TELEPHONIQUE",
  "D_DOCUMENTS",
  "E_ENTREVUE_PARTENAIRE",
  "F_ENTREVUE_CLIENT",
  "G_REFERENCES",
  "H_TESTS",
  "I_STAND_BY",
  "J_PRET_A_LEMBAUCHE",
  "K_EMBAUCHE",
  "L_DESISTEMENT",
  "M_REJETE",
  "N_Transfert_autre_affichage",
  "O_JOB_FILLED"
]

export default { myColumnArrays }
