import { combineReducers } from "redux"
import { connectRouter } from "connected-react-router"
import authReducer from "./auth/reducers"
import muiReducer from "./mui/reducers"
import jobReducer from "./jobs/reducers"
import contactReducer from "./contact/reducers"
import cvReducer from "./cv/reducers"
import sovrenReducer from "./sovren/reducers"
import infuContactReducer from "./infuContact/reducers"
import actionReducer from "./action/reducers"

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    auth: authReducer,
    jobs: jobReducer,
    contacts: contactReducer,
    action: actionReducer,
    infuContact: infuContactReducer,
    sovren: sovrenReducer,
    cv: cvReducer,
    mui: muiReducer
  })
