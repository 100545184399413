import React from "react"
import clsx from "clsx"

import { Bubble } from "./animation"
import useStyles from "./styles"

const LoginWrapper = (props) => {
  const { children, className, ...rest } = props
  const classes = useStyles()

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      {children}
      <Bubble />
    </div>
  )
}

export default LoginWrapper
