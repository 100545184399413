/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import IconButton from "@material-ui/core/IconButton"
import PropTypes from "prop-types"
import * as React from "react"
/* eslint-enable no-unused-vars */
import GetAppIcon from "@material-ui/icons/GetApp"
import { CSVLink } from "react-csv"
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles"
import { withTranslation } from "react-i18next"
import { statusMap } from "utils/status"
import { withHooksHOC } from "./hookInsideGroupeRow"

const styles = (theme) =>
  createStyles({
    button: {
      right: "1rem",
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      lineHeight: 0
    }
  })
interface IHooksHOCProps {
  currentJobId: string;
  translate: (key: string) => void;
}

class GroupedRow extends React.Component<IHooksHOCProps> {
  constructor(props) {
    super(props)
    this.state = {
      header: [
        { label: "Status", key: "currentJob.status" },
        { label: "Category", key: "currentJob.rejectedCategory" },
        { label: "Id", key: "Id" },
        { label: "ContactName", key: "ContactName" },
        { label: "Notes", key: "currentJob.rejectedDisclaimerNotes" },
        { label: "Date", key: "currentJob.modifiedAt" },
        { label: "cvOrigin", key: "currentJob.cvOrigin" }
      ],
      data: [],
      fileName: ""
    }
  }

  componentDidMount() {
    const { groupData } = this.props
    const date = new Date()
    const dayMonthYear = ` ${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
    const remakeGroupedDatas = JSON.parse(JSON.stringify(groupData.data))

    for (let i = 0; i < remakeGroupedDatas.length; i++) {
      remakeGroupedDatas[i].currentJob.status = this.props.translate(`Job.Status.${remakeGroupedDatas[i].currentJob.status}`)
    }

    remakeGroupedDatas.sort((a, b) => {
      return a.currentJob.modifiedAt < b.currentJob.modifiedAt ? -1 : a.currentJob.modifiedAt > b.currentJob.modifiedAt ? 1 : 0
    })

    this.setState({
      data: remakeGroupedDatas,
      fileName: `${this.props.translate(`Job.Status.${groupData.data[0].currentJob.status}`)} : ${
        groupData.data[0].currentJob.jobTitle
      } ${dayMonthYear}`
    })
  }

  rotateIconStyle = (isOpen) => ({
    transform: isOpen ? "rotate(90deg)" : "none"
  })

  render() {
    const { classes } = this.props

    let colSpan = this.props.columns.filter((columnDef) => !columnDef.hidden).length
    this.props.options.selection && colSpan++
    this.props.detailPanel && colSpan++
    this.props.actions && this.props.actions.length > 0 && colSpan++
    const column = this.props.groups[this.props.level]
    const date = new Date()
    const dayMonthYear = ` ${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`

    const exportData = (currentStatus) => {
      const arrayColumn = ["Status", "Category", "Id", "ContactName", "Notes", "Date"]
      const status = this.props.translate(`Job.Status.${currentStatus}`)
      const builder = new CsvBuilder(
        `${this.props.groupData.data[0].currentJob.status} : ${this.props.groupData.data[0].currentJob.jobTitle} ${dayMonthYear}`
      )
      builder
        .setColumns(arrayColumn)
        .addRows(
          this.props.groupData.data.map((d) =>
            Object.values({
              Status: status,
              Category: d.currentJob.rejectedCategory,
              Id: d.Id,
              ContactName: d.ContactName,
              Notes: d.currentJob.rejectedDisclaimerNotes,
              Date: d.currentJob.modifiedAt
            })
          )
        )
        .exportFile()
    }

    let detail
    if (this.props.groupData.isExpanded) {
      if (this.props.groups.length > this.props.level + 1) {
        // Is there another group
        detail = this.props.groupData.groups.map((groupData, index) => (
          <this.props.components.GroupRow
            actions={this.props.actions}
            key={groupData.value || `${index}`}
            columns={this.props.columns}
            components={this.props.components}
            detailPanel={this.props.detailPanel}
            getFieldValue={this.props.getFieldValue}
            groupData={groupData}
            groups={this.props.groups}
            icons={this.props.icons}
            level={this.props.level + 1}
            path={[...this.props.path, index]}
            onGroupExpandChanged={this.props.onGroupExpandChanged}
            onRowSelected={this.props.onRowSelected}
            onRowClick={this.props.onRowClick}
            onToggleDetailPanel={this.props.onToggleDetailPanel}
            onTreeExpandChanged={this.props.onTreeExpandChanged}
            onEditingCanceled={this.props.onEditingCanceled}
            onEditingApproved={this.props.onEditingApproved}
            options={this.props.options}
            hasAnyEditingRow={this.props.hasAnyEditingRow}
            isTreeData={this.props.isTreeData}
            celleditable={this.props.celleditable}
            onCellEditStarted={this.props.onCellEditStarted}
            onCellEditFinished={this.props.onCellEditFinished}
            scrollWidth={this.props.scrollWidth}
          />
        ))
      } else {
        detail = this.props.groupData.data.map((rowData, index) => {
          if (rowData.tableData.editing) {
            return (
              <this.props.components.EditRow
                columns={this.props.columns}
                components={this.props.components}
                data={rowData}
                icons={this.props.icons}
                path={[...this.props.path, index]}
                localization={this.props.localization}
                key={index}
                mode={rowData.tableData.editing}
                options={this.props.options}
                isTreeData={this.props.isTreeData}
                detailPanel={this.props.detailPanel}
                onEditingCanceled={this.props.onEditingCanceled}
                onEditingApproved={this.props.onEditingApproved}
                getFieldValue={this.props.getFieldValue}
                onBulkEditRowChanged={this.props.onBulkEditRowChanged}
                scrollWidth={this.props.scrollWidth}
              />
            )
          }
          return (
            <this.props.components.Row
              actions={this.props.actions}
              key={index}
              columns={this.props.columns}
              components={this.props.components}
              data={rowData}
              detailPanel={this.props.detailPanel}
              getFieldValue={this.props.getFieldValue}
              icons={this.props.icons}
              path={[...this.props.path, index]}
              onRowSelected={this.props.onRowSelected}
              onRowClick={this.props.onRowClick}
              onToggleDetailPanel={this.props.onToggleDetailPanel}
              options={this.props.options}
              isTreeData={this.props.isTreeData}
              onTreeExpandChanged={this.props.onTreeExpandChanged}
              onEditingCanceled={this.props.onEditingCanceled}
              onEditingApproved={this.props.onEditingApproved}
              hasAnyEditingRow={this.props.hasAnyEditingRow}
              celleditable={this.props.celleditable}
              onCellEditStarted={this.props.onCellEditStarted}
              onCellEditFinished={this.props.onCellEditFinished}
              scrollWidth={this.props.scrollWidth}
            />
          )
        })
      }
    }
    const freeCells = []
    for (let i = 0; i < this.props.level; i++) {
      freeCells.push(<TableCell padding="checkbox" key={i} />)
    }

    let { value } = this.props.groupData
    if (column.lookup) {
      value = column.lookup[value]
    }

    let { title } = column
    if (typeof this.props.options.groupTitle === "function") {
      title = this.props.options.groupTitle(this.props.groupData)
    } else if (typeof title !== "string") {
      title = React.cloneElement(title)
    }

    const separator = this.props.options.groupRowSeparator || ": "

    return (
      <>
        <TableRow>
          {freeCells}
          <this.props.components.Cell
            style={{ position: "relative" }}
            colSpan={colSpan}
            padding="none"
            columnDef={column}
            value={`${value} - (${this.props.groupData.data.length})`}
            icons={this.props.icons}
          >
            {this.props.groupData.data[0].currentJob.status === "L_DESISTEMENT" || this.props.groupData.data[0].currentJob.status === "M_REJETE" ? (
              <>
                <CSVLink data={this.state.data} className={classes.button} filename={this.state.fileName} headers={this.state.header}>
                  <GetAppIcon style={{ color: "#fff" }} />
                </CSVLink>
              </>
            ) : (
              ""
            )}

            <IconButton
              style={{
                transition: "all ease 200ms",
                ...this.rotateIconStyle(this.props.groupData.isExpanded)
              }}
              onClick={() => {
                this.props.onGroupExpandChanged(this.props.path)
              }}
            >
              <this.props.icons.DetailPanel />
            </IconButton>
            <b>
              {`${title}`}
              {separator}
            </b>
          </this.props.components.Cell>
        </TableRow>
        {detail}
      </>
    )
  }
}

GroupedRow.defaultProps = {
  columns: [],
  groups: [],
  options: {},
  level: 0
}

GroupedRow.propTypes = {
  actions: PropTypes.array,
  columns: PropTypes.arrayOf(PropTypes.object),
  components: PropTypes.object,
  detailPanel: PropTypes.oneOfType([PropTypes.func, PropTypes.arrayOf(PropTypes.object)]),
  getFieldValue: PropTypes.func,
  groupData: PropTypes.object,
  groups: PropTypes.arrayOf(PropTypes.object),
  hasAnyEditingRow: PropTypes.bool,
  icons: PropTypes.object,
  isTreeData: PropTypes.bool.isRequired,
  level: PropTypes.number,
  localization: PropTypes.object,
  onGroupExpandChanged: PropTypes.func,
  onRowSelected: PropTypes.func,
  onRowClick: PropTypes.func,
  onToggleDetailPanel: PropTypes.func.isRequired,
  onTreeExpandChanged: PropTypes.func.isRequired,
  onEditingCanceled: PropTypes.func,
  onEditingApproved: PropTypes.func,
  options: PropTypes.object,
  path: PropTypes.arrayOf(PropTypes.number),
  scrollidth: PropTypes.number,
  celleditable: PropTypes.object,
  onCellEditStarted: PropTypes.func,
  onCellEditFinished: PropTypes.func,
  onBulkEditRowChanged: PropTypes.func
}

export default withStyles(styles, { withTheme: true })(withHooksHOC(GroupedRow))
