import { RECEIVE_SOVREN, RECEIVE_SOVREN_MATCH, RECEIVE_ERROR, REQUEST_SOVREN_DATAS_MATCH, REQUEST_SOVREN_DATAS } from "./actions"

const initialState = {
  isFetchingSovrenMatch: false,
  hasErrorSovren: false,
  sovrenDatas: {},
  sovrenMatch: {}
}

function sovrenReducer(state = initialState, sovren) {
  switch (sovren.type) {
    case REQUEST_SOVREN_DATAS:
      return {
        ...state,
        sovren: {},
        isFetching: true
      }

    case REQUEST_SOVREN_DATAS_MATCH:
      return {
        ...state,
        sovrenMatch: {},
        isFetchingSovrenMatch: true
      }
    case RECEIVE_SOVREN:
      return {
        ...state,
        sovrenDatas: sovren.json,
        isFetchingSovrenMatch: false,
        hasErrorSovren: false
      }
    case RECEIVE_SOVREN_MATCH:
      return {
        ...state,
        sovrenMatch: sovren.json,
        isFetchingSovrenMatch: false,
        hasErrorSovren: false
      }
    case RECEIVE_ERROR:
      return {
        ...state,
        hasErrorSovren: true,
        isFetchingSovrenMatch: false
      }
    default:
      return state
  }
}

export default sovrenReducer
