import palette from "../palette"
import typography from "../typography"

export default {
  root: {
    ...typography.body1,
    borderBottom: `1px solid ${palette.black.light}`,
    fontWeight: 400,
    "& a": {
      color: palette.black.charcoal,
      textDecoration: "none",
      fontWeight: 400,
      "&:hover": {
        color: palette.secondary.main
      }
    },
    "& span": {
      fontWeight: 600
    }
  }
}
