import { TextField } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import { useQueryJobs } from "api/hooks"
import { useMutationUpdateContactAddJobs } from "api/hooks/update"
import { ModalWrapper } from "components/modal/modalWrapper"
import React, { useMemo } from "react"
import { Controller } from "react-hook-form"
import { useTranslation } from "react-i18next"

interface IAddTagToCandidateModalProps {
  row: any
  opened: boolean
  toggle: () => void
}

export const AddTagToCandidateModal: React.FunctionComponent<IAddTagToCandidateModalProps> = (props) => {
  const { row, opened, toggle } = props
  const { t: translate } = useTranslation()
  const { data: jobs, isLoading: isLoadingJobs } = useQueryJobs()
  const { mutateAsync: addTags } = useMutationUpdateContactAddJobs(row.Id, [])
  const excludedIds = (row.jobs || []).map((job: any) => job.jobTagId)
  const currentJobs = useMemo(() => (jobs as { jobTitle: string; jobTagId: string }[]).filter((j) => !excludedIds.includes(j.jobTagId)), [jobs, row])

  const onSubmit = async (data: any) => {
    await addTags((data.jobs || []).map((job: any) => job.jobTagId))
    toggle()
  }

  return (
    <ModalWrapper onSubmit={onSubmit} title={translate("Job.AddToCandidate")} saveText={translate("Common.Add")} opened={opened} toggle={toggle}>
      {({ control }) => (
        <Controller
          name="jobs"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Autocomplete
              multiple
              disabled={isLoadingJobs}
              options={currentJobs || []}
              style={{ marginTop: "10px" }}
              getOptionLabel={(option) => option.jobTitle}
              getOptionSelected={(option, v) => option.jobTitle === v.jobTitle}
              renderInput={(params) => <TextField {...params} label={translate("Job.SelectJobToAddToCandidate")} variant="outlined" />}
              {...field}
              onChange={(_, data) => field.onChange(data)}
            />
          )}
        />
      )}
    </ModalWrapper>
  )
}
