import React, { useCallback, useEffect, useState } from "react"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import { Button, CircularProgress, Dialog, FormControl, Grid, InputLabel, MenuItem, Select } from "@material-ui/core"
import { FormProvider, useForm } from "react-hook-form"
import { IStatsDto } from "api/dto/IStatsDto"
import { useMutateAddStat } from "api/hooks/add"
import { useTranslation } from "react-i18next"
import { showSuccessToast } from "utils/toast"
import { isEmpty } from "lodash"
import { useMultiStepsModal } from "hooks/useMultiStepsModal"
import { useMutationUpdateContactAddTags } from "api/hooks/update"
import { useStyleStats } from "./styles"
import Stats, { defaultTemplates } from "./stats"
import { ShortSurveyList, LongSurveyList } from "../survey/surveyDatas"

export const MultiStepsModal: React.FunctionComponent = () => {
  const { isOpen, job: currentJob, contactName, close, step, setStep, rowData, tempStatusFromNotes } = useMultiStepsModal()
  const [isBillable, setIsBilabble] = useState(false)
  const { mutateAsync } = useMutateAddStat()
  const [isLoading, setIsLoading] = useState(false)
  const [shortSurvey, setShortSurvey] = React.useState("")
  const [longSurvey, setLongSurvey] = React.useState("")
  const classes = useStyleStats()
  const { t: translate } = useTranslation()
  const { mutateAsync: addTags } = useMutationUpdateContactAddTags(currentJob ? currentJob.contactId : "", [])
  const methods = useForm({ reValidateMode: "onChange" })
  const { handleSubmit, reset } = methods
  useEffect(() => {
    if (!isOpen) {
      reset()
    }
  }, [isOpen, reset])

  const handleChangeShortSurvey = (event) => {
    setLongSurvey("")
    setShortSurvey(event.target.value.toString())
  }

  const handleChangeLongSurvey = (event) => {
    setShortSurvey("")
    setLongSurvey(event.target.value.toString())
  }

  const handleReset = () => {
    setShortSurvey("")
    setLongSurvey("")
    setStep(1)
    setIsLoading(false)
  }

  const cleanObject = useCallback((obj: any) => {
    for (const propName in obj) {
      if (obj[propName] === false) {
        delete obj[propName]
      }
    }
    return obj
  }, [])

  const onSubmit = async (data: any) => {
    if (step === 1) {
      if (!currentJob) {
        return
      }

      const dataClean = cleanObject(data)
      const promises: any[] = []
      const clientTagId = currentJob.clientTagId ? currentJob.clientTagId : "1816"

      if (!isEmpty(data)) {
        defaultTemplates.forEach((type) => {
          if (type in dataClean) {
            const statsDto: IStatsDto = {
              clientId: clientTagId,
              contactId: currentJob.contactId,
              jobId: currentJob.jobTagId,
              status: currentJob.status,
              type,
              jobName: currentJob.jobTitle || "N/A",
              contactName: contactName || "N/A"
            }
            promises.push(mutateAsync(statsDto))
          }
        })
        await Promise.all(promises)
        showSuccessToast(translate("Statistics.AddStatsSuccess"))
      }
      if (
        tempStatusFromNotes === "K_EMBAUCHE" ||
        currentJob.status === "K_EMBAUCHE" ||
        tempStatusFromNotes === "M_REJETE" ||
        currentJob.status === "M_REJETE" ||
        tempStatusFromNotes === "L_DESISTEMENT" ||
        currentJob.status === "L_DESISTEMENT"
      ) {
        setStep(step + 1)
        // close()
      } else {
        close()
        handleReset()
      }
    } else {
      setIsLoading(true)
      await addTags([shortSurvey !== "" ? shortSurvey : longSurvey])

      close()
      handleReset()
    }
  }

  return (
    <FormProvider {...methods}>
      <Dialog open={isOpen} fullWidth maxWidth="md" aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        {step === 1 ? (
          <>
            <DialogTitle id="alert-dialog-title">Activités de recrutement pour statistiques</DialogTitle>
            <form className={classes.statsContainer} onSubmit={handleSubmit(onSubmit)}>
              <DialogContent>
                <div>
                  <Grid container item lg={12}>
                    {defaultTemplates.map((type) => {
                      return (
                        <>
                          {!type.includes("Billable") && (
                            <Grid xs={6} key={`${type}`} spacing={2}>
                              <Stats type={type} />
                            </Grid>
                          )}
                        </>
                      )
                    })}
                  </Grid>
                  <Button
                    variant="outlined"
                    color="primary"
                    className={`${classes.close} ${classes.button}`}
                    onClick={() => setIsBilabble(!isBillable)}
                  >
                    {translate("Common.Billable")}
                  </Button>

                  <Grid container lg={12}>
                    {defaultTemplates.map((type) => {
                      return (
                        <>
                          {type.includes("Billable") && isBillable && (
                            <Grid xs={6} key={`${type}`} spacing={2}>
                              <Stats type={type} />
                            </Grid>
                          )}
                        </>
                      )
                    })}
                  </Grid>
                </div>
              </DialogContent>
              <DialogActions className={classes.buttonContainer}>
                {tempStatusFromNotes === "K_EMBAUCHE" || tempStatusFromNotes === "M_REJETE" || tempStatusFromNotes === "L_DESISTEMENT" ? (
                  <Button className={`${classes.save} ${classes.button}`} color="primary" type="submit">
                    {translate("Common.Next")}
                  </Button>
                ) : (
                  <>
                    {!isLoading ? (
                      <>
                        <Button className={`${classes.save} ${classes.button}`} color="secondary" onClick={() => close()}>
                          {translate("Common.Close")}
                        </Button>
                        <Button className={`${classes.save} ${classes.button}`} color="primary" type="submit">
                          {translate("Common.Send")}
                        </Button>
                      </>
                    ) : (
                      <CircularProgress />
                    )}
                  </>
                )}
              </DialogActions>
            </form>
          </>
        ) : (
          <>
            <form onSubmit={handleSubmit(onSubmit)}>
              <DialogTitle id="alert-dialog-title">Sondage Experience Candidat</DialogTitle>

              <DialogContent>
                <Grid className={classes.SurveyContainer} item xs={12} sm container>
                  <span className={classes.SurveyTitle}>Sondage Court</span>
                  <span className={classes.SurveyDescription}>
                    À utiliser lorsque le candidat(e) a eu une préqualification téléphonique mais pas rencontré le PA
                  </span>

                  <FormControl variant="filled" fullWidth className={classes.formControl}>
                    <InputLabel id="label">Sélectionner le sondage à envoyer</InputLabel>
                    <Select labelId="label" id="select" value={shortSurvey} onChange={handleChangeShortSurvey}>
                      {ShortSurveyList.map((survey) => (
                        <MenuItem key={survey.id} value={survey.id}>
                          {survey.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                {rowData?.Tags.includes("275") && (
                  <Grid className={classes.SurveyContainer} item xs={12} sm container>
                    <span className={classes.SurveyTitle}>Sondage Long</span>
                    <span className={classes.SurveyDescription}>
                      À utiliser lorsque le candidat(e) a été en entrevue avec le Partenaire ou le client{" "}
                    </span>

                    <FormControl variant="filled" fullWidth className={classes.formControl}>
                      <InputLabel id="label">Sélectionner le sondage à envoyer</InputLabel>
                      <Select labelId="label" id="select" value={longSurvey} onChange={handleChangeLongSurvey}>
                        {LongSurveyList.map((survey) => (
                          <MenuItem key={survey.id} value={survey.id}>
                            {survey.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
              </DialogContent>

              <DialogActions className={classes.buttonContainer}>
                {!isLoading ? (
                  <>
                    <Button className={`${classes.save} ${classes.button}`} color="secondary" onClick={() => close()}>
                      {translate("Common.Close")}
                    </Button>
                    <Button className={`${classes.save} ${classes.button}`} color="primary" type="submit">
                      {translate("Common.Send")}
                    </Button>
                  </>
                ) : (
                  <CircularProgress />
                )}
              </DialogActions>
            </form>
          </>
        )}
      </Dialog>
    </FormProvider>
  )
}
