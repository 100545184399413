import axios from "axios"
import { postAction, receiveActionResponse, receiveError } from "./actions"

export function postActionInfusion(userId, ActionId) {
  return (dispatch) => {
    dispatch(postAction())
    axios({
      url: `/contacts/${userId}/actions/${ActionId}`,
      method: "post"
    })
      .then((res) => {
        return dispatch(receiveActionResponse(res.data))
      })
      .catch((err) => dispatch(receiveError(err)))
  }
}

export function postActionInfusionDel(userId, ActionId) {
  return (dispatch) => {
    dispatch(postAction())
    axios({
      url: `/contacts/${userId}/actions/${ActionId}`,
      method: "DELETE"
    })
      .then((res) => {
        return dispatch(receiveActionResponse(res.data))
      })
      .catch((err) => dispatch(receiveError(err)))
  }
}
