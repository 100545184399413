import React from "react"
import { TextField } from "@material-ui/core"

const ValidateField = ({
  applicationData,
  setApplicationData,
  applicationElements,
  labelName,
  addressSuite,
  requireField,
  applicationDataAddress
}) => {
  const [addressSuit, setAddressSuit] = React.useState(applicationDataAddress ? applicationDataAddress[addressSuite] : "")

  if (applicationElements.includes("address")) {
    if (addressSuite === "postalCode") {
      return (
        <TextField
          required={requireField}
          id="outlined-required"
          label={`${labelName}`}
          variant="outlined"
          fullWidth
          value={addressSuit}
          onChange={(e) => {
            const postalValueSplit = e.target.value
              .toUpperCase()
              .replace(/\W/g, "")
              .replace(/(...)/, "$1 ") // "M5R 2G3"
              .toString()

            const splitPostalVal = postalValueSplit.slice(0, 7)

            setAddressSuit(splitPostalVal)
            applicationDataAddress[addressSuite] = splitPostalVal
            setApplicationData(applicationData)
          }}
        />
      )
    }
    return (
      <TextField
        required={requireField}
        id="outlined-required"
        label={`${labelName}`}
        variant="outlined"
        fullWidth
        defaultValue={addressSuit}
        onChange={(e) => {
          applicationDataAddress[addressSuite] = e.target.value
          setApplicationData(applicationData)
        }}
      />
    )
  }
  return (
    <TextField
      required={requireField}
      id="outlined-required"
      label={`${labelName}`}
      variant="outlined"
      fullWidth
      defaultValue={applicationData[applicationElements]}
      onChange={(e) => {
        applicationData[applicationElements] = e.target.value
        setApplicationData(applicationData)
      }}
    />
  )
}

export default ValidateField
