import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { Switch, FormControlLabel, FormGroup } from "@material-ui/core"
import useNavigation from "hooks/useNavigation"
import { useTranslation } from "react-i18next"
import { setMuiGroupingStatus } from "../../../redux/mui/actions"

export const GroupBy: React.FC = () => {
  const { location } = useNavigation()
  const { t: translate } = useTranslation()
  const mui = useSelector((state) => (state as any).mui)
  const dispatch = useDispatch()
  const { status } = mui.grouping

  return (
    <FormGroup>
      <FormControlLabel
        style={{
          display: location.pathname.includes("/contacts") ? "none" : "flex"
        }}
        control={
          <Switch
            checked={status}
            onChange={() => {
              dispatch(setMuiGroupingStatus(!status))
            }}
            value="status"
          />
        }
        label={<div className="groupBy">{translate("Status.GroupBy")}</div>}
      />
    </FormGroup>
  )
}
