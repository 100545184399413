/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useMemo } from "react"
import { Form, Field } from "react-final-form"
import { TextField } from "final-form-material-ui"
import { Typography, Paper, Grid } from "@material-ui/core"
import InputAdornment from "@material-ui/core/InputAdornment"
import AccountCircle from "@material-ui/icons/AccountCircle"
import clsx from "clsx"
import { makeStyles } from "@material-ui/core/styles"
import AppBar from "@material-ui/core/AppBar"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import Box from "@material-ui/core/Box"
import List from "@material-ui/core/List"
import Divider from "@material-ui/core/Divider"
import ListItem from "@material-ui/core/ListItem"
import { generateContactFields } from "api/mapping/ContactMapper"
import { useContactJobs } from "utils/jobs"
import { ITagDto, TagCategory } from "api/dto/ITagDto"
import { useTranslation } from "react-i18next"
import { useStyles } from "./styles"
import { DatePickerWrapper } from "./components"

const TabPanel: React.FC<{ className: string; index: number; value: any }> = (props: any) => {
  const { children, className, value, index, ...other } = props

  return (
    <div
      className={className}
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      <Box p={3}>
        <Typography component="div">{children}</Typography>
      </Box>
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`
  }
}

const useStylesForTag = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper
  },
  disabled: {}
}))

const validate = () => {
  const errors = {}
  return errors
}

export const Informations: React.FC<{ rowData: any; tags: ITagDto[] }> = ({ rowData, tags }) => {
  const classes = useStyles()
  const classesTabs = useStylesForTag()
  const [tabIndex, setTabIndex] = React.useState(2)
  const fields = useMemo(() => generateContactFields(), [])
  const { t: translate } = useTranslation()
  const { jobs, Tags } = rowData
  const { currentJob } = useContactJobs(jobs)

  const onSubmit = async () => {
    // console.log("submit")
  }

  return (
    <div className={clsx(classesTabs.root, "panel")}>
      <div className={classes.root}>
        <AppBar position="static" color="default">
          <Tabs
            value={tabIndex}
            onChange={(e, newValue) => setTabIndex(newValue)}
            scrollButtons="on"
            indicatorColor="primary"
            textColor="primary"
            aria-label="scrollable force tabs example"
            centered
            variant="fullWidth"
          >
            <Tab label="Coordonnées" {...a11yProps(0)} />
            <Tab className="tabs" label="Tags" {...a11yProps(1)} />
            <Tab className="autres-candidatures" label="Autres Candidatures" {...a11yProps(2)} />
          </Tabs>
        </AppBar>
        <TabPanel className={tabIndex === 0 ? classes.tab : ""} value={tabIndex} index={0}>
          <Grid item xs={12} sm={12}>
            <Form
              onSubmit={onSubmit}
              initialValues={{ ...rowData }}
              validate={validate}
              render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit} noValidate>
                  <Paper className={classes.wrapper}>
                    <Grid container alignItems="flex-start" spacing={2}>
                      <Grid item xs={2} sm={1}>
                        <Field
                          fullWidth
                          name={fields.id}
                          component={TextField}
                          type="text"
                          label="Id"
                          rows="1"
                          disabled
                          InputProps={{
                            classes: {
                              disabled: classesTabs.disabled
                            }
                          }}
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item xs={6} sm={4}>
                        <Field
                          fullWidth
                          name={fields.contactName}
                          component={TextField}
                          type="text"
                          id="information-contact-name"
                          label={`${translate("Contact.Fields.FullName")}`}
                          rows="1"
                          disabled
                          InputProps={{
                            classes: {
                              disabled: classesTabs.disabled
                            },
                            startAdornment: (
                              <InputAdornment position="start">
                                <AccountCircle color="action" />
                              </InputAdornment>
                            )
                          }}
                        />
                      </Grid>
                      <Grid item xs={4} sm={3}>
                        <Field
                          fullWidth
                          name={fields.postalCode}
                          component={TextField}
                          type="text"
                          label={`${translate("Contact.Fields.PostalCode")}`}
                          rows="1"
                          multiline
                          disabled
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={4} sm={4}>
                        <Field
                          fullWidth
                          name={fields.phoneNumber}
                          component={TextField}
                          type="text"
                          label="Tel : 1"
                          rows="1"
                          multiline
                          disabled
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item xs={4} sm={5}>
                        <Field
                          fullWidth
                          name={fields.email}
                          component={TextField}
                          type="text"
                          id="information-email"
                          label={`${translate("Contact.Fields.Email")}`}
                          rows="1"
                          multiline
                          disabled
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item xs={4} sm={3}>
                        <Field fullWidth name={fields.city} component={TextField} type="text" label="Ville" rows="1" disabled variant="outlined" />
                      </Grid>
                      <Grid item xs={4} sm={4}>
                        <Field
                          fullWidth
                          name={fields.phoneNumber2}
                          component={TextField}
                          type="text"
                          label="Tel : 2"
                          rows="1"
                          multiline
                          disabled
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={7}>
                        <Field
                          name="currentJob.jobTitle"
                          fullWidth
                          component={TextField}
                          defaultValue={currentJob?.jobTitle}
                          type="text"
                          label={`${translate("Contact.Fields.Jobs")}`}
                          disabled
                        />
                      </Grid>
                      <Grid item xs={12} sm={5}>
                        <Field
                          name="currentJob.cvOrigin"
                          fullWidth
                          component={TextField}
                          defaultValue={currentJob?.cvOrigin}
                          type="text"
                          label={`${translate("Contact.CvOriginsLabel")}`}
                          variant="outlined"
                          disabled
                        />
                      </Grid>
                      <Grid container justifyContent="space-between" item xs={12} sm={12}>
                        <Grid item xs={12} sm={3}>
                          <Field
                            fullWidth
                            name={fields.customRecruto}
                            component={DatePickerWrapper}
                            type="text"
                            label={`${translate("Contact.Job.InterviewDataRecruto")}`}
                            rows="1"
                            multiline
                            disabled
                          />
                        </Grid>

                        <Grid item xs={12} sm={3}>
                          <Field
                            fullWidth
                            name={fields.customAncia}
                            component={DatePickerWrapper}
                            type="text"
                            label={`${translate("Contact.Job.InterviewDataAncia")}`}
                            rows="1"
                            disabled
                            variant="outlined"
                          />
                        </Grid>

                        <Grid item xs={12} sm={3}>
                          <Field
                            fullWidth
                            name={fields.customClient}
                            component={DatePickerWrapper}
                            type="text"
                            label={`${translate("Contact.Job.InterviewDataCustomer")}`}
                            rows="1"
                            multiline
                            disabled
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>
                </form>
              )}
            />
          </Grid>
        </TabPanel>
        <TabPanel className={tabIndex === 1 ? classes.tab : ""} value={tabIndex} index={1}>
          <Paper className={classes.wrapper}>
            <Grid container alignItems="flex-start" spacing={2}>
              <Grid item xs={12}>
                <h3>{translate("Contact.Fields.Experiences")}</h3>
                <List dense>
                  <TagList category={TagCategory.experience} currentTagIds={Tags} tags={tags} />
                </List>
                <Divider style={{ marginBottom: "10px" }} />
                <h3>{translate("Contact.Fields.Formations")}</h3>
                <List dense>
                  <TagList category={TagCategory.formation} currentTagIds={Tags} tags={tags} />
                </List>
                <Divider style={{ marginBottom: "10px" }} />
                <h3>{translate("Contact.Fields.Languages")}</h3>
                <List dense>
                  <TagList category={TagCategory.language} currentTagIds={Tags} tags={tags} />
                </List>
              </Grid>
            </Grid>
          </Paper>
        </TabPanel>
        <TabPanel className={tabIndex === 2 ? classes.tab : ""} value={tabIndex} index={2}>
          <Paper className={classes.wrapper}>
            <Grid container alignItems="flex-start" spacing={2}>
              <Grid item xs={12}>
                <List dense>
                  <TagList category={TagCategory.job} currentTagIds={Tags} tags={tags} />
                </List>
              </Grid>
            </Grid>
          </Paper>
        </TabPanel>
      </div>
    </div>
  )
}

const TagList: React.FC<{
  category: TagCategory
  currentTagIds: string[]
  tags: ITagDto[]
}> = (props) => {
  const { category, currentTagIds, tags } = props
  const { currentJobId } = useContactJobs()
  const { t: translate } = useTranslation()

  const currentTags = tags.filter((t) => t.category === category && currentTagIds.includes(t.id) && t.id !== currentJobId)

  const tagsComponents = []
  switch (category) {
    case TagCategory.job: {
      if (currentTags.length === 0) {
        return <span>{translate("Job.NoOtherJob")}</span>
      }
      tagsComponents.push(
        ...currentTags.map((t) => (
          // @ts-ignore
          <ListItem key={`${category}-${t.name}`}>
            <a href={`/#/jobs/${t.id}`} style={{ display: "block" }}>
              {t.name}
            </a>
          </ListItem>
        ))
      )
      break
    }

    default:
      tagsComponents.push(
        ...currentTags.map((t) => (
          <ListItem key={`${category}-${t.name}`}>
            <span style={{ display: "block" }}> {t.name}</span>
          </ListItem>
        ))
      )

      break
  }

  return <>{tagsComponents}</>
}
