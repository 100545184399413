import { makeStyles } from "@material-ui/styles"

export const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    height: "calc(100% - 64px)"
  },
  iframe: {
    width: "100%",
    height: "100%"
  },
  title: {
    fontSize: "30px",
    padding: "0.3em",
    fontWeight: "bold",
    width: "100%",
    textAlign: "center",
    display: "block"
  }
}))

export default { useStyles }
