import { JobStatus } from "api/dto/IContactJobDto"

const formatRowStatus = (status?: any, withoutBackgroundColor = false) => {
  let myColor
  let txtColor = "#fff"

  const currentStatus = status.split(" - ")[0]

  switch (currentStatus) {
    case JobStatus.Sourcing:
      myColor = "#B78700"
      break
    case JobStatus.ToSort:
      myColor = "#c6d7f7"
      break
    case JobStatus.TelephoneInterview:
      myColor = "#7faafa"
      break
    case JobStatus.Documents:
      myColor = "#0f61fa"
      break
    case JobStatus.PartnerInterview:
      myColor = "#16f224"
      break
    case JobStatus.ClientInterview:
      myColor = "#15ab1e"
      break
    case JobStatus.Reference:
      myColor = "#38c5c9"
      break
    case JobStatus.Tests:
      myColor = "#2c989c"
      break
    case JobStatus.StandBy:
      myColor = "#fab693"
      break
    case JobStatus.ReadyToHire:
      myColor = "#ff8141"
      break
    case JobStatus.Hiring:
      myColor = "#ff5803"
      break
    case JobStatus.Desistement:
      myColor = "#DC143C"
      break
    case JobStatus.Rejected:
      myColor = "#B22222"
      break
    case JobStatus.Transfered:
      myColor = "#8B0000"
      break

    case JobStatus.JobFilled:
      myColor = "#246EE9"
      break

    default:
      myColor = "rgba(0,0,0,0)"
      txtColor = "#000"
  }

  if (withoutBackgroundColor) {
    myColor = "rgba(0,0,0,0)"
    txtColor = "#000"
  }

  return {
    fontWeight: 600,
    width: "180px",
    backgroundColor: myColor,
    color: txtColor,
    whiteSpace: "nowrap"
  }
}

export { formatRowStatus }
