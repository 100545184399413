export const LOCAL = "local"
export const DEV = "dev"
export const BETA = "beta"
export const PROD = "prod"

const globalEnv = {
  api: {
    ressourcesUrl: {
      jobs: "jobs",
      contacts: "contacts",
      tags: "tags",
      stats: "stats",
      tokens: "tokens"
    }
  }
}

const env = {
  local: {
    cognito: {
      userPoolId: "ca-central-1_mr8F8xw2m",
      clientId: "m1jfa5nl3et01rrl0p6s3latj"
    },
    api: {
      ...globalEnv.api,
      baseUrl: "https://api.dev.recruto.ca",
      version: "v2",
      vaultUrl: "https://vault.dev.recruto.ca/?token="
    },
    versionSha: "local-build",
    defaultEnvPrefix: LOCAL
  },
  dev: {
    cognito: {
      userPoolId: "ca-central-1_mr8F8xw2m",
      clientId: "m1jfa5nl3et01rrl0p6s3latj"
    },
    api: {
      ...globalEnv.api,
      baseUrl: "https://api.dev.recruto.ca",
      version: "v2",
      vaultUrl: "https://vault.dev.recruto.ca/?token="
    },
    versionSha: "dev-build",
    defaultEnvPrefix: DEV
  },
  beta: {
    cognito: {
      userPoolId: "ca-central-1_mvNxs27aJ",
      clientId: "3g06q4pviqdjg66v227tdhb8vi"
    },
    api: {
      ...globalEnv.api,
      baseUrl: "https://api.app.recruto.ca",
      version: "v1",
      vaultUrl: "https://vault.app.recruto.ca/?token="
    },
    versionSha: "beta-build",
    defaultEnvPrefix: BETA
  },
  prod: {
    cognito: {
      userPoolId: "ca-central-1_mvNxs27aJ",
      clientId: "3g06q4pviqdjg66v227tdhb8vi"
    },
    api: {
      ...globalEnv.api,
      baseUrl: "https://api.app.recruto.ca",
      version: "v1",
      vaultUrl: "https://vault.app.recruto.ca/?token="
    },
    versionSha: "prod-build",
    defaultEnvPrefix: PROD
  }
}

const environment = (() => {
  const currentEnv = process.env.REACT_APP_ENV || "local"
  const versionSha = process.env.REACT_APP_VERSION_SHA || "not-in-ci"
  switch (currentEnv) {
    case LOCAL:
    case DEV:
    case BETA:
    case PROD:
      return {
        ...env[currentEnv],
        versionSha
      }
    default:
      return env.local
  }
})()

export default environment
