/* eslint-disable @typescript-eslint/ban-ts-comment */
import { IStatsDto, StatsType } from "api/dto"
import { defaultTemplates } from "containers/formulaires/stats/stats"
import countBy from "lodash/countBy"
import { Chart } from "react-google-charts"
import React, { useMemo } from "react"
import { Grid } from "@material-ui/core"
import { capitalize } from "utils/string"
import { useTranslation } from "react-i18next"

interface IStatsAggregatesProps {
  stats: IStatsDto[]
}

const globalPieOptions = {
  slices: [],
  legend: {
    position: "bottom",
    alignment: "center",
    textStyle: {
      color: "233238",
      fontSize: 14
    }
  },
  tooltip: {
    showColorCode: true
  },
  chartArea: {
    width: "100%",
    height: "80%"
  },
  fontName: "Roboto"
}

export function getStatusColor(type: StatsType) {
  let myColor
  switch (type) {
    case StatsType.Sourcing:
      myColor = "#B78700"
      break
    case StatsType.ToSort:
      myColor = "#c6d7f7"
      break
    case StatsType.GoogleVerification:
      myColor = "#91c0cc"
      break
    case StatsType.Prequalification:
      myColor = "#3c4fcf"
      break
    case StatsType.TelephoneInterview:
      myColor = "#7faafa"
      break
    case StatsType.Email:
      myColor = "#e3a534"
      break
    case StatsType.PartnerInterview:
      myColor = "#16f224"
      break
    case StatsType.Documents:
      myColor = "#0f61fa"
      break
    case StatsType.Tests:
      myColor = "#2c989c"
      break
    case StatsType.Reference:
      myColor = "#38c5c9"
      break
    case StatsType.ApplicationCompliance:
      myColor = "#b5283f"
      break
    case StatsType.SelectionCommittee:
      myColor = "#ae28b5"
      break
    case StatsType.CandidateFollowUp:
      myColor = "#865bc7"
      break
    case StatsType.ClientFollowUp:
      myColor = "#b84db2"
      break
    case StatsType.ClientInterview:
      myColor = "#15ab1e"
      break
    case StatsType.Hiring:
      myColor = "#ff5803"
      break

    default:
      myColor = "rgb(145, 145, 255)"
  }
  return myColor
}
export const StatsAggregates: React.FC<IStatsAggregatesProps> = (props) => {
  const { stats } = props
  const { t: translate } = useTranslation()
  const defaults = useMemo(() => Object.entries(defaultTemplates), [])
  const countStats = useMemo(() => countBy(stats, (s) => s.type || []), [stats])

  const pies = useMemo(() => {
    const groupedCount = Object.entries(countStats)
    const piesToRender: any[] = []
    const formattedPie: { title: string; pie: any[] } = { title: `Activités de recrutement`, pie: [{ data: ["Type", "Value"] }] }

    defaultTemplates.forEach((type) => {
      const count = groupedCount.find(([currentType]) => currentType === type)
      if (count && count[1] !== 0) {
        formattedPie.pie.push({ data: [translate(`Statistics.${capitalize(type)}.Label`), count[1]], color: getStatusColor(type) })
      }
    })

    piesToRender.push(formattedPie)

    return piesToRender
  }, [countStats])

  return (
    <Grid style={{ padding: "20px", minHeight: "430px" }} container spacing={2}>
      {pies.map((p, i) => (
        <Grid style={{ padding: "20px" }} item key={p.title} xs={6}>
          <Chart
            chartType="PieChart"
            data={p.pie.map((c: any) => c.data)}
            loader={<div>{translate("Statistics.LoadingChart")}</div>}
            width="100%"
            height="350px"
            options={{
              ...globalPieOptions,
              slices: p.pie.filter((c: any) => c.color !== undefined).map((c: any) => ({ color: c.color })),
              title: p.title,
              pieSliceText: "value-and-percentage"
            }}
            graph_id={`PieChart${i}`}
          />
        </Grid>
      ))}
    </Grid>
  )
}
