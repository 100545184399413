import { RECEIVE_CONTACTS, REQUEST_CONTACTS, SEND_POST_JOBS_DATAS, RECEIVE_ERROR, UPDATE_CONTACT_JOB } from "./actions"

const initialState = {
  isFetchingContacts: false,
  contactsList: [],
  hasErrorContact: false
}

function contactReducer(state = initialState, contacts) {
  switch (contacts.type) {
    case SEND_POST_JOBS_DATAS:
      return {
        ...state,
        isFetchingContacts: true
      }

    case REQUEST_CONTACTS:
      return {
        ...state,
        contactsList: [],
        isFetchingContacts: true
      }

    case RECEIVE_CONTACTS:
      return {
        ...state,
        contactsList: contacts.json,
        isFetchingContacts: false,
        hasErrorContact: false
      }

    case UPDATE_CONTACT_JOB: {
      const old = state.contactsList
      const optimisticContact = contacts.json
      const { Id } = optimisticContact

      if (!old) {
        return []
      }

      const cleanDatas = state.contactsList.map((el) => {
        if (el.Id !== Id) {
          return { ...el }
        }
        return { ...optimisticContact }
      })

      return {
        ...state,
        contactsList: cleanDatas,
        isFetchingContacts: false,
        hasErrorContact: false
      }
    }
    case RECEIVE_ERROR:
      return {
        ...state,
        hasErrorContact: true,
        isFetchingContacts: false
      }
    default:
      return state
  }
}

export default contactReducer
